import "./ChooseActivities.css";
import PageStructure from "../../components/PageStructure/PageStructure";
import ColouredButton from "../../components/Buttons/ColouredButton/ColouredButton";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import api from "../../api/api";
import Navbar from "../../components/Navbar/Navbar";
import Store from "../../Store";

import ColouredReturnButton from "../../components/Buttons/ColouredReturnButton/ColouredReturnButton";

import games from "./games.png";
import repeat from "./repeat.png";
import lessons from "./lessons.png";
import grammar from "./grammar.png";
import setGaDimension from "../../common/setGaDimension";

function ChooseActivities() {
  const [pairShort, setPairShort] = useState();
  const [pair, setPair] = useState();

  const params = useParams();
  const history = useHistory();
  useEffect(async () => {
    const pairRes = await api.get(`/language-pair/${params.pairId}`);

    setPair(pairRes.data);
    setPairShort(
      `${pairRes.data.languageFrom.languageShort}-${pairRes.data.languageTo.languageShort}`
    );
    setGaDimension(
      1,
      `${pairRes.data.languageFrom.language}-${pairRes.data.languageTo.language}(poziom ${pairRes.data.level})`
    );
  }, []);

  function onActivityClick(activityName) {
    setGaDimension(2, activityName);
  }

  return (
    <PageStructure
      title={Store.getText("chooseHowToLearn")}
      altBgMap
      supportIcon
      tutorialLink
      pairShort={pairShort}
    >
      <div className="activity-selection-page-content">
        <Link to={`/select-level/${pairShort}`}>
          <ColouredReturnButton className="coloured-return-button--top-left">
            {Store.getText("back")}
          </ColouredReturnButton>
        </Link>
        <div className="activity-wrapper">
          <Link
            className="activity-link"
            to={`/lessons/${params.pairId}`}
            onClick={() => onActivityClick("lekcje")}
          >
            <div className="activity">
              <div className="activity-img-wrapper">
                <img src={lessons} className="activity-img" />
              </div>
              <p className="activity-desc">{Store.getText("lessons")}</p>
            </div>
          </Link>
          <Link
            className="activity-link"
            to={`/games/${params.pairId}`}
            onClick={() => onActivityClick("gry")}
          >
            <div className="activity">
              <div className="activity-img-wrapper">
                <img src={games} className="activity-img" />
              </div>
              <p className="activity-desc">{Store.getText("exercises")}</p>
            </div>
          </Link>
          <Link
            className="activity-link"
            to={`/learning/${params.pairId}`}
            onClick={() => onActivityClick("powtórka")}
          >
            <div className="activity">
              <div className="activity-img-wrapper">
                <img src={repeat} className="activity-img" />
              </div>
              <p className="activity-desc">{Store.getText("repeat")}</p>
            </div>
          </Link>
          {pair &&
            ["cs-en", "sk-en"].indexOf(
              `${pair.languageFrom.languageShort}-${pair.languageTo.languageShort}`
            ) !== -1 && (
            <Link
              className="activity-link"
              to={`/grammar/${pair.languageFrom.languageShort}-${pair.languageTo.languageShort}`}
              onClick={() => onActivityClick("gramatyka")}

            >
              <div className="activity">
                <div className="activity-img-wrapper">
                  <img src={grammar} className="activity-img" />
                </div>
                <p className="activity-desc">GRAMATIKA</p>
              </div>
            </Link>
          )}
        </div>
      </div>
    </PageStructure>
  );
}

export default ChooseActivities;
