import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useHistory } from "react-router";
import ColouredReturnButton from "../../components/Buttons/ColouredReturnButton/ColouredReturnButton";
import PageStructure from "../../components/PageStructure/PageStructure";
import Store from "../../Store";
import "./GrammarPage.css";

const responsive = {
  0: { items: 1 },
};

const items = [
  <div>
       <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">
        Prítomný jednoduchý čas použiješ v prvom rade vtedy, keď chceš vyjadriť
        niečo, čo je pravdivé teraz v prítomnosti.
      </p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">
        Nemusí sa striktne jednať o niečo, čo sa v budúcnosti meniť nebude.
      </p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">
        Podstatné je však to, že je daná vec pravdivá a aktuálna v momente, keď
        ju vyslovuješ.
      </p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">
        Daj si však veľký pozor na to, že prítomný jednoduchý čas sa nepoužíva
        na vyjadrenie činností, ktoré sa dejú práve teraz.
      </p>
    </div>
  <div className="grammar-page__content">
    <p className="grammar-page__content-header">Present Simple</p>
 
    <div className="grammar-page__content-inner-section">
      <table className="grammar-page__table">
        <thead>
          <th>Present simple</th>
          <th> Prítomný čas jednoduchý</th>
          <th>Vysvetlenie</th>
        </thead>
        <tbody>
          <tr>
            <td>
              She <span className="grammar-page__span-bold">lives</span> in
              Bratislava.
            </td>
            <td>Ona býva v Bratislave.</td>
            <td>
              Prítomný čas jednoduchý naznačuje to, že býva v Bratislave teraz.
              Môže sa stať, že sa to v budúcnosti zmení, avšak teraz je to pravd
            </td>
          </tr>
          <tr>
            <td>
              I <span className="grammar-page__span-bold">am</span> thirty years
              old.
            </td>
            <td>Mám tridsať rokov.</td>
            <td>
              To, že mám tridsať rokov, je pravdou teraz, preto je vo vete
              present simple. Tu sa nejedná o tvrdenie so všeobecnou platnosťou,
              ale o tvrdenie, ktoré sa bude v budúcnosti s určitosťou meniť.
            </td>
          </tr>
          <tr>
            <td>
              He <span className="grammar-page__span-bold">doesn´t like</span>{" "}
              tomatoes.
            </td>
            <td>Nemá rád paradajky.</td>
            <td>
              Tu sa môže na prvý pohľad zdať, že sa jedná o všeobecné tvrdenie.
              Avšak, aj keď veta tvrdí, že dotyčný jednoducho nemá rád
              paradajky, tak nie je vylúčené, že sa to v budúcnosti nezmení.
              Môže sa to zmeniť, ale aj nemusí.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div></div>,
  <div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">
        Jednoduchý minulý čas sa v angličtine nazýva aj préteritum (z latinského
        praeteritum „to, čo skončilo“).
      </p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">
        Môžeš ho teda najčastejšie využiť na vyjadrenie deja, ktorý začal a aj
        skončil v určitom čase v minulosti.
      </p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">
        Väčšinou sa viaže na konkrétny čas v minulosti, aj keď nemusel byť
        vyslovene spomenutý.
      </p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">
        Na naznačenie konkrétneho času môžeš použiť príslovky ako:
      </p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">Yesterday (včera)</p>
      <p className="grammar-page__content-row">Last year (minulý rok)</p>
      <p className="grammar-page__content-row">Last week (minulý týždeň)</p>
      <p className="grammar-page__content-row">Last night (včera večer)</p>
      <p className="grammar-page__content-row">Príkladové vety:</p>
    </div>
  <div className="grammar-page__content">
    <p className="grammar-page__content-header">Past simple</p>
    
    <div className="grammar-page__content-inner-section">
      <table className="grammar-page__table">
        <thead>
          <th>Past simple</th>
          <th> Minulý čas jednoduchý</th>
        </thead>
        <tbody>
          <tr>
            <td>
              We <span className="grammar-page__span-bold">saw</span> a movie
              yesterday.
            </td>
            <td>
              Včera <span className="grammar-page__span-bold">sme</span> videli
              (jeden) film.
            </td>
          </tr>
          <tr>
            <td>
              I <span className="grammar-page__span-bold">didn´t see</span> you
              at the party yesterday.
            </td>
            <td>
              Včera <span className="grammar-page__span-bold">som</span> ťa na
              oslave <span className="grammar-page__span-bold">nevidel</span>.
            </td>
          </tr>
          <tr>
            <td>
              Last year, I{" "}
              <span className="grammar-page__span-bold">didn´t move</span> to
              Spain.
            </td>
            <td>
              Minulý rok{" "}
              <span className="grammar-page__span-bold">
                som sa nepresťahoval
              </span>{" "}
              do Španielska.
            </td>
          </tr>
          <tr>
            <td>
              Last year, I{" "}
              <span className="grammar-page__span-bold">didn´t</span> buy a new
              car.
            </td>
            <td>
              Minulý rok som{" "}
              <span className="grammar-page__span-bold">si nekúpil</span> nové
              auto.
            </td>
          </tr>
          <tr>
            <td>
              <span className="grammar-page__span-bold">Did</span> you{" "}
              <span className="grammar-page__span-bold">cook</span> dinner last
              night?
            </td>
            <td>
              <span className="grammar-page__span-bold">Varil si</span> včera
              večeru?
            </td>
          </tr>
          <tr>
            <td>
              I <span className="grammar-page__span-bold">washed</span> my hair
              yesterday.
            </td>
            <td>
              Včera <span className="grammar-page__span-bold">som si umyl</span>{" "}
              vlasy.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div></div>,
  <div>
     <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">
        Present perfect je kombináciou prítomného času (present) a vyjadrenia
        úplnej / dokončenej činnosti (perfect).
      </p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">
        Používa sa na vyjadrenie minulej udalosti / činnosti, ktorá má súvis s
        prítomnosťou.
      </p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">
        Najčastejšie sa používa na vyjadrenie dejov, skúseností, alebo zmien,
        ktoré sa udiali a pri ktorých je dôležitý výsledok a nie čas, kedy sa
        udiali.
      </p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">
        Štruktúra kladnej oznamovacej vety v present perfect vyzerá nasledovne:
      </p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">
        Osoba/predmet + pomocné sloveso have/ has + minulé trpné príčastie
        slovesa
      </p>
    </div>
  <div className="grammar-page__content">
    <p className="grammar-page__content-header">Present perfect</p>
   
    <div className="grammar-page__content-inner-section">
      <table className="grammar-page__table">
        <thead>
          <th>Anglická veta v present perfect</th>
          <th> Bežný preklad predprítomného času</th>
        </thead>
        <tbody>
          <tr>
            <td>
              I <span className="grammar-page__span-bold">have seen</span> that
              movie.
            </td>
            <td>Videl som ten film.</td>
          </tr>
          <tr>
            <td>
              You <span className="grammar-page__span-bold">have seen</span>{" "}
              that movie.
            </td>
            <td>Videl si ten film.</td>
          </tr>
          <tr>
            <td>
              He (she/ it){" "}
              <span className="grammar-page__span-bold">has seen</span> that
              movie.
            </td>
            <td>Videl ten film.</td>
          </tr>
          <tr>
            <td>
              We <span className="grammar-page__span-bold">have seen</span> that
              movie.
            </td>
            <td>Videli sme ten film.</td>
          </tr>
          <tr>
            <td>
              You <span className="grammar-page__span-bold">have seen</span>{" "}
              that movie.
            </td>
            <td>Videli ste ten film.</td>
          </tr>
          <tr>
            <td>
              They <span className="grammar-page__span-bold">have seen</span>{" "}
              that movie.
            </td>
            <td>Videli ten film.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div></div>,
  <div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">
        Na popis činností, ktoré prebiehajú práve teraz, sa v angličtine (na
        rozdiel od slovenčiny) nepoužíva prítomný jednoduchý čas (present
        simple), ale prítomný priebehový čas – present continuous.
      </p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">
        Vo vetách s prítomným priebehovým časom sa môžeš často stretnúť s
        príslovkami, ktoré naznačujú, že sa jedná o dej, ktorý prebieha práve
        teraz:
      </p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">now (teraz)</p>
      <p className="grammar-page__content-row">today (dnes)</p>
      <p className="grammar-page__content-row">right now (práve teraz)</p>
      <p className="grammar-page__content-row">
        at the moment (v tomto momente/ práve v tejto chvíli)
      </p>
      <p className="grammar-page__content-row">
        Ako vyzerá tento čas vo vetách?
      </p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">Tu je pár príkladov:</p>
    </div>
  <div className="grammar-page__content">
    <p className="grammar-page__content-header">Present continuous</p>
    
    <div className="grammar-page__content-inner-section">
      <table className="grammar-page__table">
        <thead>
          <th>Present continuous</th>
          <th>Prítomný čas priebehový</th>
        </thead>
        <tbody>
          <tr>
            <td>
              He <span className="grammar-page__span-bold">is studying</span>{" "}
              English right now.
            </td>
            <td>Práve sa učí angličtinu.</td>
          </tr>
          <tr>
            <td>
              I <span className="grammar-page__span-bold">am working</span> in
              the office at the moment.
            </td>
            <td>Práve teraz pracujem v kancelárii.</td>
          </tr>
          <tr>
            <td>
              The children{" "}
              <span className="grammar-page__span-bold">are sleeping</span> now.
            </td>
            <td>Deti práve spia.</td>
          </tr>
          <tr>
            <td>
              They <span className="grammar-page__span-bold">are cooking</span>{" "}
              dinner right now.
            </td>
            <td>Teraz varia večeru.</td>
          </tr>
          <tr>
            <td>
              You <span className="grammar-page__span-bold">are talking</span>{" "}
              too fast. I don´t understand you.
            </td>
            <td>Hovoríš príliš rýchlo. Nerozumiem ti.</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div></div>,
  <div>
     <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">
        Past continuous použiješ, ak hovoríš o činnosti, ktorá prebiehala v
        minulosti dlhšiu dobu a bola prerušená jednorazovou krátkou činnosťou.
      </p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">
        Táto kratšia činnosť býva väčšinou vyjadrená v jednoduchom minulom čase
        (past simple).
      </p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">Ako to vyzerá v praxi?</p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">
        Pozrime sa na konkrétny príklad (vety). Ako si môžeš všimnúť, past
        continuous je v nich zvýraznený tučným a za ním nasleduje past simple.
      </p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">Príklady</p>
    </div>
  <div className="grammar-page__content">
    <p className="grammar-page__content-header">Past continuous</p>
   
    <div className="grammar-page__content-inner-section">
      <table className="grammar-page__table">
        <thead>
          <th>Past continuous a past simple</th>
          <th>Minulý priebehový čas a minulý jednoduchý čas</th>
        </thead>
        <tbody>
          <tr>
            <td>
              I <span className="grammar-page__span-bold">was having</span>{" "}
              lunch when the phone rang.
            </td>
            <td>
              Práve{" "}
              <span className="grammar-page__span-bold">som obedoval</span>, keď
              mi zazvonil telefón.
            </td>
          </tr>
          <tr>
            <td>
              What <span className="grammar-page__span-bold">were</span> you{" "}
              <span className="grammar-page__span-bold">doing</span> when you
              hurt your knee?
            </td>
            <td>
              Čo <span className="grammar-page__span-bold">si robil</span>, keď
              si si poranil koleno?
            </td>
          </tr>
          <tr>
            <td>
              While I{" "}
              <span className="grammar-page__span-bold">was shopping</span>,
              somebody stole my bike.
            </td>
            <td>
              Zatiaľ, čo{" "}
              <span className="grammar-page__span-bold">som nakupovala</span>,
              mi niekto ukradol bicykel.
            </td>
          </tr>
          <tr>
            <td>
              I <span className="grammar-page__span-bold">was having</span> a
              beautiful dream when the alarm clock rang.
            </td>
            <td>
              <span className="grammar-page__span-bold">Sníval sa</span> mi
              krásny sen, keď v tom zazvonil budík.
            </td>
          </tr>
          <tr>
            <td>
              I <span className="grammar-page__span-bold">was having</span> a
              shower when somebody knocked on the door.
            </td>
            <td>
              {" "}
              <span className="grammar-page__span-bold">Sprchoval som</span> sa,
              keď niekto zaklopal na dvere.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div></div>,
];

function GrammarPageSkEn(props) {
  const history = useHistory();

  return (
    <PageStructure setError={props.setError} altBg tutorialLink>
      <ColouredReturnButton
        className="coloured-return-button--top-left"
        onClick={history.goBack}
      >
        {Store.getText("back")}
      </ColouredReturnButton>
      <div className="grammar-page">
        <div className="grammar-page__content-inner-section">
          <p className="grammar-page__content-row">
            <span className="grammar-page__span-bold">
              Členy v angličtine – rozdelenie
            </span>
          </p>
          <p className="grammar-page__content-row">
            V angličtine poznáme 3 druhy členov a sú to:
          </p>
        </div>
        <div className="grammar-page__content-inner-section">
          <p className="grammar-page__content-row">
            Určitý člen THE (definite article)
          </p>
          <p className="grammar-page__content-row">
            Neurčitý člen A alebo AN (indefinite article)
          </p>
          <p className="grammar-page__content-row">
            Nulový člen (zero article)
          </p>
          <p className="grammar-page__content-row">
            Vo všeobecnosti sa dá povedať, že
          </p>
        </div>
        <div className="grammar-page__content-inner-section">
          <p className="grammar-page__content-row">
            neurčitý člen A /AN použiješ, ak hovoríš o všeobecných veciach,
            ktoré nie sú konkretizované určitý člen THE použiješ, ak hovoríš o
            veciach, ktoré už sú nejakým spôsobom špecifikované
          </p>
        </div>
        <div className="grammar-page__content-inner-section">
          <p className="grammar-page__content-row">
            <span className="grammar-page__span-bold">
              For + -ing v angličtine
            </span>
          </p>
          <p className="grammar-page__content-row">
            Niekedy môžeme použiť for + -ing, keď hovoríme o účele veci. Keď sa
            tak stane, význam je rovnaký ako pri to + infinitive.
          </p>
        </div>
        <table className="grammar-page__table grammar-page__table--border">
          <thead>
            <th>For</th>
            <th>Slovenský preklad</th>
          </thead>
          <tbody>
            <tr>
              <td>What is that for? It is for opening envelopes.</td>
              <td>Na čo je to? To je na otváranie obálok.</td>
            </tr>
            <tr>
              <td>This bell is for calling the waiter.</td>
              <td>Tento zvonček slúži na zavolanie čašníka.</td>
            </tr>
            <tr>
              <td>This bell is to call the waiter.</td>
              <td>Tento zvonček má zavolať čašníka.</td>
            </tr>
          </tbody>
        </table>
        <div className="grammar-page__content-inner-section">
          <p className="grammar-page__content-row">
            Keď hovoríme o účele činnosti nejakej osoby, nemôžeme použiť for +
            ing.
          </p>
        </div>
        <table className="grammar-page__table grammar-page__table--border">
          <thead>
            <th>Anglická veta</th>
            <th>Slovenský preklad</th>
          </thead>
          <tbody>
            <tr>
              <td>I went there to ask for help.</td>
              <td>Išiel som tam požiadať o pomoc.</td>
            </tr>
            <tr>
              <td>You will have to queue a long time to get tickets.</td>
              <td>Budete musieť dlho čakať v rade, aby ste dostali lístky.</td>
            </tr>
          </tbody>
        </table>
        <div className="grammar-page__content-inner-section">
          <p className="grammar-page__content-row">
            <span className="grammar-page__span-bold">
              Najpoužívanejšie a najdôležitejšie časy v angličtine sú
            </span>
          </p>
          <p className="grammar-page__content-row">
            prítomný čas jednoduchý (present simple)
          </p>
          <p className="grammar-page__content-row">
            jednoduchý minulý čas (past simple)
          </p>
          <p className="grammar-page__content-row">
            prítomný priebehový čas (present continuous)
          </p>
          <p className="grammar-page__content-row">
            minulý priebehový čas (past continuous)
          </p>
          <p className="grammar-page__content-row">
            predprítomný čas (present perfect)
          </p>
        </div>
        <section className="grammar-page__content-section">
          <AliceCarousel
            autoHeight
            // autoWidth
            mouseTracking
            items={items}
            responsive={responsive}
          />
        </section>
      </div>
    </PageStructure>
  );
}

export default GrammarPageSkEn;
