const map = {
    'dimension1': 'lang_choose',
    'dimension2': 'activity_choose',
    'dimension3': 'lesson_choose',
    'dimension4': 'game_choose',
}


function setGaDimension(id, value) {
    window.gtag('event', 'dimension_send', {
       [map[`dimension${id}`]]: value
    })
}
 export default setGaDimension