import { useEffect, useState } from "react";
import { useParams } from "react-router";
import PageStructure from "../../components/PageStructure/PageStructure";
import api from "../../api/api";

import "./TestPage.css";
import Store from "../../Store";
import chunkArrayInGroups from "../../common/chunkArrayInGroups";
import moment from "moment";
import { Link } from "react-router-dom";

const TEST_EXPRESSION_QUESTION_NUMBER = 8;
const TEST_SENTENCE_QUESTION_NUMBER = 6;
const TEST_INSERT_QUESTION_NUMBER = 10;

function InsertQuestion({ data, index, setAnswerArray }) {
  const [foreignExpressionChunks, setForeignExpressionChunks] = useState([]);
  const [selectedAnser, setSelectedAnswer] = useState();

  useEffect(() => {
    const expression = data.goodAnswer.contentTo.expression.toLowerCase();
    const sentence = data.goodAnswer.contentTo.sentence.toLowerCase();

    const expressionStartIndex = sentence.indexOf(expression);

    const chunks = [
      data.goodAnswer.contentTo.sentence.slice(0, expressionStartIndex),
      new Array(expression.length + 1).join("_"),
      data.goodAnswer.contentTo.sentence.slice(
        expressionStartIndex + expression.length,
        sentence.length - 1
      ),
    ];
    setForeignExpressionChunks(chunks);
  }, []);

  const handlePickAnswer = (answer, i) => {
    setSelectedAnswer(i);
    const isGoodAnswer = answer.id === data.goodAnswer.id;
    setAnswerArray((prev) => {
      const newArray = [...prev];
      newArray[index] = isGoodAnswer ? 1 : 0;
      return newArray;
    });

    const newChunks = [...foreignExpressionChunks];
    newChunks[1] = answer.contentTo.expression.toUpperCase();

    setForeignExpressionChunks(newChunks);
  };
  return (
    <li key={index}>
      <p>{data.goodAnswer.contentFrom.sentence}</p>
      <p>
        {foreignExpressionChunks.map((chunk, i) => (
          <span key={chunk}>{chunk}</span>
        ))}
      </p>

      <div className="test-page__insert-answers-wrapper">
        {data.answers.map((answer, i) => (
          <div
            className={`test-page__insert-answer ${
              i === selectedAnser ? "test-page__insert-answer--selected" : ""
            }`}
            key={i}
            onClick={() => handlePickAnswer(answer, i)}
          >
            {answer.contentTo.expression}
          </div>
        ))}
      </div>
    </li>
  );
}

function TestExpressionQuestion({ data, index, setAnswerArray }) {
  const [foreignExpressionChunks, setForeignExpressionChunks] = useState([]);
  const [selectedAnser, setSelectedAnswer] = useState();

  const handlePickAnswer = (answer, i) => {
    setSelectedAnswer(i);
    const isGoodAnswer = answer.id === data.goodAnswer.id;
    setAnswerArray((prev) => {
      const newArray = [...prev];
      newArray[index] = isGoodAnswer ? 1 : 0;
      return newArray;
    });

    const newChunks = [...foreignExpressionChunks];
    newChunks[1] = answer.contentTo.expression.toUpperCase();

    setForeignExpressionChunks(newChunks);
  };
  return (
    <li key={index}>
      <p>{data.goodAnswer.contentFrom.expression}</p>

      <ul className="test-page__expression-answers-wrapper">
        {data.answers.map((answer, i) => (
          <div className="test-page__expression-answer-wrapper" key={i}>
            <input
              type="radio"
              name={`expression-${index}`}
              className={`test-page__expression-answer`}
              onClick={() => handlePickAnswer(answer, i)}
            />
            <label className="test-page__expression-answer-label">
              {answer.contentTo.expression}
            </label>
          </div>
        ))}
      </ul>
    </li>
  );
}

function TestSentenceQuestion({ data, index, setAnswerArray }) {
  const [foreignExpressionChunks, setForeignExpressionChunks] = useState([]);
  const [selectedAnser, setSelectedAnswer] = useState();

  const handlePickAnswer = (answer, i) => {
    setSelectedAnswer(i);
    const isGoodAnswer = answer.id === data.goodAnswer.id;
    setAnswerArray((prev) => {
      const newArray = [...prev];
      newArray[index] = isGoodAnswer ? 1 : 0;
      return newArray;
    });

    const newChunks = [...foreignExpressionChunks];
    newChunks[1] = answer.contentTo.expression.toUpperCase();

    setForeignExpressionChunks(newChunks);
  };
  return (
    <li key={index}>
      <p>{data.goodAnswer.contentFrom.expression}</p>

      <ul className="test-page__sentence-answers-wrapper">
        {data.answers.map((answer, i) => (
          <div className="test-page__sentence-answer-wrapper" key={i}>
            <input
              type="radio"
              name={`expression-${index}`}
              className={`test-page__sentence-answer`}
              onClick={() => handlePickAnswer(answer, i)}
            />
            <label className="test-page__sentence-answer-label">
              {answer.contentTo.sentence}
            </label>
          </div>
        ))}
      </ul>
    </li>
  );
}

function TestPage(props) {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [test, setTest] = useState();
  const [flashcardCounts, setFlashcardCounts] = useState();

  const [canDoTest, setCanDoTest] = useState(false);
  const [isTestCompleted, setIsTestCompleted] = useState(false);

  const [flashcardsForTest, setFlashcardsForTest] = useState([]);


  const [flashcardsForExpressionQuestion, setFlashcardsForExpressionQuestion] =
    useState([]);
  const [flashcardsForSentenceQuestion, setFlashcardsForSentenceQuestion] =
    useState([]);
  const [flashcardsForInsertQuestion, setFlashcardsForInsertQuestion] =
    useState([]);

  const [insertAnswers, setInsertAnswers] = useState([]);
  const [experssionAnswers, setExperssionAnswers] = useState([]);
  const [sentenceAnswers, setSentenceAnswers] = useState([]);

  const params = useParams();

  const [currentView, setCurrentView] = useState();

  useEffect(async () => {
    await loadData()
  }, []);

  useEffect(() => {
    decideCanDoTest(flashcardCounts, test);
  }, [name, surname, JSON.stringify(flashcardCounts)]);

  const onEndTest = async () => {
    let points = 0;
    [...insertAnswers, ...experssionAnswers, ...sentenceAnswers].forEach(answer => {
      if(answer === 1) points++
    })
    const allPoints = TEST_SENTENCE_QUESTION_NUMBER + TEST_INSERT_QUESTION_NUMBER + TEST_EXPRESSION_QUESTION_NUMBER
    const percent = Math.round(points/allPoints * 100)
    await api.post('/test', {name, surname, languagePairShort: params.pairShort, percent})
    const resTest = await api.get(`/test/${params.pairShort}`);
    setTest(resTest.data);
    decideIsTestCompleted(resTest.data)
    setCurrentView(3)
  }

  const loadData = async () => {
    setIsLoading(true)
    try {
      const res = await api.get(`/flashcard/counts/${params.pairShort}`);
      const resTest = await api.get(`/test/${params.pairShort}`);
  
  
      setFlashcardCounts(res.data);
      setTest(resTest.data);
      const isCompleted = decideIsTestCompleted(resTest.data);
  
  
        setFlashcardsForTest([])
        setFlashcardsForExpressionQuestion([])
        setFlashcardsForSentenceQuestion([])
        setFlashcardsForInsertQuestion([])
        setSentenceAnswers([])
        setExperssionAnswers([])
        setInsertAnswers([])
        const resFlashcardsTest = await api.get(
          `/flashcard/test/${params.pairShort}`
        );
        setFlashcardsForTest(resFlashcardsTest.data);
        createTestQuestions(resFlashcardsTest.data);
        setName(resTest.data.name);
        setSurname(resTest.data.surname);
  
      if(resTest.data && Object.keys(resTest.data).length > 0) {
        if(isCompleted || moment(resTest.data.lastAttemptDate).add(1, 'day').set({hour: 8, minute: 0, second: 0}).isAfter(moment())) setCurrentView(3);
        else setCurrentView(1)
      } else {
        setCurrentView(1)
      }
    } catch(e) {console.log(e)}
    setIsLoading(false)
  }

  const createTestQuestions = (testData) => {
    Object.keys(testData).forEach((type) => {
      testData[type] = chunkArrayInGroups(
        testData[type].sort(() => 0.5 - Math.random()),
        4
      );
      testData[type] = testData[type].map((chunk) => {
        return {
          goodAnswer: chunk[0],
          answers: chunk.sort(() => 0.5 - Math.random()),
        };
      });
    });

    const { chooseType, insertType } = testData;
    setFlashcardsForExpressionQuestion(
      chooseType.splice(0, TEST_EXPRESSION_QUESTION_NUMBER)
    );
    setFlashcardsForSentenceQuestion(
      chooseType.splice(0, TEST_SENTENCE_QUESTION_NUMBER)
    );
    setFlashcardsForInsertQuestion(
      insertType.splice(0, TEST_INSERT_QUESTION_NUMBER)
    );
  };

  const decideCanDoTest = (counts, test) => {
    if (test) {
    }
    if (!name || !surname) return setCanDoTest(false);
    if (Store.isAdmin()) return setCanDoTest(true);
    let canDo = true;
    counts.forEach((count) => {
      if (count.count < count.allCount) canDo = false;
    });

    setCanDoTest(counts.length > 0 ? canDo : false);
  };

  const decideIsTestCompleted = (userTest) => {
    setIsTestCompleted(userTest && userTest.percent === 100);
    return userTest && userTest.percent === 100
  };



  const renderTestStart = () => {
    return (
      <>
        <h1 className="test-page__main-heading">
          {Store.getText('testPageTitle')}
        </h1>
        <p className="test-page__paragraph test-page__paragraph--center">
          {Store.getText('testPageDesc')}

        </p>
        <h2 className="test-page__heading">
        {Store.getText('testPageRulesTitle')}
          
        </h2>
        <ol className="test-page__ordered-list">
          <li className="test-page__ordered-list-element">
            {Store.getText('testPageRules1')}
          </li>
          <li className="test-page__ordered-list-element">
            {Store.getText('testPageRules2')}
          </li>
          <li className="test-page__ordered-list-element">
            {Store.getText('testPageRules3')}
          </li>
          <li className="test-page__ordered-list-element">
            {Store.getText('testPageRules4')}
          </li>
          <li className="test-page__ordered-list-element">
            {Store.getText('testPageRules5')}
          </li>
          <li className="test-page__ordered-list-element">
            {Store.getText('testPageRules6')}
          </li>
          <li className="test-page__ordered-list-element">
            {Store.getText('testPageRules7')}
          </li>
          <li className="test-page__ordered-list-element">
            {Store.getText('testPageRules8')}
          </li>
          <li className="test-page__ordered-list-element">
            {Store.getText('testPageRules9')}
          </li>
          <li className="test-page__ordered-list-element">
            {Store.getText('testPageRules10')}
          </li>
          <li className="test-page__ordered-list-element">
            {Store.getText('testPageRules11')}
          </li>
        </ol>
        <p className="test-page__paragraph">
        {Store.getText('testPageStatement')}
        </p>
        <div className="test-page__inputs-wrapper">
          <div className="test-page__input-wrapper">
            <label className="test-page__input-label">{Store.getText('testPageName')}</label>
            <input
              className="test-page__input"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="test-page__input-wrapper">
            <label className="test-page__input-label">{Store.getText('testPageSurname')}</label>
            <input
              className="test-page__input"
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
            />
          </div>
        </div>
        <p className="test-page__paragraph test-page__paragraph--center">
        {Store.getText('testPagePersonalDataDesc')}
        </p>
        <button
          className="test-page__start-button"
          onClick={() => setCurrentView(2)}
          disabled={!canDoTest}
        >
          {Store.getText('testPageTestStart')}
        </button>
      </>
    );
  };

  const renderTest = () => {
    return (
      <>
        <ol className="test-page__activity-list">
          <li>
            <p className="test-page__activity-list-title">Doplňte chýbajúce slová.</p>
            <ol>
              {flashcardsForInsertQuestion.map((questionData, i) => (
                <InsertQuestion
                  key={i}
                  data={questionData}
                  index={i}
                  setAnswerArray={setInsertAnswers}
                />
              ))}
            </ol>
          </li>
          <li>
          <p className="test-page__activity-list-title">Označte správnu odpoveď</p>

            <ol>
              {flashcardsForExpressionQuestion.map((questionData, i) => (
                <TestExpressionQuestion
                  key={i}
                  data={questionData}
                  index={i}
                  setAnswerArray={setExperssionAnswers}
                />
              ))}
            </ol>
          </li>
          <li>
          <p className="test-page__activity-list-title">Ukážte na vetu, ktorá obsahuje dané slovo.</p>

            <ol>
              {flashcardsForSentenceQuestion.map((questionData, i) => (
                <TestSentenceQuestion
                  key={i}
                  data={questionData}
                  index={i}
                  setAnswerArray={setSentenceAnswers}
                />
              ))}
            </ol>
          </li>
        </ol>
        <button
          className="test-page__start-button"
          onClick={() => onEndTest()}
        >
                    {Store.getText('testPageTestEnd')}

        </button>
      </>
    );
  };

  const renderTestScore = () => {
    return (
      <>
        {test && Object.keys(test).length> 0 && <>
        <p className="test-page__heading">
          {Store.getText('testPageScoreTitle')}
        </p>
        <p className="test-page__paragraph test-page__paragraph--center">
          {Store.getText('testPageScore')}
        </p>
        <p className="test-page__percent">
          {test.percent}%
        </p>

        
        {isTestCompleted ? 
        <>
          <p className="test-page__paragraph">
          {Store.getText('testPageCongratulations')}
          </p> 
          <p className="test-page__paragraph">
          {Store.getText('testPageCongratulations2')}
          </p> 
          <p className="test-page__paragraph">
          {Store.getText('testPageCongratulations3')}
          </p> 
        </> :
        <> 
          <p className="test-page__paragraph">
          {Store.getText('testPageFail')}
          </p> 
          <p className="test-page__paragraph">
          {Store.getText('testPageFail2')}
          </p> 
        </>}

        <Link to={`/select-level/${params.pairShort}`}>
        <button className={`test-page__start-button`}>
        {Store.getText("testPageToPlatformButtonText")}
              </button>
              </Link>
        </>}
      </>
    );
  };

  const pseudoRouter = () => {
    switch(currentView) {
      case 1: return renderTestStart()
      case 2: return renderTest()
      case 3: return renderTestScore()
    }
  }

  return (
    <PageStructure
      setError={props.setError}
      whiteBg
      tutorialLink
      // pairShort={pairShort}
    >
      <div className="test-page__wrapper">
      {isLoading &&  <div class="simple-loader"></div>}

        {pseudoRouter()}
        </div>
    </PageStructure>
  );
}

export default TestPage;
