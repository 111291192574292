export const languageDictionary = {
  pl: {
    pl: "Polski",
    hu: "Lengyel",
    sk: 'Poľský',
    cs: "Polština",
    en: "Polish",
  },
  en: {
    pl: "Angielski",
    hu: "Angol",
    sk: 'Angličtina',
    cs: "angličtina",
    en: "English",
  },
  de: {
    pl: "Niemiecki",
    hu: "Német",
    sk: 'nemčina',
    cs: "němčina",
    en: "German",

  },
  es: {
    pl: "Hiszpański",
    hu: "Spanyol",
    sk: 'španielčina',
    cs: "španělština",
    en: "Spanish",

  },
  fr: {
    pl: "Francuski",
    hu: "Francia",
    sk: 'francúzština',
    cs: "francouzština",
    en: "French",

  },
  it: {
    pl: "Włoski",
    hu: "Olasz",
    sk: 'taliančina',
    cs: "italština",
    en: "Italian",

  },
  no: {
    pl: "Norweski",
    hu: "Norvég",
    sk: 'nórsky',
    cs: "Norský",
    en: "",

  },
  sv: {
    pl: "Szwedzki",
    hu: "Svéd",
    sk: 'švédsky',
    cs: "Švédský",
    en: "",

  },
  nl: {
    pl: "niderlandzki",
    hu: "Holland",
    sk: 'holandčina',
    cs: "nizozemština",
    en: "Dutch",

  },
  ru: {
    pl: "Rosyjski",
    hu: "Orosz",
    sk: 'ruština',
    cs: "ruština",
    en: "Russian",

  },
  cs: {
    pl: "Czeski",
    hu: "Cseh",
    sk: 'český',
    cs: "Čeština",
    en: "",

  },
  sk: {
    pl: "Słowacki",
    sk: 'Slovenský',
    cs: "Slovák",
    en: "",
    hu: "Szlovák"

  },
  pt: {
    pl: "Portugalski",
    sk: 'Portugalčina',
    cs: "Portugalština",
    en: "Portuguese",
    hu: "Portugál"
  },
  hu: {
    pl: "Węgierski",
    cs: "Maďarský",
    sk: "Maďarský",
    hu: "Magyar",
    en: "Hungarian"
  }
};
