import flashCardInfoCs from "./csImages/flash_info.png";
import selectLanguageCs from "./csImages/select-language.png";
import selectLevelCs from "./csImages/select-level.jpg";
import linkGameCs from "./csImages/link_game.jpg";
import lessonListCs from "./csImages/lesson_list.jpg";
import activitiesCs from "./csImages/activities.png";
import facesCs from "./csImages/3faces.jpg";
import userExpandedCs from "./csImages/user_expanded.jpg";
import addLanguageCs from "./csImages/add_language.jpg";

import flashCardInfoPl from "./plImages/flash_info.jpg";
import flashCardInfoMobPl from "./plImages/flash_info_mob.jpg";
import selectLanguagePl from "./plImages/select-language.jpg";
import selectLevelPl from "./plImages/select-level.jpg";
import linkGamePl from "./plImages/link_game.jpg";
import lessonListPl from "./plImages/lesson_list.jpg";
import activitiesPl from "./plImages/activities.jpg";
import facesPl from "./plImages/3faces.jpg";

import flashCardInfoSk from "./skImages/flash_info.png";
import selectLanguageSk from "./skImages/select-language.jpg";
import selectLevelSk from "./skImages/select-level.jpg";
import linkGameSk from "./skImages/link_game.jpg";
import lessonListSk from "./skImages/lesson_list.jpg";
import activitiesSk from "./skImages/activities.jpg";
import facesSk from "./skImages/3faces.jpg";
import userExpandedSk from "./skImages/user_expanded.jpg";
import addLanguageSk from "./skImages/add_language.jpg";



import user from "./user.jpg";
import lessons from "./lessons.png";
import games from "./games.png";
import repeat from "./repeat.png";


const langImg = {
    flashCardInfo: {
        cs: flashCardInfoCs,
        pl: flashCardInfoPl,
        sk: flashCardInfoSk,
    },
    flashCardInfoMob: {
        cs: flashCardInfoCs,
        pl: flashCardInfoMobPl,
        sk: flashCardInfoSk,
    },
    selectLanguage: {
        cs: selectLanguageCs,
        pl: selectLanguagePl,
        sk: selectLanguageSk,
    },
    selectLevel: {
        cs: selectLevelCs,
        pl: selectLevelPl,
        sk: selectLevelSk,
    },
    linkGame: {
        cs: linkGameCs,
        pl: linkGamePl,
        sk: linkGameSk,
    },
    lessonList: {
        cs: lessonListCs,
        pl: lessonListPl,
        sk: lessonListSk,
    },
    activities: {
        cs: activitiesCs,
        pl: activitiesPl,
        sk: activitiesSk,
    },
    faces: {
        cs: facesCs,
        pl: facesPl,
        sk: facesSk,
    },
    userExpanded: {
        cs: userExpandedCs,
        pl: userExpandedCs,
        sk: userExpandedSk,
    },
    addLanguage: {
        cs: addLanguageCs,
        pl: addLanguageCs,
        sk: addLanguageSk,
    },
    user: {
        cs: user,
        pl: user,
        sk: user,
    },
    lessons: {
        cs: lessons,
        pl: lessons,
        sk: lessons,
    },
    games: {
        cs: games,
        pl: games,
        sk: games,
    },
    repeat: {
        cs: repeat,
        pl: repeat,
        sk: repeat,
    }
    
}



const getTutorialImage = (imgName, langShort) => {
    return langImg[imgName][langShort]
}

export default getTutorialImage