import { useHistory } from "react-router";
import Store from "../../Store";
import api from "../../api/api";
import ColouredButton from "../Buttons/ColouredButton/ColouredButton";
import "./RegisterBox.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import TransparentButton from "../Buttons/TransparentButton/TransparentButton";
import ContactSupport from "../ContactSupport/ContactSupport";

function RegisterBox(props) {
  const history = useHistory();
  const [inputEmail, setInputEmail] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [inputPasswordShow, setInputPasswordShow] = useState(false);
  const [inputPasswordRepeat, setInputPasswordRepeat] = useState("");
  const [inputPasswordRepeatShow, setInputPasswordRepeatShow] = useState(false);
  const [inputKey, setInputKey] = useState("");

  const [isPasswordMatch, setIsPasswordMatch] = useState(true);

  const login = async (email, password) => {
    const res = await api.post("/auth/login", {
      email: email,
      password: password,
    });

    const token = res.data.token;
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    Store.setToken(token);

    // history.push("/select-language");
  };

  const handleRegisterFormSubmit = async (ev) => {
    ev.preventDefault();
    try {
      if (isPasswordMatch) {
        await api.post("/user/register", {
          email: inputEmail.trim(),
          password: inputPassword,
          accessKey: inputKey,
        });

        await login(inputEmail, inputPassword);
        history.push("/after-register");
      }
    } catch (err) {
      props.setError(err.message);
    }
  };

  const handlePasswordBlur = () => {
    setIsPasswordMatch((inputPasswordRepeat !== "" && inputPassword !== "") ? inputPassword === inputPasswordRepeat : true);
  };

  return (
    <form className="form-box" onSubmit={handleRegisterFormSubmit}>
      <h2 className="form-box__form-title">
        {Store.getText("registerButtonFormText")}
      </h2>
      <p className="form-box__slogan form-box__slogan--register">
        {Store.getText("registerSlogan")}
      </p>
      <div className="form-box__input-wrapper">
        <label className="form-box__input-label">
          {Store.getText("activationCodeLabel")}
        </label>
        <input
          className="form-box__input"
          type="text"
          onChange={(ev) => setInputKey(ev.target.value)}
          value={inputKey}
        ></input>
      </div>
      <p className="form-box__input-desc">
        ({Store.getText("activationCodeDesc")})
      </p>

      <div className="form-box__input-wrapper">
        <label className="form-box__input-label">
          {Store.getText("emailLabel")}
        </label>
        <input
          className="form-box__input"
          type="email"
          onChange={(ev) => setInputEmail(ev.target.value.toLowerCase())}
          value={inputEmail}
        ></input>
      </div>
      <p className="form-box__input-desc">
        ({Store.getText("registerEmailDesc")})
      </p>
      <div className="form-box__input-wrapper">
        <label className="form-box__input-label">
          {Store.getText("passwordLabel")}
        </label>
        <div className="form-box__password-wrapper">
        <input
          className="form-box__input"
          type={inputPasswordShow ? "text" : "password"}
          onChange={(ev) => setInputPassword(ev.target.value)}
          value={inputPassword}
          onBlur={handlePasswordBlur}
        ></input>
        <button className="form-box__password-icon-button" type="button" title={inputPasswordShow ? Store.getText("hidePassword") : Store.getText("showPassword")} onClick={() => setInputPasswordShow(!inputPasswordShow)}>
        {inputPasswordShow ? <i class="fas fa-eye-slash"></i> :
         <i class="fas fa-eye"></i>} 
        </button>
        </div>
      </div>
      <p className="form-box__input-desc">
        ({Store.getText("registerPasswordDesc")})
      </p>
      <div className="form-box__input-wrapper">
        <label className="form-box__input-label">
          {Store.getText("repeatPasswordLabel")}
        </label>
        <div className="form-box__password-wrapper">

        <input
          className="form-box__input"
          type={inputPasswordRepeatShow ? "text" : "password"}

          onChange={(ev) => setInputPasswordRepeat(ev.target.value)}
          onBlur={handlePasswordBlur}
          value={inputPasswordRepeat}
        ></input>
                <button className="form-box__password-icon-button" type="button" title={inputPasswordRepeatShow ? Store.getText("hidePassword") : Store.getText("showPassword")} onClick={() => setInputPasswordRepeatShow(!inputPasswordRepeatShow)}>
        {inputPasswordRepeatShow ? <i class="fas fa-eye-slash"></i> :
         <i class="fas fa-eye"></i>} 
        </button>
        </div>
      </div>
      <p className="form-box__input-desc">
        ({Store.getText("repeatPasswordDesc")})
      </p>
      {isPasswordMatch ? (
        ""
      ) : (
        <p className="form-box__alert">
          {Store.getText("passwordsNotMatchAlert")}
        </p>
      )}
      <ColouredButton className="orange-button up" type="submit">
        {Store.getText("registerButtonFormText")}
      </ColouredButton>
      <Link to="/">
        <TransparentButton
          returnBtn
          className="transparent-button--bottom-left"
        >
          {Store.getText("back")}
        </TransparentButton>
      </Link>
      <ContactSupport altBg />
    </form>
  );
}

export default RegisterBox;
