import { useHistory, useLocation } from "react-router";
import api from "./api/api";
import Store from "./Store";
import { useEffect } from "react";

function LoginChecker(props) {
  const history = useHistory();
  const location = useLocation()

  useEffect(() => {
    const token = Store.getToken();
    if (token) {
      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      if(["/", "/login", "register"].indexOf(location.pathname) !== -1) history.push("/select-language");
    } else history.push("/");
  }, []);
  return <>{props.children}</>;
}

export default LoginChecker;
