import "./MilestoneModal.css";
import Store from "../../Store";
import milestoneModalImage from "./ikona_grat.png";
import ColouredButton from "../Buttons/ColouredButton/ColouredButton";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import api from '../../api/api'

function MilestoneModal(props) {
  const [isLastLesson, setIsLastLesson] = useState(false)

  useEffect(async () => {
    const resLessons = await api.get(
      `/flashcard/lessons?&pairId=${props.languagePairId}`
    );
    const lastLesson = Math.max.apply(Math, resLessons.data.map(lesson => lesson.link_lesson)) 
    setIsLastLesson(parseInt(props.lesson) >= lastLesson)
  }, props.lesson)


  return (
    <div
      className={
        "milestone-modal" +
        (props.isModalActive ? " milestone-modal--active" : "")
      }
    >
      <div className="milestone-modal__box">
        <button
          onClick={() => props.setIsModalActive(false)}
          className="milestone-modal__closing-button"
        >
          <i className="milestone-modal__closing-icon fas fa-times"></i>
        </button>
        <h1 className="milestone-modal__header">
          {Store.getText("congratsText")}
        </h1>
        <p className="milestone-modal__text">

          Úspěšně jste dokončili lekci {props.lesson}

        </p>
        <div className="milestone-modal__flash-number-wrapper">
          <img
            src={milestoneModalImage}
            className="milestone-modal__flash-number-img"
          />
          <span className="milestone-modal__flash-number">
            {props.answeredKnow}
          </span>
        </div>
        <Link to={`/lessons/${props.languagePairId}`}>
        <ColouredButton
          className="orange-button orange-button--outlined round milestone-modal__button"
        >
         
          Vraťte se k výběru lekce
        </ColouredButton>
        </Link>
        <Link to={`/learning/${props.languagePairId}/lesson/${parseInt(props.lesson) + 1}`}>
        {!isLastLesson && <ColouredButton
          className="orange-button round milestone-modal__button"
        >
          Další lekce

        </ColouredButton>}
        </Link>
      </div>
    </div>
  );
}

export default MilestoneModal;
