import PageStructure from "../../components/PageStructure/PageStructure";
import "./GamesPage.css";

import { useHistory, useParams } from "react-router";
import ColouredReturnButton from "../../components/Buttons/ColouredReturnButton/ColouredReturnButton";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import api from "../../api/api";

import linkGame from "./linkGame.png";
import testGame from "./testGame.png";
import typeGame from "./typeGame.png";
import Store from "../../Store";
import setGaDimension from "../../common/setGaDimension";

function GamesPage(props) {
  const history = useHistory();
  const params = useParams();
  const [pairShort, setPairShort] = useState();

  useEffect(async () => {
    const pairShortRes = await api.get(
      `/language-pair/${params.id}/pair-short`
    );
    setPairShort(pairShortRes.data);
  });


  function onGameClick(game) {
    setGaDimension(4, game)

  }

  return (
    <PageStructure setError={props.setError}
altBgMap tutorialLink pairShort={pairShort}>
      <ColouredReturnButton
        className="coloured-return-button--top-left"
        onClick={history.goBack}
        pairShort={pairShort}
      >
        {Store.getText("back")}
      </ColouredReturnButton>
      <div className="games-page">
        <h1 className="games-page__main-heading">
          {Store.getText("chooseExercise")}
        </h1>
        <ul className="games-page__game-list">
          <li className="games-page__game-list-element">
            <Link
              to={`/games/link-game/${params.id}`}
              className="games-page__game-link"
              onClick={() => onGameClick('pary')}
            >
              {Store.getText("pairGameName")}
              <img className="games-page__game-link-icon" src={linkGame} />
            </Link>
          </li>
          <li className="games-page__game-list-element">
            <Link
              to={`/games/test-game/${params.id}`}
              className="games-page__game-link"
              onClick={() => onGameClick('jeden z czterech')}

            >
              {Store.getText("testGameName")}
              <img className="games-page__game-link-icon" src={testGame} />
            </Link>
          </li>
          <li className="games-page__game-list-element">
            <Link
              to={`/games/type-game/${params.id}`}
              className="games-page__game-link"
              onClick={() => onGameClick('wpisywanie słów')}

            >
              {Store.getText("typeGameName")}
              <img className="games-page__game-link-icon" src={typeGame} />
            </Link>
          </li>
        </ul>
      </div>
    </PageStructure>
  );
}

export default GamesPage;
