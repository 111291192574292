import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./Navbar.css";
import Store from "../../Store";
import logo from "./logo.png";
import logo_white from "./EEA_sygnet_bialy.png";
import cert from "./cert.png";
import games from "./games.png";
import ContactSupport from "../ContactSupport/ContactSupport";
import Modal from "../Modal/Modal";
import ColouredButton from "../Buttons/ColouredButton/ColouredButton";
import api from "../../api/api";
import TranslateBox from "../TranslateBox/TranslateBox";
import moment from "moment";
import ProgressModal from "../ProgressModal/ProgressModal";

function Navbar(props) {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const [isTranslateActive, setIsTranslateActive] = useState(false);


  const [changePasswordModalActive, setChangePasswordModalActive] =
    useState(false);
  const [inputPassword, setInputPassword] = useState("");
  const [inputPasswordShow, setInputPasswordShow] = useState(false);
  const [inputPasswordRepeat, setInputPasswordRepeat] = useState("");
  const [inputPasswordRepeatShow, setInputPasswordRepeatShow] = useState(false);
  const [isPasswordMatch, setIsPasswordMatch] = useState(true);

  const [isProgressModalActive, setIsProgressModalActive] = useState(false)

  let history = useHistory();

  function handleLogOut() {
    Store.removeToken();
    history.push("/"); // whichever component you want it to route to
  }

  const handlePasswordBlur = () => {
    setIsPasswordMatch(
      inputPasswordRepeat !== "" && inputPassword !== ""
        ? inputPassword === inputPasswordRepeat
        : true
    );
  };

  async function changePassword(ev) {
    try {
      ev.preventDefault();
      if (isPasswordMatch) {
        const res = await api.post(`/user/current/change-password`, {
          password: inputPassword,
        });
        setChangePasswordModalActive(false);
        history.push("/select-language");
      }
    } catch (err) {
      props.setError(err.message);
    }
  }

  return (
    <div
      className={
        "navbar-container" + (props.altBgNav ? " navbar-container--alt-bg" : "")
      }
    >

      <nav>
        <Link to="/" className="navbar-logo-link">
          <img src={logo} className={"navbar-logo"} />
          {props.altBgNav ? <div className="logo-oval-div"></div> : ""}
        </Link>
        <div className="navbar-buttons-wrapper">
        {!props.userIconInvisible && <Link to="/tutorial" className="navbar-tutorial-link navbar-tutorial-link--text">
            {Store.getText("howToLearn")}
        </Link>
}
        {props.pairShort && (
            <Link to={`/certificate/${props.pairShort}`} >
                 <svg data-name="Warstwa 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 156.96 194.4" className="navbar-icon navbar-icon--resize"> 
        kona_certfikatu
        <g>
            <path
                d="M64.19,0C65.61.58,68,1.45,70.35,2.54a17.65,17.65,0,0,0,14.88.51c1.28-.53,2.5-1.2,3.76-1.79C94.1-1.13,98.3,0,101.58,4.62a46.08,46.08,0,0,0,4,5.27c3.6,3.82,8.28,5.11,13.39,5.31,6.73.27,10.07,3.57,10.27,10.34.22,7.13,3.06,12.67,9.21,16.42a21.58,21.58,0,0,1,1.88,1.29c4,3,5.05,7,3.1,11.58-.52,1.22-1.13,2.39-1.7,3.6a17.29,17.29,0,0,0,.05,15.38c.51,1.08,1.07,2.15,1.55,3.25,2.13,4.79,1,8.93-3.26,12-1.29.92-2.64,1.74-3.89,2.72a17.73,17.73,0,0,0-6.85,13.42,34.57,34.57,0,0,1-.46,4.32,8.38,8.38,0,0,1-7.21,7.08c-2.17.39-4.41.43-6.59.75a17,17,0,0,0-11.55,7.27c-.67.92-1.29,1.88-1.94,2.81-3.3,4.74-7.42,5.82-12.71,3.36-1.54-.72-3.08-1.47-4.67-2.06a17,17,0,0,0-13.63.77c-1,.48-2,1-3.07,1.48-4.84,2.2-9.07,1.06-12.16-3.26-1.1-1.54-2.13-3.14-3.34-4.59a17.21,17.21,0,0,0-12.24-6.07l-.57,0c-8.59-.41-11.27-3.06-11.77-11.69a17.76,17.76,0,0,0-8.28-14.58c-.85-.56-1.68-1.13-2.51-1.72-4.18-3-5.69-7.31-3.21-11.78,4.18-7.56,4.25-14.73,0-22.3-2.49-4.48-1-8.78,3.13-11.81.87-.64,1.77-1.23,2.66-1.83A17.8,17.8,0,0,0,27.42,27c.08-1.13.09-2.28.27-3.4.83-5.28,4-8.06,9.37-8.45a38.33,38.33,0,0,0,6.92-1,16.59,16.59,0,0,0,9.43-6.93A49,49,0,0,1,57,2.61C58.55.85,60.67.05,64.19,0ZM78.43,27.23a38.83,38.83,0,1,0,38.8,38.9A38.79,38.79,0,0,0,78.43,27.23Z" />
            <path class="icon"
                d="M36.31,193.31l-2-3c-3.25-4.9-6.56-9.75-9.72-14.71a2.94,2.94,0,0,0-3.37-1.54C14.27,175,7.3,175.7,0,176.53l29.08-57.14c2.22,2.77,5.24,3.26,8.36,3.27a16.32,16.32,0,0,1,14.64,7.9A18.52,18.52,0,0,0,57,135.73c1.68,1.16,4,1.47,6.09,2.19Z" />
            <path
                d="M92.81,137.94c7.36.14,12.31-3.13,15.62-9.11a10.69,10.69,0,0,1,7-5.1,109.43,109.43,0,0,1,10.77-2.08c.54-.08,1.49.74,1.83,1.37q12.47,23.08,24.84,46.24c1.21,2.28,2.43,4.56,3.85,7.2-3.2-.39-6.1-.73-9-1.1-4.2-.55-8.4-1.07-12.58-1.72-1.28-.2-1.64.4-2,1.39-2.25,5.9-4.54,11.78-6.81,17.67-.18.47-.39.92-.72,1.7C114.65,175.52,103.81,156.88,92.81,137.94Z" />
            <path
                d="M103.8,60c-3.57,3.84-7.2,7.8-10.92,11.66a3.12,3.12,0,0,0-1,3.14c1,5.27,1.83,10.56,2.77,16.11-.63-.25-1.08-.38-1.49-.59-4.36-2.28-8.75-4.53-13.07-6.9a3.05,3.05,0,0,0-3.31,0c-4.71,2.56-9.48,5-14.62,7.71.56-3.41,1.06-6.47,1.57-9.53.42-2.43,1-4.84,1.22-7.28A3.52,3.52,0,0,0,64,71.87c-3.64-4-7.38-8-11-11.85,2-.31,4.64-.72,7.27-1.11,2.88-.42,5.78-.73,8.63-1.28a3.4,3.4,0,0,0,2-1.62c2.49-4.82,4.85-9.7,7.4-14.83.4.67.69,1.11.92,1.58,2.17,4.35,4.35,8.7,6.45,13.09a3,3,0,0,0,2.68,1.93C93.7,58.46,99,59.29,103.8,60Z" />
        </g>
    </svg>


            </Link>
          )}

            {props.pairShort && <svg xmlns="http://www.w3.org/2000/svg" className="navbar-icon navbar-icon--resize" viewBox="0 0 24 24" onClick={() => setIsTranslateActive(!isTranslateActive)}>
            <g id="search">
	<path class="st1" d="M17.4,15.3c-0.1-0.1-1.1,0-1.1,0L15.9,15c1.7-2,2.5-4.6,2.1-7.5c-0.6-3.9-3.9-7-7.8-7.4
		C4.3-0.7-0.7,4.3,0.1,10.2c0.5,3.9,3.6,7.1,7.4,7.8c2.8,0.5,5.5-0.4,7.5-2.1l0.4,0.4c0,0-0.1,1,0,1.1c0.1,0.1,6.3,6.3,6.3,6.3
		c0.4,0.4,1,0.4,1.4,0l0.7-0.7c0.4-0.4,0.4-1,0-1.4C23.7,21.6,17.6,15.5,17.4,15.3z M9.1,15.3c-3.5,0-6.3-2.8-6.3-6.3
		s2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3S12.5,15.3,9.1,15.3z"/>
</g>
              </svg>}
          <button
            className={
              "user-account-button" +
              (isMenuActive ? " active" : "") +
              (props.userIconInvisible ? " invisible" : "")
            }
            onClick={() => {
              setIsMenuActive(!isMenuActive);
            }}
          >
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" className="navbar-icon"><path d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z"/></svg>
            <div className="menu-list">
              <header className="menu-list_header">
                <p className="menu-list_header-text">{Store.getText("yourAccount")}</p>
                <p className="menu-list_header-text">{Store.getEmail()}</p>
                
                </header>
              <button className="menu-list-button" onClick={handleLogOut}>
                <div className="menu-list-element">
                  {Store.getText("logoutButtonText")}
                </div>
              </button>
             

              <button className="menu-list-button menu-list-button--help">
                <div className="menu-list-element">
                  {Store.getText("helpText")}
                </div>
                <div className="help-button_notification">
        <p className="help-button_notification-content">
          {Store.getText("contactText")}
        </p>
      </div>
              </button>

              {Store.isAdmin() && (
                <Link className="menu-list-link" to="/admin">
                  <div className="menu-list-element">Admin panel</div>
                </Link>
              )}
              {props.pairShort && (
              <button
                className="menu-list-button menu-list-button--activity-button"
                onClick={() => {
                  setIsProgressModalActive(true)
                }}
              >
                <div className="menu-list-element">
                {Store.getText("progressModalBookmarkText")}

                </div>
              </button>)}

              <button
                className="menu-list-button"
                onClick={() => setChangePasswordModalActive(true)}
              >
                <div className="menu-list-element">
                  {Store.getText("changePassword")}
                </div>
              </button>


            </div>
          </button>

          
        </div>
        <TranslateBox setError={props.setError} pairShort={props.pairShort} isTranslateActive={isTranslateActive} setIsTranslateActive={setIsTranslateActive}></TranslateBox>

      </nav>

      <Modal
        isModalActive={changePasswordModalActive}
        setIsModalActive={setChangePasswordModalActive}
      >
        {" "}
        <form onSubmit={(ev) => changePassword(ev)}>
            <div className="form-box__input-wrapper">
              <label className="form-box__input-label">
                {Store.getText("passwordLabel")}
              </label>
              <div className="form-box__password-wrapper">
                <input
                  className="form-box__input form-box__input--change-password-modal"
                  type={inputPasswordShow ? "text" : "password"}
                  onChange={(ev) => setInputPassword(ev.target.value)}
                  value={inputPassword}
                  onBlur={handlePasswordBlur}
                ></input>
                <button
                  className="form-box__password-icon-button"
                  type="button"
                  title={
                    inputPasswordShow
                      ? Store.getText("hidePassword")
                      : Store.getText("showPassword")
                  }
                  onClick={() => setInputPasswordShow(!inputPasswordShow)}
                >
                  {inputPasswordShow ? (
                    <i class="fas fa-eye-slash"></i>
                  ) : (
                    <i class="fas fa-eye"></i>
                  )}
                </button>
              </div>
            </div>
            <p className="form-box__input-desc">
              ({Store.getText("registerPasswordDesc")})
            </p>
            <div className="form-box__input-wrapper">
              <label className="form-box__input-label">
                {Store.getText("repeatPasswordLabel")}
              </label>
              <div className="form-box__password-wrapper">
                <input
                  className="form-box__input form-box__input--change-password-modal"
                  type={inputPasswordRepeatShow ? "text" : "password"}
                  onChange={(ev) => setInputPasswordRepeat(ev.target.value)}
                  onBlur={handlePasswordBlur}
                  value={inputPasswordRepeat}
                ></input>
                <button
                  className="form-box__password-icon-button"
                  type="button"
                  title={
                    inputPasswordRepeatShow
                      ? Store.getText("hidePassword")
                      : Store.getText("showPassword")
                  }
                  onClick={() =>
                    setInputPasswordRepeatShow(!inputPasswordRepeatShow)
                  }
                >
                  {inputPasswordRepeatShow ? (
                    <i class="fas fa-eye-slash"></i>
                  ) : (
                    <i class="fas fa-eye"></i>
                  )}
                </button>
              </div>
            </div>
            <p className="form-box__input-desc">
              ({Store.getText("repeatPasswordDesc")})
            </p>
            {isPasswordMatch ? (
              ""
            ) : (
              <p className="form-box__alert">
                {Store.getText("passwordsNotMatchAlert")}
              </p>
            )}
            <ColouredButton
              className="orange-button up add-key-button"
              type="submit"
            >
              {Store.getText("changePassword")}
            </ColouredButton>
        </form>
      </Modal>
      {props.pairShort && (
      <ProgressModal isModalActive={isProgressModalActive} setIsModalActive={setIsProgressModalActive}></ProgressModal>)}

    </div>
  );
}

export default Navbar;
