import "./LanguageSelection.css";
import PageStructure from "../../components/PageStructure/PageStructure";
import { useEffect, useState } from "react";
import api from "../../api/api";
import Store from "../../Store";

import { Link, useHistory } from "react-router-dom";
import getLanguageFlag from "./getLanguageFlag";



function LanguageSelectionStepOne(props) {
  const history = useHistory()
  const [availableLanguageShortFrom, setAvailableLanguageShortFrom] = useState([]);


  useEffect(async () => {
    const languagePairAvailable = await api.get("/language-pair/available");

    const availableShortFromPairs = [];

    languagePairAvailable.data.forEach((pair) => {
      if (availableShortFromPairs.indexOf(pair.languageFrom.languageShort) === -1) availableShortFromPairs.push(pair.languageFrom.languageShort);
    });
    setAvailableLanguageShortFrom(availableShortFromPairs)

    if(availableShortFromPairs.length === 1) history.push(`/select-language/${availableShortFromPairs[0]}?onlyOneLanguageFrom=1`)
  }, []);

  return (
    <PageStructure setError={props.setError}
title={Store.getText("chooseLanguagePageTitle")} altBgMap supportIcon tutorialLink>
      <div className="language-selection-page-content">
        {availableLanguageShortFrom.map((short) => {
          return (
            <Link className="language-pair-link" to={`/select-language/${short}`}>
              <img
                className="language-flag"
                src={getLanguageFlag(short)}
              />
              <p className="language-pair">
                {Store.getLanguageNameByShort(short)}
              </p>
            </Link>
          );
        })}
      </div>
    </PageStructure>
  );
}

export default LanguageSelectionStepOne;
