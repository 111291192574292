import Skeleton from "react-loading-skeleton";
import Store from "../../Store";
import "./FlashContent.css";

function FlashContent(props) {
  const playSound = (sound) => {
    const snd = new Audio(sound);
    snd.play();
  };
  return (
    <div className="flash-card">
      {props.isLoaded ? (
        <div className="flash-picture-wrapper">
          {props.picture && <img className="flash-picture" src={props.picture.base64} />}
        </div>
      ) : (
        <Skeleton width={window.innerWidth > 620 ? 500 : window.innerWidth * 0.85} height={window.innerWidth > 620 ? 350 : ((window.innerWidth * 0.85) / 1.4)} />
      )}
      <div className="flash-content">
        <h2 className="flash-word">
          {props.isLoaded ? props.word : <Skeleton width={200} />}
        </h2>
        {props.wordSound && <i
          className="icon fas fa-volume-up"
          onClick={() => playSound(props.wordSound)}
        ></i>}
      </div>
      <div className="flash-content">
        <p className="flash-phrase">
          {props.isLoaded ? props.phrase : <Skeleton width={100} count={3} />}
        </p>
        { props.phraseSound &&
        <i
          className="icon fas fa-volume-up"
          onClick={() => playSound(props.phraseSound)}
        ></i>
} 
      </div>
      <button className="turnover-button" onClick={props.flipPage}>
        {Store.getText('rotate')}
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet" className="flash-icon">

<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
stroke="none">
<path d="M2140 4874 c-553 -51 -1043 -274 -1434 -654 -343 -333 -569 -747
-660 -1210 -202 -1022 300 -2047 1233 -2519 295 -149 603 -231 929 -246 111
-6 131 -4 162 12 69 36 71 47 68 425 l-3 338 -31 31 c-31 30 -35 32 -180 45
-239 23 -400 68 -583 164 -135 71 -242 151 -356 265 -253 253 -391 549 -426
911 -10 97 -10 151 0 248 35 362 173 658 426 911 114 114 221 194 356 265 622
326 1395 162 1829 -389 146 -185 240 -390 291 -631 18 -87 22 -136 22 -285 -1
-99 -5 -196 -10 -215 l-9 -35 -229 -5 c-253 -6 -262 -8 -291 -68 -36 -75 -41
-68 419 -530 277 -277 436 -429 463 -442 80 -39 65 -51 546 429 302 301 439
445 444 464 11 45 -12 105 -49 127 -29 18 -51 20 -234 20 l-203 0 7 167 c34
922 -464 1757 -1295 2172 -208 104 -446 179 -682 216 -101 15 -428 27 -520 19z"/>
</g>
</svg>      </button>
    </div>
  );
}

export default FlashContent;
