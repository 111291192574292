import Store from "../../Store";
import ColouredButton from "../Buttons/ColouredButton/ColouredButton";
import "./LoginBox.css";
import api from "../../api/api";
import { useHistory } from "react-router";
import { useState } from "react";
import { Link } from "react-router-dom";
import TransparentButton from "../Buttons/TransparentButton/TransparentButton";
import ContactSupport from "../ContactSupport/ContactSupport";

function LoginBox(props) {
  const history = useHistory();
  const [inputEmail, setInputEmail] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [inputPasswordShow, setInputPasswordShow] = useState(false);

  const login = async (email, password) => {
    const res = await api.post("/auth/login", {
      email: email,
      password: password,
    });

    const token = res.data.token;
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    Store.setToken(token);

    history.push("/select-language");
  };

  const handleLoginFormSubmit = async (ev) => {
    ev.preventDefault();
    try {
      await login(inputEmail, inputPassword);
    } catch (err) {
      props.setError(err.message);
    }
  };
  return (
    <form className="form-box" onSubmit={handleLoginFormSubmit}>
      <h2 className="form-box__form-title">
        {Store.getText("loginButtonFormText")}
      </h2>
      <p className="form-box__slogan form-box__slogan--login">
        {Store.getText("loginSlogan")}
      </p>
      <div className="form-box__input-wrapper">
        <label className="form-box__input-label">
          {Store.getText("emailLabel")}
        </label>
        <input
          className="form-box__input"
          type="email"
          onChange={(ev) => setInputEmail(ev.target.value)}
          value={inputEmail}
        ></input>
      </div>
      <p className="form-box__input-desc">
        ({Store.getText("loginEmailDesc")})
      </p>
      <div className="form-box__input-wrapper">
        <label className="form-box__input-label">
          {Store.getText("passwordLabel")}
        </label>
        <div className="form-box__password-wrapper">
        <input
          className="form-box__input"
          type={inputPasswordShow ? "text" : "password"}
          onChange={(ev) => setInputPassword(ev.target.value)}
          value={inputPassword}
        ></input>
        <button className="form-box__password-icon-button" type="button" title={inputPasswordShow ? Store.getText("hidePassword") : Store.getText("showPassword")} onClick={() => setInputPasswordShow(!inputPasswordShow)}>
        {inputPasswordShow ? <i class="fas fa-eye-slash"></i> :
         <i class="fas fa-eye"></i>} 
        </button>
        </div>
      </div>
      <p className="form-box__input-desc">
        ({Store.getText("loginPasswordDesc")})
      </p>
      <ColouredButton className="blue-button up" type="submit">
        {Store.getText("loginButtonFormText")}
      </ColouredButton>
      <Link to="/">
        <TransparentButton
          returnBtn
          className="transparent-button--bottom-left"
        >
          {Store.getText("back")}
        </TransparentButton>
      </Link>
      <ContactSupport altBg />
    </form>
  );
}

export default LoginBox;
