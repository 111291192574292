import Table from "rc-table";
import { useEffect, useState } from "react";
import api from "../../api/api";
import EditFlashcard from "../../components/EditFlashcard/EditFlashcard";
import "./UserTable.css";
import PageStructure from "../../components/PageStructure/PageStructure";
import Modal from "../../components/Modal/Modal";
import { Link } from "react-router-dom";
const queryString = require('query-string');


const PER_PAGE = 100;


const Pagination = ({offset, limit, allCount, changePage}) => {

  return (
    <div className="user-table-pagination-wrapper">
      {" "}
      <button disabled={offset <= 0} onClick={() => changePage(-1)} className="user-table-pagination-button">
        Poprzednia strona
      </button>
      <div>
        <p>
          Strona: {Math.floor(offset / limit) + 1} z{" "}
          {Math.ceil(allCount / limit)}
        </p>
        <p>
          Wyświetlani użytkownicy: {offset + 1} -{" "}
          {offset + limit < allCount ? offset + limit : allCount} z{" "}
          {allCount}
        </p>
      </div>
      <button disabled={offset + limit >= allCount} onClick={() => changePage(1)} className="user-table-pagination-button">
        Następna Strona
      </button>
    </div>
  );
};

function UserTable(props) {
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 200
    },
    {
      title: "Is blocked",
      dataIndex: "isBlocked",
      key: "isBlocked",
      width: 50,
  
      render: (row) => {
        return row ? 'x' : '';
      },
    },

    {
      title: "Akcje",
      dataIndex: "id",
      key: "id",
  
      render: (row) => {
        return <div className="user-table__buttons-wrapper">
          <button className="user-table__edit-button" onClick={() => {
            setChangePasswordModalOpen(true)
            setCurrentUser(row)
          }}>Zmień hasło</button>
          <button className="user-table__edit-button user-table__edit-button--red" onClick={() => onToggleBlock(row)}> Zablokuj / Odblokuj</button>
        </div>
      },
    },
  
  ];

  
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [currentEmail, setCurrentEmail] = useState("");

  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [currentUser, setCurrentUser] = useState();

  const afterUpdate = async () => {
    await load(currentPage, currentEmail);
    setCurrentUser()
    setChangePasswordModalOpen(false)
  };


  const changePage = async (diff) => {
    setCurrentPage(currentPage + diff)
    await load(currentPage + diff, currentEmail)
  }

  const load = async (page, email) => {

    const qsObj = {
      offset: (page - 1) * PER_PAGE,
      limit: PER_PAGE,
      email
    }
    const res = await api.get(
      `/user?${queryString.stringify(qsObj)}`
    );
    setCount(res.data.count);
    setUsers(res.data.users.filter(user => user.id !== 1));


  };

  const findUsers = async () => {
    setCurrentPage(1)
    await load(1, currentEmail)
  }

  const onToggleBlock = async (id) => {
    await api.post(`/user/${id}/toggle-block`)
    await afterUpdate()
  }

  const onPasswordChange = async (e) => {
    e.preventDefault();
    
    await api.post(`/user/${currentUser}/change-password`, {password})
    await afterUpdate()
  }

  useEffect(() => {
    setPassword("")
  }, [changePasswordModalOpen])

  useEffect(async () => {
    await load(currentPage, currentEmail);
  }, []);


  return (
    <PageStructure setError={props.setError}
altBg>

<div>
  <Link to="/admin/users"><button>Użytkownicy</button></Link>
  <Link to="/admin"><button>Fiszki</button></Link>
</div>
<div className="user-table">

 <div>
         <input value={currentEmail} onChange={(e) => setCurrentEmail(e.target.value)} placeholder="email bądź jego część"/>
          <button onClick={findUsers} className="user-table-button-std">Znajdź</button>
      </div>
      <Pagination offset={(currentPage - 1) * PER_PAGE} limit={PER_PAGE} allCount={count} changePage={changePage}/>

     
        <Table
          data={users}
          columns={columns}
          onRow={(row) => {
            return {
              onClick: () => {
                // setCurrentFlashcard(row);
                // setIsModalOpen(true);
              },
            };
          }}
        ></Table>
      <Modal setIsModalActive={setChangePasswordModalOpen} isModalActive={changePasswordModalOpen}>
        <form onSubmit={onPasswordChange}>
          {currentUser && <p>{users.find(user => user.id === currentUser).email}</p>}
          <input placeholder="hasło" value={password} onChange={(e) => setPassword(e.target.value)}></input>
          <button type="submit" className="user-table-button-std">Zapisz</button>
        </form>
        </Modal>
    <Pagination offset={(currentPage - 1) * PER_PAGE} limit={PER_PAGE} allCount={count} changePage={changePage}/>
</div>

    </PageStructure>

  );
}

export default UserTable;
