import image1Cs from "./csImages/image1.jpg";
import image2Cs from "./csImages/image2.jpg";
import image3Cs from "./csImages/image3.jpg";

import image1Sk from "./skImages/image1.jpg";
import image2Sk from "./skImages/image2.jpg";
import image3Sk from "./skImages/image3.jpg";

import image1Pl from "./plImages/image1.jpg";
import image2Pl from "./plImages/image2.jpg";
import image3Pl from "./plImages/image3.jpg";





const langImg = {
    image1: {
        cs: image1Cs,
        pl: image1Pl,
        sk: image1Sk,
    },
    image2: {
        cs: image2Cs,
        pl: image2Pl,
        sk: image2Sk,
    },
    image3: {
        cs: image3Cs,
        pl: image3Pl,
        sk: image3Sk,
    },    
}



const getNotificationImage = (imgName, langShort) => {
    return langImg[imgName][langShort]
}

export default getNotificationImage