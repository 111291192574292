import { useState } from "react";
import Store from "../../Store";
import ColouredButton from "../Buttons/ColouredButton/ColouredButton";
import Modal from "../Modal/Modal";
import api from "../../api/api"
import { useHistory } from "react-router";

import './AddKeyModal.css'

function AddKeyModal(props) {
  const [accessKey, setAccessKey] = useState("");

  const history = useHistory()

  async function addKey(ev) {
    try {
      ev.preventDefault();
      const res = await api.post(`/user/current/add-key`, { accessKey });
      props.setIsModalActive(false);
      history.push("/select-language");
    } catch (err) {
      props.setError(err.message);
    }
  }

  return (
    <Modal
      isModalActive={props.isModalActive}
      setIsModalActive={props.setIsModalActive}
    >
      {" "}
      <form onSubmit={(ev) => addKey(ev)}>
        <div className="add-key-modal">
            <p className="add-key-modal__header">{Store.getText("addLanguageModalUpperTextFirst")}</p>
            <p className="add-key-modal__text">{Store.getText("addLanguageModalUpperTextSecond")}</p>

        

<div className="form-box__input-wrapper form-box__input-wrapper--add-key-modal">
        <label className="form-box__input-label form-box__input-label--add-key-modal">
          {Store.getText("activationCodeLabel")}
        </label>
        <input
            className="form-box__input form-box__input--margin"
            type="text"
            required={true}
            onChange={(ev) => setAccessKey(ev.target.value)}
            value={accessKey}
          ></input>
      </div>
          <button
            className="add-key-modal__button"
            type="submit"
          >
            {Store.getText("addLanguageModalButtonText")}
          </button>
          <p className="add-key-modal__header">{Store.getText("addLanguageModalLowerTextFirst")}</p>
          <p className="add-key-modal__text">{Store.getText("addLanguageModalLowerTextSecond")}</p>
          <p className="add-key-modal__text">{Store.getText("addLanguageModalLowerTextThird")}</p>

        </div>
      </form>
    </Modal>
  );
}

export default AddKeyModal;
