import "./Modal.css";


function Modal(props) {




  return (
    <div
      className={
        "modal" +
        (props.isModalActive ? " modal--active" : "")
      }
    >
      <div className="modal__box">
      <button
          onClick={() => props.setIsModalActive(false)}
          className="modal__closing-button"
        >
          <i className="modal__closing-icon fas fa-times"></i>
        </button>
        {props.children}

      </div>
    </div>
  );
}

export default Modal;
