import "./HomePage.css";
import PageStructure from "../../components/PageStructure/PageStructure";
import {
  Switch,
  Route,
  useRouteMatch,
  Link,
  useLocation,
} from "react-router-dom";
import Store from "../../Store";
import HomePageBox from "../../components/HomePageBox/HomePageBox";
import LoginBox from "../../components/LoginBox/LoginBox";
import RegisterBox from "../../components/RegisterBox/RegisterBox";
import LoginChecker from "../../LoginChecker";
import ChoosePlatformLanguage from "../ChoosePlatformLanguage/ChoosePlatformLanguage";

import "react-alice-carousel/lib/alice-carousel.css";
import AliceCarousel from "react-alice-carousel";
import { useEffect, useRef, useState } from "react";



function HomePage(props) {
  // const [autoPlay, setAutoPlay] = useState(false);
  const bgCarousel = useRef(null);
  const textCarousel = useRef(null);

  const sloganCarouselItmes = [
    <p className="slogan-text">{Store.getText("mainPageSloganSecond")}</p>,
    <p className="slogan-text">{Store.getText("mainPageSloganSecond2")}</p>,
    <p className="slogan-text">{Store.getText("mainPageSloganSecond3")}</p>,
    <p className="slogan-text">{Store.getText("mainPageSloganSecond4")}</p>,
    <p className="slogan-text">{Store.getText("mainPageSloganSecond6")}</p>,
    <p className="slogan-text">{Store.getText("mainPageSloganSecond7")}</p>,
    <p className="slogan-text">{Store.getText("mainPageSloganSecond8")}</p>,
    <p className="slogan-text">{Store.getText("mainPageSloganSecond9")}</p>,
  ];
  
  const backdroundCarouselItmes = [
    <div className="homepage-bg-slide homepage-bg-slide--1"></div>,
    <div className="homepage-bg-slide homepage-bg-slide--2"></div>,
    <div className="homepage-bg-slide homepage-bg-slide--3"></div>,
    <div className="homepage-bg-slide homepage-bg-slide--4"></div>,
    <div className="homepage-bg-slide homepage-bg-slide--6"></div>,
    <div className="homepage-bg-slide homepage-bg-slide--7"></div>,
    <div className="homepage-bg-slide homepage-bg-slide--8"></div>,
    <div className="homepage-bg-slide homepage-bg-slide--9"></div>,
  ];

  const { path } = useRouteMatch();

  useEffect(() => {
    const interval = setInterval(() => {
      bgCarousel.current.slideNext();
      textCarousel.current.slideNext();
    }, 4000);
    return () => clearInterval(interval)
  }, []);
  return (
    <PageStructure
      setError={props.setError}
      userIconInvisible={true}
      altBgNav
      altLogo
      contentVerticalCentered
    >
      <div className="homepage-bg-slide-container">
        <AliceCarousel
          mouseTracking
          items={backdroundCarouselItmes}
          responsive={{ 0: { items: 1 } }}
          ref={bgCarousel}

          infinite
          disableButtonsControls
          disableDotsControls
          swipeDelta={9999999999}

        />
      </div>
      <LoginChecker />
      <div className="homepage-content">
        <div className="slogan-container">
          <p className="slogan-text slogan-text--bold" key={Store.getText("mainPageSloganFirst")}>
            {Store.getText("mainPageSloganFirst")}
          </p>
          <div className="second-slogan-container">
            <AliceCarousel
              mouseTracking
              items={sloganCarouselItmes}
              ref={textCarousel}
              responsive={{ 0: { items: 1 } }}
              autoWidth
              infinite
              disableButtonsControls
              disableDotsControls
              swipeDelta={9999999999}
            />
          </div>
        </div>
        <Switch>
          <Route exact path={path}>
            <HomePageBox {...props} />
          </Route>
          <Route path={`${path}login`}>
            <LoginBox {...props} />
          </Route>
          <Route path={`${path}register`}>
            <RegisterBox {...props} />
          </Route>
          <Route path="/:platformLangShort" exact>
            <ChoosePlatformLanguage {...props} />
          </Route>
        </Switch>
      </div>
    </PageStructure>
  );
}

export default HomePage;
