import Store from "../../Store"
import "./Notification.css";
import api from "../../api/api"
import ColouredButton from "../Buttons/ColouredButton/ColouredButton"

import image1 from "./image1.jpg";
import image2 from "./image2.png";
import { useEffect } from "react";
import getNotificationImage from "./getNotificationImage";

const NOTIFICATION_ID = 1


function Notification(props) {
  useEffect(async() => {
    try {    
      const res = await api.get(`/notification/${NOTIFICATION_ID}`)
      props.setIsModalActive(!res.data)
    } catch(e) {
      console.log(e)
    }

  }, [])




  return (
    <div
      className={
        "notification" +
        (props.isModalActive ? " notification--active" : "")
      }
    >
      <div className="notification__box">
      <button
          onClick={() => props.setIsModalActive(false)}
          className="notification__closing-button"
        >
          <i className="notification__closing-icon fas fa-times"></i>
        </button>
        <h2 className="notification__header">{Store.getText("notificationHeader")}</h2>
        <p className="notification__text notification__text--center notification__text--short">{Store.getText("notificationDescription")}</p>
        <ol className="notification__ordered-list">
          <li className="notification__ordered-list-element">
            <span className="notification__ordered-list-element-number">1</span>
            <div className="notification__ordered-list-wrapper">
            <p className="notification__text-header">{Store.getText('notificationList1Header')}</p>
            <p className="notification__text">{Store.getText('notificationList1Text1')}</p>
            <img src={getNotificationImage('image1', Store.getUserLangShort())} className="notification__img notification__img--bigger"/>
            <p className="notification__text">{Store.getText('notificationList1Text2')}</p>
            </div>
          </li>
          <li className="notification__ordered-list-element">
          <span className="notification__ordered-list-element-number">2</span>

          <div className="notification__ordered-list-wrapper">

            <p className="notification__text-header">{Store.getText('notificationList2Header')}</p>
            <p className="notification__text">{Store.getText('notificationList2Text1')}</p>
            <img src={getNotificationImage('image2', Store.getUserLangShort())} className="notification__img"/>


            <p className="notification__text">{Store.getText('notificationList2Text2')}</p>
            </div>
          </li>
          <li className="notification__ordered-list-element">
          <span className="notification__ordered-list-element-number">3</span>

          <div className="notification__ordered-list-wrapper">

            <p className="notification__text-header">{Store.getText('notificationList3Header')}</p>
            <p className="notification__text">{Store.getText('notificationList3Text1')}</p>
            <img src={getNotificationImage('image3', Store.getUserLangShort())} className="notification__img notification__img--smaller"/>

            <p className="notification__text">{Store.getText('notificationList3Text2')}</p>
            </div>
          </li>
        </ol>
        <ColouredButton className="orange-button up" onClick={() => props.setIsModalActive(false)}>
        OK!
      </ColouredButton>
      </div>
    </div>
  );
}

export default Notification;
