import de from "./images/de.png";
import en from "./images/en.png";
import ru from "./images/ru.png";
import es from "./images/es.png";
import fr from "./images/fr.png";
import it from "./images/it.png";
import nl from "./images/nl.png";
import pl from "./images/pl.png";
import cs from "./images/cs.png";
import sk from "./images/sk.png";
import pt from "./images/pt.png";

const languageFlags = {
  de,
  en,
  ru,
  es,
  fr,
  it,
  nl,
  pl, cs,sk, pt
};

const getOutlinedLanguageFlag = (short) => {
  return languageFlags[short] ? languageFlags[short] : languageFlags.en;
};

export default getOutlinedLanguageFlag;
