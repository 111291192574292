import "./PageStructure.css";
import Navbar from "../Navbar/Navbar";
import { Link } from "react-router-dom";
import ColouredReturnButton from "../Buttons/ColouredReturnButton/ColouredReturnButton";
import ContactSupport from "../ContactSupport/ContactSupport";
import Store from "../../Store";

function PageStructure(props) {
  return (
    <div
      className={
        "page-container" +
        (props.altBg ? " page-container--alt-bg" : "") +
        (props.altBgMap ? " page-container--alt-bg-map" : "") + 
        (props.whiteBg ? " page-container--white-bg" : "")
      }
    >
      <Navbar
        userIconInvisible={props.userIconInvisible}
        altBgNav={props.altBgNav}
        altLogo={props.altLogo}
        supportIcon={props.supportIcon}
        pairShort={props.pairShort}
        setError={props.setError}
      ></Navbar>
      {props.title && <h1 className="page-title">{props.title}</h1>}


      <div className="page-content">{props.children}</div>
    </div>
  );
}

export default PageStructure;
