import "./ChooseLesson.css";
import PageStructure from "../../components/PageStructure/PageStructure";
import ColouredButton from "../../components/Buttons/ColouredButton/ColouredButton";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import api from "../../api/api";
import Navbar from "../../components/Navbar/Navbar";
import Store from "../../Store";

import lessonLabel from "./pieciokat.png"

import ColouredReturnButton from "../../components/Buttons/ColouredReturnButton/ColouredReturnButton";
import setGaDimension from "../../common/setGaDimension";


function ChooseLesson() {
  const [lessons, setLessons] = useState([])
  const [lessonsWithImages, setLessonsWithImages] = useState([])
  const [pairShort, setPairShort] = useState()
  const [level, setLevel] = useState()

  const params = useParams();
  const history = useHistory()

  useEffect(async () => {
    let lessonsCount = api.get(
      `/flashcard/counts/language-pair/${params.pairId}`
    );

    let pairShortRes = api.get(
      `/language-pair/${params.pairId}/pair-short`
    );

    let levelRes = api.get(
      `/language-pair/${params.pairId}/level`
    );


    let lessonImages = api.get(
      `/lesson/language-pair/${params.pairId}`
    );

    [lessonsCount, pairShortRes, levelRes, lessonImages] = await Promise.all([lessonsCount, pairShortRes, levelRes, lessonImages])

    setPairShort(pairShortRes.data)
    setLevel(levelRes.data)
    setLessonsWithImages(lessonImages.data)
    setLessons(lessonsCount.data.sort((a, b) => a.lesson - b.lesson))
  }, [])
 
  const getAddionalClassNameForLessonProgress = (lesson) => {
    if(lesson.count === lesson.allCount) return 'lesson-progress--finished'
    if(lesson.count > 0) return 'lesson-progress--started'
    return ''
  } 

  const getImageBase64ForLessonNumber = (number) => {
    return lessonsWithImages.find(lesson => lesson.number === number).image.base64
  }

  function onLessonClick(lesson) {
    setGaDimension(3, lesson)

  }
 
  return (
    <PageStructure
          
      title={Store.getText('chooseLesson')}
      altBgMap
      supportIcon
      tutorialLink
      pairShort={pairShort}
    >
      <div className="lesson-selection-page-content">
        <Link to={`/choose-activity/${params.pairId}`}>
          <ColouredReturnButton className="coloured-return-button--top-left">
          {Store.getText('back')}


          </ColouredReturnButton>
        </Link>

        <ul className="lessons-list">
          {lessons.map((lesson, i) => <Link to={`/learning/${params.pairId}/lesson/${lesson.lesson}`} onClick={() => onLessonClick(`lekcja ${lesson.lesson}`)} className="lesson-link" key={i}><li className="lesson-wrapper">
              <div className="lesson-img-wrapper">
              <img className="lesson-img" src={getImageBase64ForLessonNumber(lesson.lesson)}/>
              {/* <div className="lesson-number-wrapper">

                  <img src={lessonLabel} className="lesson-number-img"/>
                  <span className="lesson-number-number">{lesson.lesson}</span>
                </div> */}
            </div>
            <div className="lesson-text-wrapper">
              <div className="lesson-title"><span>{Store.getLessonName(level, lesson.lesson)}</span> </div>
              <div className="lesson-progress-wrapper">
              <div className={`lesson-progress ${getAddionalClassNameForLessonProgress(lesson)}`}>{lesson.count}/{lesson.allCount}</div>
              </div>
            </div>
            </li></Link>)}
        </ul>

      </div>
    </PageStructure>
  );
}

export default ChooseLesson;
