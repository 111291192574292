import { Link } from "react-router-dom";
import Store from "../../Store";
import ColouredButton from "../Buttons/ColouredButton/ColouredButton";
import TransparentButton from "../Buttons/TransparentButton/TransparentButton";
import "./ContactSupport.css";

function ContactSupport(props) {
  return props.colouredBtn ? (
    <ColouredButton
      type="button"
      className={
        "coloured-button--support-button" +
        (props.topRight
          ? " coloured-button--top-right"
          : " coloured-button--bottom-right")
          + ` ${props.className}`
      }
    >
      ?
      <div className="contact-support_notification">
        {/* <Link className="contact-support__tutorial-link" to="/tutorial">
          {Store.getText("howToLearn")}
        </Link> */}

        <p className="contact-support_notification-content">
          {Store.getText("contactText")}
        </p>
      </div>
    </ColouredButton>
  ) : (
    <TransparentButton
      type="button"
      className={
        "transparent-button--support-button" +
        (props.topRight
          ? " transparent-button--top-right"
          : " transparent-button--bottom-right")
      }
    >
      ?
      <div className="contact-support_notification">
        <p className="contact-support_notification-content">
          {Store.getText("contactText")}
        </p>
      </div>
    </TransparentButton>
  );
}

export default ContactSupport;
