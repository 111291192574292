import "./TransparentButton.css";

function TransparentButton(props) {
  return (
    <button {...props} className={"transparent-button " + props.className}>
      {props.returnBtn ? <i className="retun-icon fas fa-arrow-left"></i> : ""}{" "}
      {props.children}
    </button>
  );
}

export default TransparentButton;
