import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import HomePage from "./views/HomePage/HomePage.js";
import FlashCard from "./views/FlashCard/FlashCard.js";
import LoginChecker from "./LoginChecker.js";
import FlashMessageError from "./views/FlashMessageError/FlashMessageError.js";
import Store from "./Store.js";
import LanguageSelectionStepOne from "./views/LanguageSelection/LanguageSelectionStepOne";
import LanguageSelectionStepTwo from "./views/LanguageSelection/LanguageSelectionStepTwo";
import LevelSelection from "./views/LevelSelection/LevelSelection.js";
import MilestoneModal from "./components/MilestoneModal/MilestoneModal.js";
import RegisterAlert from "./components/RegisterAlert/RegisterAlert.js";
import FlashcardTable from "./views/FlashcardTable/FlashcardTable.js";
import TutorialPage from "./views/TutorialPage/TutorialPage.js";
import CertificatePage from "./views/CertificatePage/CertificatePage.js";
import LinkGamePage from "./views/LinkGamePage/LinkGamePage.js";
import GamesPage from "./views/GamesPage/GamesPage.js";
import ChooseActivities from "./views/ChooseActivities/ChooseActivities.js";
import ChooseLesson from "./views/ChooseLesson/ChooseLesson.js";
import FlashCardLesson from "./views/FlashCard/FlashCardLesson.js";
import ChoosePlatformLanguage from "./views/ChoosePlatformLanguage/ChoosePlatformLanguage.js";
import TestGamePage from "./views/TestGamePage/TestGamePage.js";
import TypeGamePage from "./views/TypeGamePage/TypeGamePage.js";
import GrammarPageCsEn from "./views/GrammarPage/GrammarPageCsEn.js";
import GrammarPageSkEn from "./views/GrammarPage/GrammarPageSkEn.js";
import UserTable from "./views/UserTable/UserTable.js";
import Panel from "./components/Panel/Panel.js";
import TestPage from "./views/TestPage/TestPage.js";
import FlashcardTableRate from "./views/FlashcardTable/FlashcardTableRate.js";

function App() {
  const [errors, setErrors] = useState([]);

  async function handleError(errors) {
    setErrors([]);
    const errCodesArray = errors.split(",");
    const errorMessagesArray = [];
    for (let i = 0; i < errCodesArray.length; i++) {
      errorMessagesArray.push(
        await Store.getExceptionMessage(errCodesArray[i])
      );
    }
    setErrors(errorMessagesArray);
  }

  useEffect(() => {
    document.title = Store.getText("pageTitle");
  }, []);
  return (
    <div className="App">
      <Router>
        <LoginChecker></LoginChecker>
        {errors.length > 0 && <FlashMessageError messages={errors} />}
        <Switch>
          <Route path="/after-register">
            <RegisterAlert />
          </Route>
          <Route exact path="/select-language">
            <Panel>
              <LanguageSelectionStepOne setError={handleError} />
            </Panel>
          </Route>
          <Route path="/select-language/:languageShortFrom">
            <Panel>
            <LanguageSelectionStepTwo setError={handleError} />
            </Panel>
          </Route>
          <Route path="/select-level/:pairShort">
            <Panel>
            <LevelSelection setError={handleError} />
            </Panel>
          </Route>
          <Route path="/certificate/:pairShort">
            <Panel>
            <CertificatePage setError={handleError} />
            </Panel>
          </Route>
          <Route path="/test/:pairShort">
            <Panel>
              <TestPage setError={handleError} />
            </Panel>
          </Route>

          <Route path="/games/link-game/:id">
            <Panel>
            <LinkGamePage setError={handleError} />
            </Panel>
          </Route>
          <Route path="/games/test-game/:id">
            <Panel>
            <TestGamePage setError={handleError} />
            </Panel>
          </Route>
          <Route path="/games/type-game/:id">
            <Panel>
            <TypeGamePage setError={handleError} />
            </Panel>
          </Route>
          <Route path="/games/:id" exact>
            <Panel>
            <GamesPage setError={handleError} />
            </Panel>
          </Route>
          <Route path="/choose-activity/:pairId">
            <Panel>
            <ChooseActivities setError={handleError} />
            </Panel>
          </Route>
          <Route path="/lessons/:pairId">
            <Panel>
            <ChooseLesson setError={handleError} />
            </Panel>
          </Route>
          <Route path="/learning/:pairId/lesson/:lesson">
            <Panel>
              <FlashCardLesson setError={handleError} />
            </Panel>
          </Route>
          

          <Route path="/learning/:id">
            <Panel>
            <FlashCard setError={handleError} />
            </Panel>
          </Route>


          <Route path="/admin/users">
            <UserTable></UserTable>
          </Route>
          <Route path="/admin/by-rate">
            <FlashcardTableRate></FlashcardTableRate>
          </Route>
          <Route path="/admin">
            <FlashcardTable></FlashcardTable>
          </Route>


          <Route path="/tutorial">
          <Panel>

            <TutorialPage></TutorialPage>
            </Panel>
          </Route>
          <Route path="/grammar/cs-en">
            <Panel>
            <GrammarPageCsEn setError={handleError} />
            </Panel>
          </Route>
          <Route path="/grammar/sk-en">
            <Panel>
            <GrammarPageSkEn setError={handleError} />
            </Panel>
          </Route>
          <Route path="/">
            <HomePage setError={handleError} />
          </Route>

        </Switch>
      </Router>
    </div>
  );
}

export default App;
