
import { useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import Store from "../../Store";

function ChoosePlatformLanguage() {

  const params = useParams();
  const history = useHistory()
  useEffect(async () => {
  
   Store.setUserLanguage(params.platformLangShort)
   history.push('/')
  })
 
 
  return (
    <></>
  );
}

export default ChoosePlatformLanguage;
