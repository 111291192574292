import { useState } from "react"
import Store from "../../Store"
import Notification from "../Notification/Notification"

function Panel(props) {

    const [isNotificationOpen, setIsNotificationOpen] = useState(false)
    return <>
  <Notification isModalActive={isNotificationOpen} setIsModalActive={setIsNotificationOpen}/>

     {props.children}
    </>
}

export default Panel