import "./LanguageSelection.css";
import PageStructure from "../../components/PageStructure/PageStructure";
import { useEffect, useState } from "react";
import api from "../../api/api";
import Store from "../../Store";
import qs from "qs";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import getLanguageFlag from "./getLanguageFlag";
import TransparentButton from "../../components/Buttons/TransparentButton/TransparentButton";
import ColouredReturnButton from "../../components/Buttons/ColouredReturnButton/ColouredReturnButton";
import AddKeyModal from "../../components/AddKeyModal/AddKeyModal";

function LanguageSelectionStepTwo(props) {
  const params = useParams();
  const location = useLocation();
  const onlyOneLanguageFrom = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  }).onlyOneLanguageFrom;
  const [languagesTo, setLanguagesTo] = useState([]);

  const [addKeyModalActive, setAddKeyModalActive] = useState(false);


  useEffect(async () => {
    const languagePairAll = await api.get(
      `/language-pair/language-to/${params.languageShortFrom}`
    );
    const languagePairAvailable = await api.get(
      `/language-pair/language-to/${params.languageShortFrom}/available`
    );
    const languageToList = [];

    Store.setUserLanguage(params.languageShortFrom);

    languagePairAvailable.data.forEach((pair) => {
      const languageShort = {
        short: pair.languageTo.languageShort,
        available: true,
      };

      if (
        !languageToList.find(
          (languageTo) => languageTo.short === languageShort.short
        )
      )
        languageToList.push(languageShort);
    });

    languagePairAll.data.forEach((pair) => {
      const languageShort = {
        short: pair.languageTo.languageShort,
        available: false,
      };

      if (
        !languageToList.find(
          (languageTo) => languageTo.short === languageShort.short
        )
      )
        languageToList.push(languageShort);
    });

    setLanguagesTo(languageToList);
  }, []);

  return (
    <PageStructure
      setError={props.setError}
      title={Store.getText("chooseLanguagePageTitle")}
      altBgMap
      supportIcon
      tutorialLink
    >
      <div className="language-selection-page-content">
        {parseInt(onlyOneLanguageFrom) !== 1 && (
          <Link to="/select-language">
            <ColouredReturnButton className="coloured-return-button--top-left">
              {Store.getText("back")}
            </ColouredReturnButton>
          </Link>
        )}
        <AddKeyModal isModalActive={addKeyModalActive} setIsModalActive={setAddKeyModalActive} setError={props.setError}></AddKeyModal>
        {languagesTo.map((short) => {
          return (
            <div
              className={`language-pair-link-wrapper ${
                short.available ? "" : "language-pair-link-wrapper--disabled"
              }`}
              onClick={() => {
                if(!short.available) setAddKeyModalActive(true)
              }}
            >
              <Link
                className={`language-pair-link ${
                  short.available ? "" : "language-pair-link--disabled"
                }`}
                to={`/select-level/${params.languageShortFrom}-${short.short}`}
              >
                <img
                  className={`language-flag ${
                    short.available ? "" : "language-flag--disabled"
                  }`}
                  src={getLanguageFlag(short.short)}
                />
                <p className="language-pair">
                  {Store.getLanguageNameByShort(short.short)}
                </p>

                <div className="language_notification">
                  <p className="language_notification-content">
                    {Store.getText("addLanguage")}
                  </p>
                </div>
              </Link>
            </div>
          );
        })}
      </div>
    </PageStructure>
  );
}

export default LanguageSelectionStepTwo;
