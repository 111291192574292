import { ProgressBar } from 'react-bootstrap';
import './ProgressBarDotted.css'
const PROGRESS_LIMIT = 7;

function ProgressBarDotted(props) {
   return ( <div className="progress-bar-wrapper">
    <span
      className={
        "progress-label" +
        (props.percent < PROGRESS_LIMIT
          ? " progress-label--translated-left"
          : "")
      }
    >
      {props.desc}
    </span>
    <ProgressBar now={props.percent}></ProgressBar>
  </div>)
}

export default ProgressBarDotted