import PageStructure from "../../components/PageStructure/PageStructure";
import "./TutorialPage.css";

import { useHistory } from "react-router";
import ColouredReturnButton from "../../components/Buttons/ColouredReturnButton/ColouredReturnButton";


import Store from "../../Store";
import getTutorialImage from "./getTutorialImage";
import ColouredButton from "../../components/Buttons/ColouredButton/ColouredButton";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import api from '../../api/api'
import getLanguageFlag from "../LanguageSelection/getLanguageFlag";

function TutorialPage(props) {
  const history = useHistory();


  const [languagesTo , setLanguagesTo] = useState([])


  useEffect(async () => {
    const languagePairFromAvailable = await api.get("/language-pair/available");
    const languageShortFrom = languagePairFromAvailable.data[0].languageFrom.languageShort
    const languagePairAll = await api.get(`/language-pair/language-to/${languageShortFrom}`);
    const languagePairAvailable = await api.get(`/language-pair/language-to/${languageShortFrom}/available`);
    const languageToList = []

    languagePairAvailable.data.forEach((pair) => {
      const languageShort = {
        short: pair.languageTo.languageShort,
        available: true
      }
      
      if (!languageToList.find(languageTo => languageTo.short === languageShort.short)) languageToList.push(languageShort);
    });


    languagePairAll.data.forEach((pair) => {
      const languageShort = {
        short: pair.languageTo.languageShort,
        available: false
      }
      
      if (!languageToList.find(languageTo => languageTo.short === languageShort.short)) languageToList.push(languageShort);
    });

    setLanguagesTo(languageToList);
    console.log(languageToList)
  }, []);

  return (
    <PageStructure setError={props.setError}
altBg>
      <ColouredReturnButton
        className="coloured-return-button--top-left"
        onClick={history.goBack}
      >
        {Store.getText("back")}
      </ColouredReturnButton>
      <div className="tutorial-page">
        <h1 className="tutorial-page__title"> {Store.getText("tutorialTitle")}</h1>
        <p className="tutorial-page__text">
        {Store.getText("tutorialFirstParagraph")}
        </p>
        <ol className="tutorial-page__ordered-list">
          <li className="tutorial-page__ordered-list-element">
          {Store.getText("tutorialFirstPointTitle")}
          <div className="choose-language-wrapper-tutorial">
          <h2 className="choose-language-header--tutorial">{Store.getText("chooseLanguagePageTitle")}</h2>
          <div className="language-selection-page-content">
            
          {languagesTo.map((short) => {
          return (
            <div className={`language-pair-link ${short.available ? '' : 'language-pair-link--disabled'} language-pair-link--tutorial`}>
              <img
                className={`language-flag ${short.available ? '' : 'language-flag--disabled'} language-flag--tutorial`}
                src={getLanguageFlag(short.short)}
                
              />
              <p className="language-pair language-pair--tutorial">
                {Store.getLanguageNameByShort(short.short)}
              </p>
            </div>
          );
        })}
        </div>
        </div>

            {/* <img className="tutorial-page__img" src={getTutorialImage('selectLanguage', Store.getUserLangShort())} /> */}
            <p className="tutorial-page__text">
            {Store.getText("tutorialFirstPointParagraph")}
            </p>
          </li>
          <li className="tutorial-page__ordered-list-element">
          {Store.getText("tutorialSecondPointTitle")}
            <img className="tutorial-page__img" src={getTutorialImage('selectLevel', Store.getUserLangShort())} style={{width: '400px'}}/>
            <p className="tutorial-page__text">
            {Store.getText("tutorialSecondPointParagraph")}
            </p>
            <p className="tutorial-page__text">
            {Store.getText("tutorialSecondPointParagraph2")}
            </p>
          </li>
          <li className="tutorial-page__ordered-list-element">
          {Store.getText("tutorialThirdPointTitle")}
            <img className="tutorial-page__img" src={getTutorialImage('activities', Store.getUserLangShort())} style={{width: '400px'}}/>
            <p className="tutorial-page__text">
            {Store.getText("tutorialThirdPointParagraph")}
            </p>
            <ol className="tutorial-page__ordered-list-nested">
              <li className="tutorial-page__ordered-list-nested-element">
                <div className="tutorial-page__ordered-list-nested-flex-wrapper">
                  <img className="tutorial-page__activity-img" src={getTutorialImage('lessons', Store.getUserLangShort())} />
                  <div>
                    a)  {Store.getText("tutorialLearningMethodsFirstPointTitle")}
                    <p className="tutorial-page__text">
                    {Store.getText("tutorialLearningMethodsFirstPointParagraph")}
                    </p>
                  </div>
                </div>
                <img className="tutorial-page__img" src={getTutorialImage('lessonList', Store.getUserLangShort())} style={{width: '800px'}}/>
                <p className="tutorial-page__text">
                {Store.getText("tutorialLearningMethodsDescriptionTitle")}
                </p>

                <ul className="tutorial-page__unordered-list">
                  <li className="tutorial-page__unordered-list-element">
                  {Store.getText("tutorialLearningMethodsDescriptionItem")}
                  </li>
                  <li className="tutorial-page__unordered-list-element">
                  {Store.getText("tutorialLearningMethodsDescriptionItem2")}
                  </li>
                  <li className="tutorial-page__unordered-list-element">
                  {Store.getText("tutorialLearningMethodsDescriptionItem3")}
                  </li>
                </ul>
              </li>
              <li className="tutorial-page__ordered-list-nested-element">
                <div className="tutorial-page__ordered-list-nested-flex-wrapper">
                  <img className="tutorial-page__activity-img" src={getTutorialImage('games', Store.getUserLangShort())} />
                  <div>
                    b)  {Store.getText("tutorialLearningMethodsSecondPointTitle")}
                    <p className="tutorial-page__text">
                    {Store.getText("tutorialLearningMethodsSecondPointParagraph")}
                    </p>
                  </div>
                </div>
                <img className="tutorial-page__img" src={getTutorialImage('linkGame', Store.getUserLangShort())} />
              </li>
              <li className="tutorial-page__ordered-list-nested-element">
                <div className="tutorial-page__ordered-list-nested-flex-wrapper">
                  <img className="tutorial-page__activity-img" src={getTutorialImage('repeat', Store.getUserLangShort())} />
                  <div>
                    c)  {Store.getText("tutorialLearningMethodsThirdPointTitle")}
                    <p className="tutorial-page__text">
                    {Store.getText("tutorialLearningMethodsThirdPointParagraph")}
                    </p>
                  </div>
                </div>
              </li>
            </ol>
            <p className="tutorial-page__text">
            {Store.getText("tutorialLearningMethodsThirdPointImgTitle")}
            </p>
            <img
              className="tutorial-page__img"
              src={window.innerWidth > 768 ? getTutorialImage('flashCardInfo', Store.getUserLangShort()) : getTutorialImage('flashCardInfoMob', Store.getUserLangShort())}
            />
            <p className="tutorial-page__text"> {Store.getText("tutorialLearningMethodsThirdPointImgDescriptionTitle")}</p>
            <ul className="tutorial-page__unordered-list">
              <li className="tutorial-page__unordered-list-element">
              {Store.getText("tutorialLearningMethodsThirdPointImgDescriptionItem")}
              </li>
              <li className="tutorial-page__unordered-list-element">
              {Store.getText("tutorialLearningMethodsThirdPointImgDescriptionItem2")}
              </li>
              <li className="tutorial-page__unordered-list-element">
              {Store.getText("tutorialLearningMethodsThirdPointImgDescriptionItem3")}
              </li>
              <li className="tutorial-page__unordered-list-element">
              {Store.getText("tutorialLearningMethodsThirdPointImgDescriptionItem4")}
              </li>
              <li className="tutorial-page__unordered-list-element">
              {Store.getText("tutorialLearningMethodsThirdPointImgDescriptionItem5")}
              </li>
              <li className="tutorial-page__unordered-list-element">
              {Store.getText("tutorialLearningMethodsThirdPointImgDescriptionItem6")}
              </li>
            </ul>
            <p className="tutorial-page__text"> {Store.getText("tutorialLearningMethodsThirdPointSecondImgTitle")}</p>
            <img className="tutorial-page__img" src={getTutorialImage('faces', Store.getUserLangShort())} style={{width: '400px'}}/>
            <p className="tutorial-page__text">
            {Store.getText("tutorialLearningMethodsThirdPointSecondImgDescription")}
            </p>
          </li>
          <li className="tutorial-page__ordered-list-element">
          {Store.getText("tutorialFourthPointTitle")}
          <div className="tutorial-page__flex-wrapper">

            <img className="tutorial-page__img" src={getTutorialImage('user', Store.getUserLangShort())} style={{width: '70px'}} />
            <p className="tutorial-page__text">
            {Store.getText("tutorialFourthPointParagraph")}
            </p>
            </div>

            {['cs', 'sk'].indexOf(Store.getUserLangShort()) > -1 && <div className="tutorial-page__flex-wrapper">
            <img className="tutorial-page__img" src={getTutorialImage('userExpanded', Store.getUserLangShort())} style={{width: '250px'}}/>
                <div>
                <p className="tutorial-page__text">
            {Store.getText("tutorialFourthPointParagraph2")}
            </p>
            <p className="tutorial-page__text">
            {Store.getText("tutorialFourthPointList")}
            </p>
            <p className="tutorial-page__text">
            {Store.getText("tutorialFourthPointList2")}
            </p>
            <p className="tutorial-page__text">
            {Store.getText("tutorialFourthPointList3")}
            </p>
                </div>
              </div>}

          </li>
         {['cs', 'sk'].indexOf(Store.getUserLangShort()) > -1 && <li className="tutorial-page__ordered-list-element">
          {Store.getText("tutorialFifthPointTitle")}
            <img className="tutorial-page__img" src={getTutorialImage('addLanguage', Store.getUserLangShort())} style={{width: '350px'}}/>
            <p className="tutorial-page__text">
            {Store.getText("tutorialFifthPointParagraph")}
            </p>
          </li>}
        </ol>
        <Link to="/select-language" className="tutorial-page__start-link">
         <ColouredButton className="orange-button tutorial-page__start-button">{Store.getText("startLearning")}</ColouredButton>
         </Link>
      </div>
    </PageStructure>
  );
}

export default TutorialPage;
