import PageStructure from "../../components/PageStructure/PageStructure";
import "./CertificatePage.css";
import api from '../../api/api'

import { useHistory, useParams } from "react-router";
import ColouredReturnButton from "../../components/Buttons/ColouredReturnButton/ColouredReturnButton";
import { useEffect, useState } from "react";

import l1 from "./l1.png";
import l2 from "./l2.png";
import l3 from "./l3.png";
import ProgressBarDotted from "../../components/ProgressBar/ProgressBarDotted";
import Store from "../../Store";
import { Link } from "react-router-dom";

function CertificatePage(props) {
  const [counts, setCounts] = useState([])
  const [canDoTest, setCanDoTest] = useState(false)
  const history = useHistory();
  const params = useParams();

  useEffect(async () => {
    const res = await api.get(`/flashcard/counts/${params.pairShort}`);
    setCounts(res.data)
    decideCanDoTest(res.data)

  }, [])

  const getLevelPairCount = (level) => {
    return counts.find((count) => count.level === level);
  };

  const getPercentForLevel = (level) => {
    const levelPairCount = getLevelPairCount(level)
    
    return levelPairCount ? Math.round(
      (levelPairCount.count /
        levelPairCount.allCount) *
        100
    ) : 0
  }

  const decideCanDoTest = (counts) => {
    let canDo = true;
    counts.forEach(count => {
      if(count.count < count.allCount) canDo = false;
    })
    if(Store.isAdmin()) canDo = true;
    setCanDoTest(counts.length > 0 ? canDo : false)
  }

  return (
    <PageStructure setError={props.setError}
altBg>
      <ColouredReturnButton
        className="coloured-return-button--top-left"
        onClick={history.goBack}
      >
        {Store.getText("back")}
      </ColouredReturnButton>
      <div className="cert-page">
        <h1 className="cert-page__title">{Store.getText("certificatePageTitle")}</h1>

        <ol className="cert-page__ordered-list">
          <li className="cert-page__ordered-list-element">
          {Store.getText("certificatePageFirstPoint")}
            <p className="cert-page__text">
            {Store.getText("certificatePageFirstPointParagraph")}

            </p>
            <div className="cert-page__cert-button-mock-wrapper">
              <button className="cert-page__cert-button cert-page__cert-button--disabled cert-page__cert-button--mock">
              {Store.getText("certificatePageButtonText")}
              </button>
              <p className="cert-page__cert-button-mock-text">{Store.getText("certificatePageButtonNotActiveDesc")}

</p>
            </div>
            <div className="cert-page__cert-button-mock-wrapper">
              <button className="cert-page__cert-button cert-page__cert-button--mock">
              {Store.getText("certificatePageButtonText")}
              </button>
              <p className="cert-page__cert-button-mock-text">{Store.getText("certificatePageButtonActiveDesc")}

</p>

            </div>
            <p>
              <strong>{Store.getText("certificatePageProgressTitle")}</strong>
              {getLevelPairCount(1) && <div className="cert-page__progress-wrapper">
                <div className="cert-page__level-image-wrapper">
                  <img src={l1} className="cert-page__level-image"/>
                  <p className="cert-page__level-desc">{Store.getText("levelOneText")}</p>
                </div>
                <ProgressBarDotted desc={getPercentForLevel(1) + '%'} percent={getPercentForLevel(1)} />
              </div>}

              {getLevelPairCount(2) && <div className="cert-page__progress-wrapper">
                <div className="cert-page__level-image-wrapper">
                  <img src={l2} className="cert-page__level-image"/>
                  <p className="cert-page__level-desc">{Store.getText("levelTwoText")}</p>
                </div>
                <ProgressBarDotted desc={getPercentForLevel(2) + '%'} percent={getPercentForLevel(2)} />
              </div>}

              {getLevelPairCount(3) && <div className="cert-page__progress-wrapper">
                <div className="cert-page__level-image-wrapper">
                  <img src={l3} className="cert-page__level-image"/>
                  <p className="cert-page__level-desc">{Store.getText("levelThreeText")}</p>
                </div>
                <ProgressBarDotted desc={getPercentForLevel(3) + '%'} percent={getPercentForLevel(3)} />
              </div>}
            </p>
          </li>
          <li className="cert-page__ordered-list-element">
          {Store.getText("certificatePageSecondPoint")}
            <p className="cert-page__text">
          {Store.getText("certificatePageSecondPointParagraph")}
            
            </p>
            <p className="cert-page__text">          {Store.getText("certificatePageGoodLuck")}
</p>
          </li>
        </ol>
        <Link to={`/test/${params.pairShort}`}>
        <button className={`cert-page__cert-button ${!canDoTest && 'cert-page__cert-button--disabled'}`}>
        {Store.getText("certificatePageButtonText")}
              </button>
              </Link>
      </div>
    </PageStructure>
  );
}

export default CertificatePage;
