import de from "./language-flags/de.png";
import en from "./language-flags/en.png";
import ru from "./language-flags/ru.png";
import es from "./language-flags/es.png";
import fr from "./language-flags/fr.png";
import it from "./language-flags/it.png";
import nl from "./language-flags/nl.png";
import pl from "./language-flags/pl.png";
import sk from "./language-flags/sk.png";
import pt from "./language-flags/pt.png";
import cs from "./language-flags/cs.png";

const languageFlags = {
  de,
  en,
  ru,
  es,
  fr,
  it,
  nl,
  pl,
  sk,
  pt,
  cs
};

const getLanguageFlag = (short) => {
  return languageFlags[short] ? languageFlags[short] : languageFlags.en;
};

export default getLanguageFlag;
