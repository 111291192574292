import { useEffect, useState } from "react";
import FlashMessage from "react-flash-message";
import "./FlashMessageError.css";

const FLASHCARD_DURATION = 5000;
const ANIMATION_DURATION = 500;

const FlashMessageError = (props) => {
  const [transition, setTransition] = useState(false);

  useEffect(() => {
    const timeoutIn = setTimeout(() => {
      setTransition(true);
    }, 100);

    const timeoutOut = setTimeout(() => {
      setTransition(false);
    }, FLASHCARD_DURATION - ANIMATION_DURATION);

    return function cleanup() {
      clearTimeout(timeoutIn);
      clearTimeout(timeoutOut);
    };
  }, props.messages);
  return (
    <FlashMessage duration={FLASHCARD_DURATION}>
      <div
        className={
          "flash-message" + (transition ? " flash-message--transition" : "")
        }
      >
        {props.messages.map((message, i) => (
          <p className="flash-message__single-error" key={i}>
            {message}
          </p>
        ))}
      </div>
    </FlashMessage>
  );
};

export default FlashMessageError;
