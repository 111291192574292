import "./LevelSelection.css";
import PageStructure from "../../components/PageStructure/PageStructure";
import ColouredButton from "../../components/Buttons/ColouredButton/ColouredButton";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import api from "../../api/api";
import Navbar from "../../components/Navbar/Navbar";
import Store from "../../Store";

import l1 from "./l1.png";
import l2 from "./l2.png";
import l3 from "./l3.png";
import ColouredReturnButton from "../../components/Buttons/ColouredReturnButton/ColouredReturnButton";

function LevelSelection(props) {
  const [languagePairs, setLanguagePairs] = useState([]);
  const [languagePairsProgress, setLanguagePairsProgress] = useState([]);

  const params = useParams();

  useEffect(async () => {
    const languagePair = await api.get(
      `/language-pair/pair-short/${params.pairShort}`
    );

    console.log(languagePair)

    const languagePairsProgress = await api.get(
      `/flashcard/counts/${params.pairShort}`
    );



    setLanguagePairsProgress(languagePairsProgress.data)

    setLanguagePairs(languagePair.data);
  }, []);

  const isUserHaveLevel = (level) => {
    return languagePairs.find((pair) => pair.level === level);
  };

  const getIdByLevel = (level) => {
    const languagePair = languagePairs.find((pair) => pair.level === level);
    return languagePair.id;
  };

  const getProgressByLevel = (level) => {
    const pairProgress = languagePairsProgress.find((pair) => pair.level === level);
    if(!pairProgress) return null
    return Math.floor((pairProgress.count / pairProgress.allCount) * 100);
  };

  return (
    <PageStructure setError={props.setError}
title={Store.getText("chooseLevelPageTitle")} altBgMap supportIcon tutorialLink pairShort={params.pairShort}>
      <div className="level-selection-page-content">
        <Link to="/select-language">
          <ColouredReturnButton className="coloured-return-button--top-left">
            {Store.getText("back")}
          </ColouredReturnButton>
        </Link>
        <div className="levels-wrapper">
          {isUserHaveLevel(1) && (
            <Link className="level-link" to={`/choose-activity/${getIdByLevel(1)}`}>
              <div className="level">
                <img src={l1} className="level-img" />
                <p className="level-desc">{Store.getText("levelOneText")}</p>
                <p className="level-progress-title">{Store.getText("yourProgress")}</p>

<p className="level-progress">{getProgressByLevel(1)}%</p>
              </div>
            </Link>
          )}
          {isUserHaveLevel(2) && (
            <Link className="level-link" to={`/choose-activity/${getIdByLevel(2)}`}>
              <div className="level">
                <img src={l2} className="level-img" />

                <p className="level-desc">{Store.getText("levelTwoText")}</p>
                <p className="level-progress-title">{Store.getText("yourProgress")}</p>

                <p className="level-progress">{getProgressByLevel(2)}%</p>
              </div>
            </Link>
          )}
          {isUserHaveLevel(3) && (
            <Link className="level-link" to={`/choose-activity/${getIdByLevel(3)}`}>
              <div className="level">
                <img src={l3} className="level-img" />

                <p className="level-desc">{Store.getText("levelThreeText")}</p>
                <p className="level-progress-title">{Store.getText("yourProgress")}</p>

<p className="level-progress">{getProgressByLevel(3)}%</p>
              </div>
            </Link>
          )}
        </div>
      </div>
    </PageStructure>
  );
}

export default LevelSelection;
