import "./ColouredButton.css";

function ColouredButton(props) {
  return (
    <button {...props} className={"coloured-button " + props.className}>
      {props.children}
    </button>
  );
}

export default ColouredButton;
