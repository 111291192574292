import { useState } from "react";

import api from "../../api/api";
import Store from "../../Store";

import "./TranslateBox.css";

export default function TranslateBox(props) {
  const [word, setWord] = useState("");
  const [foundFlashcard, setFoundFlashcard] = useState();
  const [isSentenceExpanded, setIsSentenceExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [isReversed, setIsReversed] = useState(false)

  const findFlashcard = async (e) => {
    e.preventDefault();
    setIsLoading(true)
    try {
      const res = await api.get(
        `/flashcard/language-pair/${props.pairShort}/translate/${word}`
      );
      setIsReversed(res.data.contentFrom.expression.toLowerCase() === word.toLowerCase())
      setFoundFlashcard(res.data);
    } catch (e) {
      props.setError(e.message);
    }
    setIsLoading(false)
  };

  return (
    <div className={"translate" + (props.isTranslateActive ? " translate--active" : "")}>
      <div className="translate__box">
        <button
          onClick={() => props.setIsTranslateActive(false)}
          className="translate__closing-button"
          
        >
          <i className="translate__closing-icon fas fa-times"></i>
        </button>
        <form onSubmit={(ev) => findFlashcard(ev)}>
       

          <div className="translate-box-content">
            <p className="translate-box-content__header">
             {Store.getText('translateBoxHeading')}
            </p>
           
              
              <input
                className="form-box__input form-box__input--margin"
                type="text"
                required={true}
                onChange={(ev) => setWord(ev.target.value)}
                value={word}
              ></input>

            <button className="translate-box-content__button" type="submit" disabled={isLoading}>
            {isLoading ?  <div class="simple-loader"></div> : Store.getText('translateBoxButtonText')}
            </button>

            {foundFlashcard && <> 
                <p className="translate-box-content__gray-header">
                {Store.getText('translateBoxExpressionText')}
            </p>
            <p className="translate-box-content__text">
              {foundFlashcard[isReversed ? 'contentTo' : 'contentFrom'].expression}
            </p>
            <p className="translate-box-content__gray-header" onClick={() => setIsSentenceExpanded(!isSentenceExpanded)}>
            {Store.getText('translateBoxSentenceText')}  <i class={`fas fa-angle-down translate-box-content__icon-expand ${isSentenceExpanded ? "translate-box-content__icon-expand--transfom-up"  : ""}`}></i>
            </p>
            {isSentenceExpanded && <> 
                <p className="translate-box-content__text">
                {foundFlashcard[isReversed ? 'contentFrom' : 'contentTo'].sentence}

            </p>
            <p className="translate-box-content__text">
            {foundFlashcard[isReversed ? 'contentTo' : 'contentFrom'].sentence}

            </p>
              
            
            </>}

              
              </>}
           
          </div>
        </form>
      </div>
    </div>
  );
}
