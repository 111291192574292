import axios from "axios";
import Store from "../Store";

require("dotenv").config();

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

/**
 * Catch the AunAuthorized Request
 */
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      Store.removeToken();
      window.location = "/";
    }
    else if (error.response.status === 400) {
      throw new Error(error.response.data.message);
    }
    else if (error.response.status === 403) {
      Store.removeToken();
      window.location = "/";
    }
  }
);

export default instance;
