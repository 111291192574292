import PageStructure from "../../components/PageStructure/PageStructure";
import "./TestGamePage.css";
import api from "../../api/api";

import { useHistory, useParams } from "react-router";
import ColouredReturnButton from "../../components/Buttons/ColouredReturnButton/ColouredReturnButton";
import { useEffect, useState } from "react";
import LineTo, { SteppedLineTo } from "react-lineto";
import Store from "../../Store";




function TestGamePage(props) {
  const [flashcards, setFlashcards] = useState([]);

  const [expressionFrom, setExpressionFrom] = useState(null);

  const [expressionsTo, setExpressionsTo] = useState([]);
  const [activeExpression, setActiveExpression] = useState({});
  const [pairShort, setPairShort] = useState()

  const history = useHistory();
  const params = useParams();

  const clickExpression = (expressionTo) => {
    setActiveExpression(expressionTo)

    if( expressionTo.isCorrect) {
      setTimeout(async () => {
        await loadNew()
      }, 1500)
    }
  }

  const isExpressionActive = (expressionTo) => {
    return expressionTo.index === activeExpression.index
  }

  const getExpressionAddionalClassName = (expression) => {
    if(isExpressionActive(expression)) {
      if(expression.isCorrect) return "test-game-page__expression--correct"
      return "test-game-page__expression--not-correct"
    }
    return ""
  }



  const loadNew = async () => {
    setActiveExpression({})
    const res = await api.get(
      `/flashcard/link-game/language-pair/${params.id}`
    );
    setFlashcards(res.data)

    const pairShortRes = await api.get(
      `/language-pair/${params.id}/pair-short`
    );
    setPairShort(pairShortRes.data)

    const exprnsTo = []
    const resFlashcards = res.data.slice(0, 4).sort(() => (Math.random() > .5) ? 1 : -1)
    resFlashcards.forEach((flashcard, i) => {
      if(i === 0) setExpressionFrom(flashcard.contentFrom.expression)
      exprnsTo.push( {
        index: i,
        expression: flashcard.contentTo.expression,
        isCorrect: resFlashcards[0].contentTo.expression === flashcard.contentTo.expression
      })
    });


    setExpressionsTo(exprnsTo.sort(() => (Math.random() > .5) ? 1 : -1));
  }

  useEffect(async () => {
    await loadNew()
  }, []);

  return (
    <PageStructure setError={props.setError}
altBgMap tutorialLink pairShort={pairShort}>
      <ColouredReturnButton
        className="coloured-return-button--top-left"
        onClick={history.goBack}
      >
        {Store.getText("back")}
      </ColouredReturnButton>
      <div className="test-game-page">
      <h1 className="test-game-page__main-heading">{Store.getText('testGameName')}</h1>
      {flashcards.length > 0 && (
            <div  className={`test-game-page__expression test-game-page__expression--from`} >

                
                  {expressionFrom}
               
  
            </div>
          )}

        <div className="test-game-page__expressions-wrapper">
          {flashcards.length > 0 && (
            <ul className="test-game-page__expression-list">
              {expressionsTo.map((expression, i) => (
                <li
                  className={`test-game-page__expression test-game-page__expression--to ${getExpressionAddionalClassName(expression)} expression-to-${i}`}
                  onClick={() => clickExpression(expression)}
                  key={i}
                >
                  {expression.expression}
                </li>
              ))}
            </ul>
          )}



        </div>

      </div>
    </PageStructure>
  );
}

export default TestGamePage;
