import PageStructure from "../../components/PageStructure/PageStructure";
import "./TypeGamePage.css";
import api from "../../api/api";

import { useHistory, useParams } from "react-router";
import ColouredReturnButton from "../../components/Buttons/ColouredReturnButton/ColouredReturnButton";
import { useEffect, useState } from "react";
import LineTo, { SteppedLineTo } from "react-lineto";
import Store from "../../Store";
import getOutlinedLanguageFlag from "./getOutlinedLanguageFlag";

function TypeGamePage(props) {
  const [flashcard, setFlashcard] = useState();
  const [pairShort, setPairShort] = useState();

  const [inputValue, setInputValue] = useState("")
  const [correctnessOfAnswer, setCorrectnessOfAnswer] = useState()
  const [message, setMessage] = useState("")

  function hammingDistance (str1 = '', str2 = '') {
    if (str1.length !== str2.length) {
       return 9999;
    }
    let dist = 0;
    for (let i = 0; i < str1.length; i += 1) {
       if (str1[i] !== str2[i]) {
          dist += 1;
       };
    };
    return dist;
 };

  function onAnswerSubmit() {
    const properAnswerLowercase = flashcard.contentFrom.expression.toLowerCase().trim()
    const inputValueLowercase = inputValue.toLowerCase().trim()
    if(inputValueLowercase === properAnswerLowercase) return setCorrectnessOfAnswer(3);
    const withoutDiacriticsProperAnswer = properAnswerLowercase.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    const withoutDiacriticsInputValue = inputValueLowercase.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    const hammingDist = hammingDistance(withoutDiacriticsInputValue, withoutDiacriticsProperAnswer)
    if(hammingDist <= 1) return setCorrectnessOfAnswer(2);
    setCorrectnessOfAnswer(1);
  }

  const history = useHistory();
  const params = useParams();

  const loadNew = async () => {
    setCorrectnessOfAnswer()
    setInputValue("")
    setMessage()
    const res = await api.get(
      `/flashcard/type-game/language-pair/${params.id}`
    );
    setFlashcard(res.data);

    console.log(res.data);

    const pairShortRes = await api.get(
      `/language-pair/${params.id}/pair-short`
    );
    setPairShort(pairShortRes.data);
  };

  useEffect(async () => {
    await loadNew();
  }, []);


  useEffect(() => {
    switch(correctnessOfAnswer) {
      case 1: return  setMessage(`${Store.getText('typeGameMessage1')} ${flashcard.contentFrom.expression}`)
      case 2: return setMessage(`${Store.getText('typeGameMessage2')} ${flashcard.contentFrom.expression}`)
      case 3: return setMessage(`${Store.getText('typeGameMessage3')}`)
      default: setMessage()
    }
  }, [correctnessOfAnswer]);

  return (
    <PageStructure
      setError={props.setError}
      altBgMap
      tutorialLink
      pairShort={pairShort}
    >
      
      <ColouredReturnButton
        className="coloured-return-button--top-left"
        onClick={history.goBack}
      >
        {Store.getText("back")}
      </ColouredReturnButton>
      <div className="type-game-page">
        <h1 className="type-game-page__main-heading">
          {Store.getText("typeGameName")}
        </h1>
        {flashcard && (
          <div className="type-game-page__flashcard-wrapper">
            <div className="type-game-page__flashcard-img-wrapper">
            <img src={flashcard.link.image.base64} className="type-game-page__flashcard-img"></img>
            </div>
            <div className="type-game-page__expression-wrapper">
              <img
                src={getOutlinedLanguageFlag(
                  flashcard.languagePair.languageTo.languageShort
                )}
                className="type-game-page__flag-img"
              ></img>{" "}
              <span className="type-game-page--expression-text">{flashcard.contentTo.expression}</span>
            </div>

            <div className="type-game-page__expression-wrapper">
              <img
                src={getOutlinedLanguageFlag(
                  flashcard.languagePair.languageFrom.languageShort
                )}
                className="type-game-page__flag-img"
              ></img>{" "}
              <input className="type-game-page--expression-input" placeholder={Store.getText('typeExpression')} value={inputValue} onChange={(e) => setInputValue(e.target.value)}></input>
            </div>
          </div>
        )}
        <ColouredReturnButton className="coloured-return-button--hide-icon" onClick={() => correctnessOfAnswer ? loadNew() : onAnswerSubmit()}>{correctnessOfAnswer ? Store.getText("next") : Store.getText("check")}</ColouredReturnButton>
      </div>
      {message && <div key={message} className={`type-game-page__submit-message type-game-page__submit-message--${correctnessOfAnswer}`}>{message}</div>}
    </PageStructure>
  );
}

export default TypeGamePage;
