import "./FlashCard.css";
import FlashContent from "../../components/FlashContent/FlashContent";
import ReactCardFlip from "react-card-flip";
import ColouredReturnButton from "../../components/Buttons/ColouredReturnButton/ColouredReturnButton.js";
import { useState, useEffect } from "react";
import api from "../../api/api";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar";
import Store from "../../Store";
import MilestoneModal from "../../components/MilestoneModal/MilestoneModal";
import ContactSupport from "../../components/ContactSupport/ContactSupport";
import ColouredButton from "../../components/Buttons/ColouredButton/ColouredButton";
import ProgressBarDotted from "../../components/ProgressBar/ProgressBarDotted";

import dontKnow from './dontKnow.png'
import notSure from './notSure.png'
import know from './know.png'

function FlashCardLesson(props) {
  const [isFlipped, setIsFlipped] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [flashCard, setFlashCard] = useState();
  const [counts, setCounts] = useState(null)
  const [isModalActive, setIsModalActive] = useState(false)
  const [lastAnsweredKnow, setLastAnsweredKnow] = useState(null)
  const [rate, setRate] = useState(0)

  const params = useParams();

  const loadRate = async (id) => {
    const res = await api.get(`/flashcard-rate/flashcard/${id}`);
    setRate(res.data ? res.data.rate : 0)
  }

  const pickupFlashcard = async () => {
    setIsLoaded(false);
    const flashCardRes = await api.get(
      `/flashcard/pickup/language-pair/${params.pairId}/lesson/${params.lesson}`
    );
    setIsFlipped(false);
    setFlashCard(flashCardRes.data.flashcard);
    setCounts(flashCardRes.data.counts)
    await loadRate(flashCardRes.data.flashcard.id)

    setIsModalActive(
      lastAnsweredKnow && 
      (lastAnsweredKnow + 1 === flashCardRes.data.counts.answeredKnow) && 
      (flashCardRes.data.counts.answeredKnow >= flashCardRes.data.counts.flashcards)
    )
    setLastAnsweredKnow(flashCardRes.data.counts.answeredKnow)

    setTimeout(() => {
      setIsLoaded(true);
    }, 500);
  };

  useEffect(async () => {
    await pickupFlashcard();
  }, params.lesson);

  const makeAnswer = async (answer) => {
    const res = await api.post(`/flashcard/${flashCard.id}/answer`, { answer });
    await pickupFlashcard();
  };

  const makeRate = async (rate) => {
    const res = await api.post(`/flashcard-rate`, {  flashcardId: flashCard.id, rate });
    setRate(rate)
  };


  return (
    <div className="flash-card-container">
    
      <Navbar setError={props.setError} gamesLangPairId={params.pairId} pairShort={flashCard && `${flashCard.languagePair.languageFrom.languageShort}-${flashCard.languagePair.languageTo.languageShort}`}></Navbar>
     
      {flashCard && (
        <>
          <Link
            to={`/lessons/${params.pairId}`}
          >
            <ColouredReturnButton className="coloured-return-button--top-left">
            {Store.getText('back')}


            </ColouredReturnButton>
          </Link>


      <MilestoneModal isModalActive={isModalActive} languagePairId={params.pairId} lesson={params.lesson} setIsModalActive={setIsModalActive}></MilestoneModal>
          <div className="flash-card-grid-wrapper flash-card-grid-wrapper--with-progressbar">
            <div className="flash-card-flex-wrapper">
            <div>
                  {counts && <ProgressBarDotted percent={(counts.answeredKnow/counts.flashcards) * 100} desc={`${counts.answeredKnow}/${counts.flashcards}`}/>}
              </div>
              
              <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">

                <FlashContent
                  isFlipped={isFlipped}
                  isLoaded={isLoaded}
                  flipPage={() => setIsFlipped(!isFlipped)}
                  picture={flashCard.link.image}
                  word={flashCard.contentFrom.expression}
                  phrase={flashCard.contentFrom.sentence}
                />
                <FlashContent
                  isFlipped={isFlipped}
                  isLoaded={isLoaded}
                  flipPage={() => setIsFlipped(!isFlipped)}
                  picture={flashCard.link.image}
                  word={flashCard.contentTo.expression}
                  phrase={flashCard.contentTo.sentence}
                  wordSound={flashCard.contentTo.expressionSound}
                  phraseSound={flashCard.contentTo.sentenceSound}
                />
              </ReactCardFlip>
              <div className="flash-card-rate__wrapper">
                <p className="flash-card-rate__text">{Store.getText('makeRateText')}</p>
                <svg className={`flash-card-rate__rate ${rate === 1 ? "flash-card-rate__rate--up-active" : ""}`} version="1.1" id="UP" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 39.8 36.3" onClick={() => makeRate(1)}>

<path class="st0" d="M14.5,36.3h16.3c1.4,0,2.8-1,3.4-2.2l5.4-12.8c0.1-0.4,0.2-0.8,0.2-1.3v-3.6c0-2-1.6-3.6-3.6-3.6H24.7l1.7-8.3
	V3.9c0-0.7-0.4-1.4-0.8-1.9L23.6,0L11.7,11.9c-0.6,0.6-1.1,1.6-1.1,2.5v18.1C10.9,34.7,12.4,36.3,14.5,36.3z M14.5,14.6l7.8-7.8
	l-2.4,9.6h16.3V20l-5.4,12.7H14.5V14.6z M7.2,36.3H0V14.6h7.2V36.3z"/>
</svg>
                <svg className={`flash-card-rate__rate ${rate === -1 ? "flash-card-rate__rate--down-active" : ""}`} version="1.1" id="DOWN" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
	 viewBox="0 0 39.8 36.3" onClick={() => makeRate(-1)}>

<path class="st0" d="M25.3,0H9C7.6,0,6.3,1,5.7,2.2L0.2,14.9C0.1,15.3,0,15.8,0,16.3v3.6c0,2,1.6,3.6,3.6,3.6h11.5l-1.7,8.3v0.6
	c0,0.7,0.4,1.4,0.8,1.9l1.9,1.9L28,24.3c0.6-0.6,1.1-1.6,1.1-2.5V3.7C28.9,1.6,27.4,0,25.3,0z M25.3,21.7l-7.8,7.8l2.4-9.6H3.6v-3.6
	L9,3.6h16.3V21.7z M32.6,0h7.2v21.7h-7.2V0z"/>
</svg>
              </div>
            </div>
            <div className="points-wrapper points-wrapper--with-progressbar">
              <div className="point">
                <button
                  className="point-number know"
                  onClick={() => makeAnswer("know")}
                  disabled={!isLoaded}
                >
                  <img src={know} className="point-number-image"></img>
                </button>
                <p className="point-desc">{Store.getText("knowAnswerText")}</p>
              </div>
              <div className="point">
                <button
                  className="point-number not-sure"
                  onClick={() => makeAnswer("not_sure")}
                  disabled={!isLoaded}
                >
                                   <img src={notSure} className="point-number-image"></img>

                </button>
                <p className="point-desc">
                  {Store.getText("notSureAnswerText")}
                </p>
              </div>
              <div className="point">
                <button
                  className="point-number dont-know"
                  onClick={() => makeAnswer("dont_know")}
                  disabled={!isLoaded}
                >
                                    <img src={dontKnow} className="point-number-image"></img>

                </button>
                <p className="point-desc">
                  {Store.getText("dontKnowAnswerText")}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default FlashCardLesson;
