import moment from "moment";
import { useEffect, useState } from "react";
import { Chart as ChartJS } from "chart.js/auto";

import { Line } from "react-chartjs-2";
import api from "../../api/api";
import "./ProgressModal.css";
import Store from "../../Store";

function ProgressModal(props) {
  const [progressData, setProgressData] = useState({});
  const [chartData, setChartData] = useState();

  useEffect(async () => {
    if (props.isModalActive) {
      const res = await api.get(`/flashcard/last-flashcard-completed`);
      const flashcardsCompleted = res.data;
      const dayCompletedObj = {};

      const weekAgo = moment().startOf("day").subtract(6, "day");
      const now = moment();
      while (weekAgo.isBefore(now)) {
        const dayString = weekAgo.format("YYYY-MM-DD");
        dayCompletedObj[dayString] = 0;
        weekAgo.add(1, "day");
      }

      flashcardsCompleted.forEach((completed) => {
        const dayString = moment(completed.timestamps.createdAt).format(
          "YYYY-MM-DD"
        );
        dayCompletedObj[dayString]++;
      });

      let daysInRow = 0;
      const dayArr = Object.keys(dayCompletedObj).sort().reverse();
      const todayFlashcards = dayCompletedObj[dayArr[0]];

      for (let i = 0; dayCompletedObj[dayArr[i]] || i < 2; i++) {
        if(dayCompletedObj[dayArr[i]]) daysInRow++;
      }

      const dataObj = {
        daysInRow,
        todayFlashcards,
        dayCompletedObj,
      };
      setProgressData(dataObj);
      computeChartsData(dayCompletedObj);
    }
  }, [props.isModalActive]);

  const computeChartsData = (dataObj) => {
    const dayNames = [Store.getText("progressModalMondayShort"), Store.getText("progressModalTuesdayShort"), Store.getText("progressModalWednesdayShort"), Store.getText("progressModalThursdayShort"), Store.getText("progressModalFridayShort"), Store.getText("progressModalSaturdayShort"), Store.getText("progressModalSundayShort")];
    const labels = [];
    const data = [];
    Object.keys(dataObj).forEach((day) => {
      labels.push(dayNames[moment(day).isoWeekday() - 1]);
      data.push(dataObj[day]);
    });

    setChartData({
      labels,
      datasets: [
        {
          label: Store.getText("progressModalChartTitle"),
          backgroundColor: "rgba(255, 134, 74, 0.5)",
          borderColor: "rgb(255, 134, 74)",
          fill: true,
          data,
          pointRadius: [3, 3, 3, 3, 3, 3, 3],
          borderWidth: [1, 1, 1, 1, 1, 1, 4],
        },
      ],
    });
  };

  return (
    <>
    <div
      className={
        "progress-modal" +
        (props.isModalActive ? " progress-modal--active" : "")
      }
      onClick={() => props.setIsModalActive(false)}
    >
    </div>

      <div className={"progress-modal__box" + (props.isModalActive ? " progress-modal__box--active" : "")}>
      <div className="progress-modal__bookmark" onClick={() => props.setIsModalActive(!props.isModalActive)}>{Store.getText("progressModalBookmarkText")}</div>
        <button
          onClick={() => props.setIsModalActive(false)}
          className="progress-modal__closing-button"
        >
          <i className="progress-modal__closing-icon fas fa-times"></i>
        </button>
        <p className="progress-modal__title">{Store.getText("progressModalTitle")}</p>
        <div className="progress-modal__content-wrapper">
          <div className="progress-modal__text-wrapper">
            <div className="progress-modal__number-wrapper">
              <span className="progress-modal__number">{progressData.todayFlashcards}</span>
              <p className="progress-modal__description progress-modal__description--bold">{Store.getText("progressModalNumberHeader")}</p>
              <p className="progress-modal__description">{Store.getText("progressModalNumberText")}</p>
            </div>
            <div className="progress-modal__number-wrapper">
              <span className="progress-modal__number">{progressData.daysInRow}</span>
              <p className="progress-modal__description progress-modal__description--bold">{Store.getText("progressModalNumberHeader2")}</p>
              <p className="progress-modal__description">{Store.getText("progressModalNumberText2")}</p>
            </div>
          </div>
          <div className="progress-modal__line-wrapper">
            {chartData && (
              <Line
                data={chartData}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  scales: {
                    y: {
                      beginAtZero: true,
                      ticks: {
                        stepSize: 1,
                      },
                    },
                    x: {
                      grid: {
                        display: false,
                      },
                    },
                  },
                }}
              ></Line>
            )}
            <p className="progress-modal__line-description">{Store.getText("progressModalChartText")}</p>
          </div>
        </div>
        <p className="progress-modal__footer">{Store.getText("progressModalFooter")}</p>

      </div>
    </>
  );
}

export default ProgressModal;
