import "./RegisterAlert.css";
import congratsIcon from "./ikona_grat.png";
import ColouredButton from "../Buttons/ColouredButton/ColouredButton";
import PageStructure from "../PageStructure/PageStructure";
import { Link } from "react-router-dom";
import Store from "../../Store";
function RegisterAlert(props) {
  return (
    <PageStructure setError={props.setError}
userIconInvisible={true} altBgNav altLogo>
      <div className="register-alert-box">
        <h1 className="register-alert-box__title">
          {Store.getText("congratsText")}
        </h1>
        <p className="register-alert-box__text-content">
          {Store.getText("registerAlertTextFirst")}
        </p>
        <p className="register-alert-box__text-content">
          {Store.getText("registerAlertTextSecond")}
        </p>
        <img className="register-alert-box__icon" src={congratsIcon} />
        <Link to="/tutorial">
          <ColouredButton className="orange-button round">
            {Store.getText("registerAlertButtonText")}
          </ColouredButton>
        </Link>
      </div>
    </PageStructure>
  );
}

export default RegisterAlert;
