import { Link } from "react-router-dom";
import Store from "../../Store";
import ColouredButton from "../Buttons/ColouredButton/ColouredButton";
import "./HomePageBox.css";

function HomePageBox() {
  return (
    <div className="homepage-box">
      <p className="homepage-box__slogan">
        {Store.getText("homepageBoxSlogan")}
      </p>
      <div className="homepage-box__select-wrapper">
        <div className="homepage-box__select-div homepage-box__select-div--register">
          <p className="homepage-box__select-slogan homepage-box__select-slogan--register">
            {Store.getText("homepageBoxRegisterSloganFirst")}{" "}
            {Store.getText("homepageBoxRegisterSloganSecond")}
          </p>
          <Link to="/register">
            <ColouredButton className="orange-button up">
              {Store.getText("registerButtonFormText")}
            </ColouredButton>
          </Link>
        </div>
        <div className="homepage-box__select-div homepage-box__select-div--login">
          <p className="homepage-box__select-slogan homepage-box__select-slogan--login">
            {Store.getText("homepageBoxLoginSloganFirst")}{" "}
            {Store.getText("homepageBoxLoginSloganSecond")}
          </p>
          <Link to="/login">
            {" "}
            <ColouredButton className="blue-button up">
              {Store.getText("loginButtonFormText")}
            </ColouredButton>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default HomePageBox;
