import PageStructure from "../../components/PageStructure/PageStructure";
import "./LinkGamePage.css";
import api from "../../api/api";

import { useHistory, useParams } from "react-router";
import ColouredReturnButton from "../../components/Buttons/ColouredReturnButton/ColouredReturnButton";
import { useEffect, useState } from "react";
import LineTo, { SteppedLineTo } from "react-lineto";

import cross from './cross.png'
import tick from './tick.png'
import Store from "../../Store";


const NUMBER_OF_EXPRESSIONS = 5

function LinkGamePage(props) {
  const [flashcards, setFlashcards] = useState([]);
  const [expressionsFrom, setExpressionsFrom] = useState([]);
  const [expressionsTo, setExpressionsTo] = useState([]);
  const [activeExpression, setActiveExpression] = useState(null);
  const [links, setLinks] = useState([])
  const [pairShort, setPairShort] = useState()

  const history = useHistory();
  const params = useParams();

  const clickExpression = (expressionClassName) => {
    if(activeExpression) {
      if(expressionClassName.split('-')[1] === activeExpression.split('-')[1]) return setActiveExpression(expressionClassName)

      const link = {
        from: activeExpression.indexOf('from') > -1 ? activeExpression : expressionClassName,
        to: activeExpression.indexOf('from') > -1 ? expressionClassName: activeExpression,
      }

      const newLinks = links.filter(link => {
        const cannotContainArr = [expressionClassName, activeExpression]
        return cannotContainArr.indexOf(link.to) === -1 && cannotContainArr.indexOf(link.from) === -1
      })

      newLinks.push(link)
      setLinks(newLinks)
      setActiveExpression(null)
      if(isAllCorrect(newLinks)) {
        setTimeout(loadNew, 700)
      }
    } else {
      setActiveExpression(expressionClassName)
    }
  }

  const isExpressionActive = (expression) => {
    return expression === activeExpression
  }

  const expressionCorrectLinkCheck = (expression, linkList = links) => {
    const link = linkList.find(link => link.from === expression)
    const isLinked = !!link
    let isCorrect = false
    if(isLinked) {
      const fromIndex = link.from.split('-')[2]
      const toIndex = link.to.split('-')[2]

      const fromAnswer = expressionsFrom[fromIndex]
      const toAnswer = expressionsTo[toIndex]

      const flashcardsFound = flashcards.filter(flashcard => flashcard.contentFrom.expression === fromAnswer)
      flashcardsFound.forEach(flashcard => {
        if(flashcard.contentTo.expression === toAnswer) isCorrect = true
      })
     
    }
    return {isLinked, isCorrect}
  }

  const checkIconChooser = (i) => {
    const {isLinked, isCorrect} = expressionCorrectLinkCheck(`expression-from-${i}`)
    if(!isLinked) return ""
    return isCorrect ? <img className="link-game-page__correct-check-img" src={tick}/> : <img className="link-game-page__correct-check-img" src={cross}/>
  }

  const isAllCorrect = (linkList) => {
    let counter = 0;
    linkList.forEach(link => {
      const {isCorrect} = expressionCorrectLinkCheck(link.from, linkList)
      if(isCorrect) counter++
    })
    console.log(counter)
    return counter === NUMBER_OF_EXPRESSIONS;
  }

  const loadNew = async () => {
    setLinks([])
    setActiveExpression(null)
    const res = await api.get(
      `/flashcard/link-game/language-pair/${params.id}`
    );

    const pairShortRes = await api.get(
      `/language-pair/${params.id}/pair-short`
    );
    setPairShort(pairShortRes.data)

    setFlashcards(res.data);
    setExpressionsFrom(
      res.data.map((expression) => expression.contentFrom.expression).sort(() => (Math.random() > .5) ? 1 : -1)
    );
    setExpressionsTo(
      res.data.map((expression) => expression.contentTo.expression)
    );
  }

  useEffect(async () => {
    await loadNew()
  }, []);

  return (
    <PageStructure setError={props.setError}
altBgMap tutorialLink pairShort={pairShort}>
      <ColouredReturnButton
        className="coloured-return-button--top-left"
        onClick={history.goBack}
      >
        {Store.getText("back")}
      </ColouredReturnButton>
      <div className="link-game-page">
      <h1 className="link-game-page__main-heading">{Store.getText('pairGameName')}</h1>


        <div className="link-game-page__expressions-wrapper">
          {flashcards.length > 0 && (
            <ul className="link-game-page__expression-list link-game-page__expression-list--to">
              {expressionsTo.map((expression, i) => (
                <li
                  className={`link-game-page__expression link-game-page__expression--to ${isExpressionActive(`expression-to-${i}`) ? "link-game-page__expression--active" : ""} expression-to-${i}`}
                  onClick={() => clickExpression(`expression-to-${i}`)}
                  key={expression + i}
                >
                  {expression}
                </li>
              ))}
            </ul>
          )}

          {flashcards.length > 0 && (
            <ul className="link-game-page__expression-list link-game-page__expression-list--from">
              {expressionsFrom.map((expression, i) => (
                <li
                  className={`link-game-page__expression link-game-page__expression--from ${isExpressionActive(`expression-from-${i}`) ? "link-game-page__expression--active" : ""} expression-from-${i}`}
                  onClick={() => clickExpression(`expression-from-${i}`)}

                  key={expression + i}
                >
                  {expression}
                  {checkIconChooser(i)}
                </li>
              ))}
            </ul>
          )}

        </div>
        {links.map((link, i) => 
          
          <LineTo from={link.from} to={link.to} key={i} fromAnchor="left" toAnchor="right" className="link-game-page__line" borderColor="#fccd08" borderWidth={4} zIndex={0}/>
        )}
      </div>
    </PageStructure>
  );
}

export default LinkGamePage;
