export const lessonDictionary = {
    pl: {
     level1: {
         1: 'Powitania i Podstawy',
         2: 'Liczby',
         3: 'Kolory',
         4: 'Miesiące i Pory roku',
         5: 'Ubrania',
         6: 'Jedzenie 1',
         7: 'Jedzenie 2',
         8: 'Jedzenie 3',
         9: 'Dni tygodnia',
         10: 'Kierunki',
         11: 'Przymiotniki 1',
         12: 'Przymiotniki 2',
         13: 'Przymiotniki 3',
         14: 'Osoby',
         15: 'Określenia czasu',
         16: 'Czasowniki 1',
         17: 'Czasowniki 2',
         18: 'Czasowniki 3',
         19: 'Życie codzienne 1',
         20: 'Życie codzienne 2',
         21: 'Narodowości 1',
         22: 'Narodowości 2',
         23: 'Narodowości 3',
         24: 'Podróże 1',
         25: 'Podróże 2',
         26: 'Biuro/Praca 1',
         27: 'Biuro/Praca 2',
         28: 'Zawody',
         29: 'Restauracja',
         30: 'Czasowniki 4',
         31: 'Czasowniki 5',
         32: 'Czasowniki 6',
         33: 'Zwierzęta',
         34: 'Części ciała i zmysły 1',
         35: 'Części ciała i zmysły 2',
         36: 'Dodatkowa lekcja 1',
         37: 'Dodatkowa lekcja 2',
         38: 'Dodatkowa lekcja 3',
     },
     default: "Lekcja"
    },
    cs: {
        level1: {
            1: 'Pozdravy a základy',
         2: 'Čísla',
         3: 'Barvy',
         4: 'Měsíce a roční období',
         5: 'Oblečení',
         6: 'Potraviny 1',
         7: 'Potraviny 2',
         8: 'Potraviny 3',
         9: 'Dny týdne',
         10: 'Instrukce',
         11: 'Přídavná 1',
         12: 'Přídavná 2',
         13: 'Přídavná 3',
         14: 'Osoby',
         15: 'Stanovení času',
         16: 'Slovesa 1',
         17: 'Slovesa 2',
         18: 'Slovesa 3',
         19: 'Každodenní život 1',
         20: 'Každodenní život 2',
         21: 'Národnosti 1',
         22: 'Národnosti 2',
         23: 'Národnosti 3',
         24: 'Cestování 1',
         25: 'Cestování 2',
         26: 'Kancelář/práce 1',
         27: 'Kancelář/práce 2',
         28: 'Profese',
         29: 'Restaurace',
         30: 'Slovesa 4',
         31: 'Slovesa 5',
         32: 'Slovesa 6',
         33: 'Zvířata',
         34: 'Části těla a smysly 1',
         35: 'Části těla a smysly 2',
         36: 'Doplňující lekce 1',
         37: 'Doplňující lekce 2',
         38: 'Doplňující lekce 3',
        },
        default: "Lekce"
    },
    sk: {
        level1: {
            1: 'Pozdravy a základy',
         2: 'Čísla',
         3: 'Farby',
         4: 'Mesiace a ročné obdobia',
         5: 'Oblečenie',
         6: 'Jedlo 1',
         7: 'Jedlo 2',
         8: 'Jedlo 3',
         9: 'Dni v týždni',
         10: 'Pokyny',
         11: 'Prídavné mená 1',
         12: 'Prídavné mená 2',
         13: 'Prídavné mená 3',
         14: 'Osoby',
         15: 'Časové podmienky',
         16: 'Slovesá 1',
         17: 'Slovesá 2',
         18: 'Slovesá 3',
         19: 'Každodenný život 1',
         20: 'Každodenný život 2',
         21: 'Národnosti 1',
         22: 'Národnosti 2',
         23: 'Národnosti 3',
         24: 'Cestuje 1',
         25: 'Cestuje 2',
         26: 'Kancelária / Práca 1',
         27: 'Kancelária / Práca 2',
         28: 'Súťaže',
         29: 'Reštaurácia',
         30: 'Slovesá 4',
         31: 'Slovesá 5',
         32: 'Slovesá 6',
         33: 'Zvieratá',
         34: 'Časti tela a zmysly 1',
         35: 'Časti tela a zmysly 2',
         36: 'Extra lekcia 1',
         37: 'Extra lekcia 2',
         38: 'Extra lekcia 3',
        },
        default: "Lekcia"
    },
    en: {
        level1: {
            1: 'Greetings & Basics',
         2: 'Numbers',
         3: 'Colors',
         4: 'Months and Seasons',
         5: 'Clothes',
         6: 'Food 1',
         7: 'Food 2',
         8: 'Food 3',
         9: 'Days of the week',
         10: 'Days of the week',
         11: 'Adjectives 1',
         12: 'Adjectives 2',
         13: 'Adjectives 3',
         14: 'Persons',
         15: 'Time',
         16: 'Verbs 1',
         17: 'Verbs 2',
         18: 'Verbs 3',
         19: 'Daily life 1',
         20: 'Daily life 2',
         21: 'Nationalities 1',
         22: 'Nationalities 2',
         23: 'Nationalities 3',
         24: 'Travels 1',
         25: 'Travels 2',
         26: 'Office/work 1',
         27: 'Office/work 2',
         28: 'Occupations',
         29: 'Restaurant',
         30: 'Verbs 4',
         31: 'Verbs 5',
         32: 'Verbs 6',
         33: 'Animals',
         34: 'Body parts and senses 1',
         35: 'Body parts and senses 2',
         36: 'Additional lesson 1',
         37: 'Additional lesson 2',
         38: 'Additional lesson 3',
        },
        default: "Lekcia"
    },
    hu: {
        level1: {
        1: 'Üdvözlet és alapok',
         2: 'Számok',
         3: 'Színek',
         4: 'Hónapok és évszakok',
         5: 'Ruházat',
         6: 'Evés 1',
         7: 'Étel 2',
         8: 'Étel 3',
         9: 'A hét napjai',
         10: 'Útvonalak',
         11: 'Melléknevek 1',
         12: 'Melléknevek 2',
         13: 'Melléknevek 3',
         14: 'Személyek',
         15: 'Idő',
         16: 'Igék 1',
         17: 'Igék 2',
         18: 'Igék 3',
         19: 'Mindennapi élet 1',
         20: 'Mindennapi élet 2',
         21: 'Nemzetiségek 1',
         22: 'Nemzetiségek 2',
         23: 'Nemzetiségek 3',
         24: 'Utazások 1',
         25: 'Utazások 2',
         26: 'Iroda/munka 1',
         27: 'Iroda/munka 2',
         28: 'Foglalkozások',
         29: 'Étterem',
         30: 'Igék 4',
         31: 'Igék 5',
         32: 'Igék 6',
         33: 'Állatok',
         34: 'Testrészek és érzékszervek 1',
         35: 'Testrészek és érzékszervek 2',
         36: 'Kiegészítő lecke  1',
         37: 'Kiegészítő lecke  2',
         38: 'Kiegészítő lecke  3',
        },
        default: "Lecke"
    }
   
   
  };
  