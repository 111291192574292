import "./ColouredReturnButton.css";

function ColouredReturnButton(props) {
  return (
    <button {...props} className={"coloured-return-button " + props.className}>
      <i className="retun-icon fas fa-arrow-left"></i> {props.children}
    </button>
  );
}

export default ColouredReturnButton;
