export const dictionary = {
  pageTitle: {
    pl: "Platforma językowa",
    cs: "Jazyková platforma",
    sk: "Jazyková platforma",
    en: "Language platform",
    hu: "Nyelvi platform",
  },
  description: {
    pl: "Naucz się nowego języka poświęcając 15 minut każdego dnia!",
    cs: "Učte se nový jazyk každý den 15 minut!",
    sk: "Naučte sa nový jazyk venujte 15 minút každý deň!",
    en: "Learn a new language in just 15 minutes every day!",
    hu: "Tanuljon meg egy új nyelvet naponta mindössze 15 perc alatt!",
  },
  mainPageLoginTitle: {
    pl: "Logowanie/rejestracja",
    cs: "Přihlášení / registrace",
    sk: "Prihlásenie / registrácia",
    en: "Login / registration",
    hu: "Bejelentkezés / regisztráció",
  },
  mainPageSloganFirst: {
    pl: "Naucz się nowego języka",
    cs: "Učte se nový jazyk",
    sk: "Naučte sa nový jazyk",
    en: "Learn a new language",
    hu: "Tanuljon meg egy új nyelvet",
  },
  mainPageSloganSecond: {
    pl: "i otwórz się na ludzi",
    cs: "a otevřít se lidem",
    sk: "a otvoriť sa ľuďom",
    en: "and open up to people",
    hu: "és nyissa meg magát az emberek előtt",
  },
  mainPageSloganSecond2: {
    pl: "i używaj go na co dzień",
    cs: "a používat ji denně",
    sk: "a používať ho denne",
    en: "and use it on a daily basis",
    hu: "és napi rendszerességgel használja",
  },
  mainPageSloganSecond3: {
    pl: "i rozwiń kompetencje zawodowe",
    cs: "a rozvíjet své odborné kompetence",
    sk: "rozvíjať svoje odborné kompetencie",
    en: "and develop your professional competences",
    hu: "és szakmai kompetenciákat fejleszt",
  },
  mainPageSloganSecond4: {
    pl: "i wyjedź na wymarzone wakacje",
    cs: "a odjeďte na dovolenou svých snů",
    sk: "a vydajte sa na dovolenku svojich snov",
    en: "and go on your dream vacation",
    hu: "és menjen el álmai nyaralására",
  },
  mainPageSloganSecond5: {
    pl: "i oglądaj filmy bez lektora",
    cs: "a sledovat filmy bez hlasového doprovodu",
    sk: "a sledovať filmy bez hlasového komentára",
    en: "and watch movies without voiceover",
    hu: "és külföldi filmeket nézhet",
  },
  mainPageSloganSecond6: {
    pl: "i zadbaj o swoją pamięć",
    cs: "a zlepšit si paměť",
    sk: "a zlepšiť si pamäť",
    en: "and improve your memory",
    hu: "és javítja a memóriáját",
  },
  mainPageSloganSecond7: {
    pl: "i znajdź lepszą pracę",
    cs: "a najít si lepší práci",
    sk: "a nájsť si lepšiu prácu",
    en: "and find a better job",
    hu: "és szerezzen egy jobb állást",
  },
  mainPageSloganSecond8: {
    pl: "i podróżuj bez obaw",
    cs: "a cestovat beze strachu",
    sk: "a cestovať bez strachu",
    en: "and travel without fear",
    hu: "és félelem nélkül utazhat",
  },
  mainPageSloganSecond9: {
    pl: "i porozmawiaj z przyjaciółmi z zagranicy",
    cs: "a mluvit s přáteli ze zahraničí",
    sk: "a hovoriť s priateľmi zo zahraničia",
    en: "and talk to your friends from abroad",
    hu: "és beszélgessen külföldi barátaival",
  },
  homepageBoxSlogan: {
    pl: "Witaj na Platformie Językowej!",
    cs: "Vítejte na Jazykové Platformě!",
    sk: "Vitajte v Jazykovej platforme!",
    en: "Welcome to Your Language Platform!",
    hu: "Üdvözöljük az Ön Nyelvi Platformjában!",
  },
  homepageBoxRegisterSloganFirst: {
    pl: "Załóż konto, jeśli jesteś tu po raz pierwszy",
    cs: "Pokud jste zde poprvé a právě jste obdrželi kód,",
    sk: "Vytvorte si účet, ak ste tu prvýkrát",
    en: "Create an account if you are here for the first time",
    hu: "Hozzon létre fiókot, ha először jár itt,",
  },
  homepageBoxRegisterSloganSecond: {
    pl: "i właśnie otrzymałeś/aś kod.",
    cs: "vytvořte si účet.",
    sk: "a práve ste dostali kód.",
    en: "and have received your code.",
    hu: "és megkapta a kódját.",
  },
  homepageBoxLoginSloganFirst: {
    pl: "Zaloguj się, jeśli wpisywałeś/aś już kod i",
    cs: "Pokud jste již zadali kód a máte účet,",
    sk: "Prihláste sa, ak ste už zadali kód",
    en: "Log in if you have already entered the code",
    hu: "Jelentkezzen be, ha már megadta",
  },
  homepageBoxLoginSloganSecond: {
    pl: "posiadasz konto.",
    cs: "přihlaste se.",
    sk: "a máte účet.",
    en: "and have an account",
    hu: "a kódot és rendelkezik fiókkal.",
  },
  registerSlogan: {
    pl: "Aby utworzyć konto i mieć dostęp do nauki wykupionego kursu językowego, wypełnij poniższe pola:",
    cs: "Pro vytvoření účtu a přístup k zakoupenému jazykovému kurzu vyplňte níže uvedená pole:",
    sk: "Ak chcete vytvoriť účet a získať prístup k štúdiu jazykového kurzu, ktorý ste si zakúpili, vyplňte polia nižšie:",
    en: "Fill in the fields below to create an account and have access to your language course:",
    hu: "Töltse ki az alábbi mezőket a fiók létrehozásához és a nyelvtanfolyamhoz való hozzáféréshez:",
  },
  loginSlogan: {
    pl: "Jeśli posiadasz już konto, wypełnij poniższe pola:",
    cs: "Pokud již máte účet, vyplňte níže uvedená pole:",
    sk: "Ak už máte účet, vyplňte polia nižšie:",
    en: "If you already have an account, please fill out the fields below:",
    hu: "Ha már rendelkezik fiókkal, kérjük, töltse ki az alábbi mezőket:",
  },
  back: { pl: "Wstecz", cs: "Zpět", sk: "Späť", en: "Back", hu: "Vissza" },
  emailLabel: {
    pl: "adres e-mail",
    cs: "e-mailová adresa",
    sk: "emailová adresa",
    en: "email address",
    hu: "email cím",
  },
  passwordLabel: {
    pl: "hasło",
    cs: "heslo",
    sk: "heslo",
    en: "password",
    hu: "jelszó",
  },
  activationCodeLabel: {
    pl: "kod",
    cs: "kód",
    sk: "kód",
    en: "code",
    hu: "kód",
  },
  repeatPasswordLabel: {
    pl: "powtórz hasło",
    cs: "Zopakovat heslo",
    sk: "zopakujte heslo",
    en: "repeat password",
    hu: "jelszó újra",
  },
  registerEmailDesc: {
    pl: "Twój adres e-mail, którego będziesz używał/a do zalogowania się",
    cs: "Vaše e-mailová adresa, kterou budete používat pro přihlášení",
    sk: "Vaša e-mailová adresa, ktorú budete používať na prihlásenie",
    en: "Your e-mail address, which you will use to log in",
    hu: "az Ön e-mail címe, amelyet a bejelentkezéshez fog használni",
  },
  loginEmailDesc: {
    pl: "Twój adres e-mail, który podawałeś/aś przy założeniu konta",
    cs: "Vaše e-mailová adresa, kterou jste zadali při vytváření účtu",
    sk: "Vaša e-mailová adresa, ktorú ste uviedli pri vytváraní účtu",
    en: "Your email address you entered when you created account",
    hu: "A fiók létrehozásakor megadott e-mail címed",
  },
  activationCodeDesc: {
    pl: "otrzymałeś/aś go w liście",
    cs: "obdrželi jste ho v dopise",
    sk: "dostali ste to v liste",
    en: "you have received it in the letter",
    hu: "evélben kaptad",
  },
  registerPasswordDesc: {
    pl: "stwórz swoje unikalne hasło, które łatwo zapamiętasz",
    cs: "vytvořte si vlastní jedinečné heslo, které si snadno zapamatujete",
    sk: "vytvorte si svoje jedinečné heslo, ktoré si ľahko zapamätáte",
    en: "create your own unique password that you can easily remember",
    hu: "hozzon létre saját egyedi jelszót, amelyet könnyen megjegyezhet",
  },
  repeatPasswordDesc: {
    pl: "aby upewnić się, że pamiętasz hasło, wpisz je jeszcze raz",
    cs: "abyste se ujistili, že si heslo pamatujete, zadejte ho znovu",
    sk: "aby ste sa uistili, že si zapamätáte svoje heslo, zadajte ho znova",
    en: "to make sure you remember your password, type it again",
    hu: "hogy biztosan emlékezzen a jelszavára, írja be újra",
  },
  passwordsNotMatchAlert: {
    pl: "Hasła nie są takie same",
    cs: "Hesla nejsou stejná",
    sk: "Heslá sa nezhodujú",
    en: "Passwords do not match",
    hu: "A jelszavak nem egyeznek",
  },
  loginPasswordDesc: {
    pl: "Twoje unikalne hasło, które stworzyłeś/aś podczas zakładania konta",
    cs: "Vaše jedinečné heslo, které jste si vytvořili při vytváření účtu",
    sk: "Vaše jedinečné heslo, ktoré ste si vytvorili pri vytváraní účtu",
    en: "Your unique password that you created when you created account",
    hu: "Az Ön egyedi jelszava, amelyet a fiók létrehozásakor hozott létre",
  },
  loginButtonFormText: {
    pl: "Zaloguj się",
    cs: "Přihlásit se",
    sk: "Prihlásiť sa",
    en: "Log in",
    hu: "Bejelentkezés",
  },
  logoutButtonText: {
    pl: "Wyloguj się",
    cs: "Odhlásit se",
    sk: "Odhlásiť sa",
    en: "Log out",
    hu: "Kijelentkezés",
  },
  yourAccount: {
    pl: "Twoje konto:",
    cs: "Váš účet:",
    sk: "Váš účet:",
    en: "Your account:",
    hu: "Fiókja:",
  },
  registerButtonFormText: {
    pl: "Załóż konto",
    cs: "Vytvořit účet",
    sk: "Vytvoriť účet",
    en: "Create an account",
    hu: "HOZZON LÉTRE EGY FIÓKOT",
  },
  congratsText: {
    pl: "Gratulacje!",
    cs: "Gratulujeme!",
    sk: "Gratulujem!",
    en: "Congratulations!",
    hu: "Gratulálunk!",
  },
  registerAlertTextFirst: {
    pl: "Twoja rejestracja przebiegła pomyślnie.",
    cs: "Vaše registrace byla úspěšná.",
    sk: "Vaša registrácia bola úspešná.",
    en: "Your registration was successful.",
    hu: "A regisztráció sikeres volt.",
  },
  registerAlertTextSecond: {
    pl: "Możesz teraz przystąpić do kursu.",
    cs: "Nyní se můžete připojit ke kurzu.",
    sk: "Teraz sa môžete zapojiť do kurzu.",
    en: "You can now join the course.",
    hu: "Most csatlakozhat a tanfolyamhoz.",
  },
  registerAlertButtonText: {
    pl: "Przechodzę dalej",
    cs: "Jdu dál",
    sk: "Idem dalej",
    en: "Next up",
    hu: "Megyek tovább",
  },
  chooseLanguagePageTitle: {
    pl: "Wybierz język",
    cs: "Zvolte si jazyk",
    sk: "Vyberte jazyk",
    en: "Select language",
    hu: "Válasszon nyelvet:",
  },
  chooseLevelPageTitle: {
    pl: "Wybierz poziom trudności",
    cs: "Zvolte úroveň obtížnosti",
    sk: "Vyberte si úroveň obtiažnosti",
    en: "Select difficulty level",
    hu: "Válassza ki a nehézségi szintet",
  },
  levelOneText: {
    pl: "podstawowy",
    cs: "základní",
    sk: "ZÁKLADNÉ",
    en: "Basic",
    hu: "Alapvető",
  },
  levelTwoText: {
    pl: "średni",
    cs: "střední",
    sk: "medziprodukt",
    en: "Medium",
    hu: "Közepes",
  },
  levelThreeText: {
    pl: "zaawansowany",
    cs: "pokročilý",
    sk: "POKROČILÉ",
    en: "Advanced",
    hu: "Fejlett",
  },
  backToChooseLanguagePageText: {
    pl: "Wybór języka",
    cs: "Zpět na výběr jazyka",
    sk: "Výber jazyka",
    en: "Language selection",
    hu: "Nyelvválasztás",
  },
  backToChooseLevelPageText: {
    pl: "Wybór poziomu",
    cs: "Zpět na výběr úrovně",
    sk: "Výber úrovne",
    en: "Level selection",
    hu: "Szintválasztás",
  },
  knowAnswerText: {
    pl: "Wiem",
    cs: "Vím",
    sk: "Viem",
    en: "Know",
    hu: "TUDOM",
  },
  notSureAnswerText: {
    pl: "Prawie",
    cs: "Téměř",
    sk: "Takmer",
    en: "Not sure",
    hu: "MAJDNEM",
  },
  dontKnowAnswerText: {
    pl: "Nie wiem",
    cs: "Nevím",
    sk: "Neviem",
    en: "I don't know",
    hu: "NEM TUDOM",
  },
  rotate: {
    pl: "Obróć",
    cs: "Otočení",
    sk: "Točiť sa",
    en: "Rotate",
    hu: "Forog",
  },
  milestoneModalTextFirst: {
    pl: "Znasz już",
    cs: "Už znáš",
    sk: "Vy už viete",
    en: "You already know",
    hu: "Már tudja",
  },
  milestoneModalTextSecond: {
    pl: "słów!",
    cs: "slov!",
    sk: "slová!",
    en: "words!",
    hu: "szavak!",
  },
  milestoneModalButtonText: {
    pl: "Chcę umieć więcej!",
    cs: "Chci vědět více!",
    sk: "Chcem vedieť viac!",
    en: "I want to know more!",
    hu: "Többet akarok tudni!",
  },
  showPassword: {
    pl: "Pokaż hasło",
    cs: "Zobrazit heslo",
    sk: "Ukázať heslo",
    en: "Show password",
    hu: "Mutasd a jelszót",
  },
  hidePassword: {
    pl: "Ukryj hasło",
    cs: "Skrýt heslo",
    sk: "Skryť heslo",
    en: "Hide password",
    hu: "Jelszó elrejtése",
  },
  howToLearn: {
    pl: "Jak się uczyć?",
    cs: "Jak se učit?",
    sk: "Ako sa učiť?",
    en: "How to learn?",
    hu: "Hogyan tanuljunk?",
  },
  helpText: {
    pl: "Pomoc",
    cs: "Pomoc",
    sk: "Pomoc",
    en: "Help",
    hu: "Segítség",
  },
  yourProgress: {
    pl: "Twój postęp",
    cs: "Váš pokrok",
    sk: "Váš pokrok",
    en: "Your progress",
    hu: "A fejlődése",
  },
  chooseHowToLearn: {
    pl: "Wybierz sposób nauki",
    cs: "Vybrat způsob, jak se učit",
    sk: "Vyberte si spôsob učenia",
    en: "Choose your way of learning",
    hu: "Válassza ki a tanulás módját",
  },
  backToChooseLevel: {
    pl: "Powrót do wyboru poziomu",
    cs: "Zpět na výběr úrovně",
    sk: "Späť na výber úrovne",
    en: "Back to level selection",
    hu: "Vissza a szintválasztáshoz",
  },
  lessons: {
    pl: "lekcje",
    cs: "lekce",
    sk: "lekcie",
    en: "Lessons",
    hu: "Leckék",
  },
  exercises: {
    pl: "ćwiczenia",
    cs: "cvičení",
    sk: "cvičenia",
    en: "Exercises",
    hu: "Feladatok",
  },
  repeat: {
    pl: "powtórka",
    cs: "opakovat",
    sk: "opakovať",
    en: "Repetition",
    hu: "Ismétlés",
  },
  chooseLesson: {
    pl: "Wybierz lekcje",
    cs: "Vybrat lekce",
    sk: "Vyberte si lekcie",
    en: "Select lessons",
    hu: "Válasszon leckéket",
  },
  backToChooseActivity: {
    pl: "Powrót to wyboru metody nauki",
    cs: "Zpět k volbě metody učení",
    sk: "Návratom je výber spôsobu učenia",
    en: "Back to choosing a learning method",
    hu: "Vissza a tanulási módszer kiválasztásához",
  },
  backToChooseLesson: {
    pl: "Powrót do wyboru lekcji",
    cs: "Zpět k výběru lekce",
    sk: "Späť na výber lekcie",
    en: "Back to lesson selection",
    hu: "Vissza a lecke kiválasztásához",
  },
  yourProgressInLearning: {
    pl: "Twój postęp w nauce",
    cs: "Váš pokrok  v učení",
    sk: "Váš pokrok v učení",
    en: "Your progress",
    hu: "A fejlődése",
  },
  chooseExercise: {
    pl: "Wybierz ćwiczenie",
    cs: "Vyberte si cvičení",
    sk: "Vyberte si cvičenie",
    en: "Select exercise",
    hu: "Válassza ki a tanulás módját",
  },
  pairGameName: {
    pl: "Pary",
    cs: "Spárovat se",
    sk: "Páry",
    en: "Pairs",
    hu: "Párok",
  },
  testGameName: {
    pl: "Jeden z czterech",
    cs: "Jeden ze čtyř",
    sk: "Jeden zo štyroch",
    en: "One of four",
    hu: "Egy a négyből",
  },
  typeGameName: {
    pl: "Wpisz słowo",
    cs: "Zadejte slovo",
    sk: "Zadajte slovo",
    en: "Enter a word",
    hu: "Írjon be egy szót",
  },
  certificatePageTitle: {
    pl: "CERTYFIKAT",
    cs: "CERTIFIKÁT",
    sk: "CERTIFIKÁT",
    en: "CERTIFICATE",
    hu: "BIZONYÍTVÁNY",
  },
  certificatePageFirstPoint: {
    pl: "Potwierdzenie Twoich umiejętności",
    cs: "Potvrzení vašich dovedností",
    sk: "Potvrdenie vašich schopností",
    en: "Confirmation of your skills",
    hu: "Képességeinek megerősítése",
  },
  certificatePageFirstPointParagraph: {
    pl: "Aby potwierdzić swoje umiejętności językowe, możesz przystąpić do testu weryfikującego Twoją wiedzę. Udzielenie poprawnych odpowiedzi decyduje o uzyskaniu certyfikatu (w cenie kursu). Do testu może przystąpić każdy, kto ukończył wszystkie 3 poziomy nauki języka w 100%. Zwróć uwagę na to, że jeśli którykolwiek z poziomów nie jest w pełni ukończony, nie będzie można przystąpić do testu.",
    cs: "Své jazykové znalosti si můžete ověřit pomocí testu. Za správné odpovědi obdržíte certifikát (v ceně kurzu). Testu se může zúčastnit každý, kdo absolvoval všechny lekce ve třech úrovních jazykového vzdělávání na 100 %. Cvičení a opakování se nezapočítávají do vašeho pokroku. Vezměte prosím na vědomí, že pokud některá z úrovní nebude zcela dokončena, nebudete moci test absolvovat.",
    sk: "Ak chcete potvrdiť svoje jazykové znalosti, môžete absolvovať test na overenie svojich vedomostí. Pre získanie certifikátu (v cene kurzu) je rozhodujúce uvedenie správnych odpovedí. Test môže absolvovať každý, kto absolvoval všetky 3 úrovne jazykového vzdelávania na 100 %. Upozorňujeme, že ak niektorá z úrovní nie je úplne dokončená, nebudete môcť vykonať test.",
    en: "To confirm your language skills, you can take a test to verify your knowledge. Correct answers are decisive for obtaining the certificate (included in the course price). The test can be taken by anyone who has completed all 3 levels of language study in 100%. Please note that if any of the levels is not fully completed, you will not be able to take the test.",
    hu: "Nyelvtudásának igazolására egy teszten ellenőrizheti tudását. A bizonyítvány megszerzéséhez a helyes válaszok a döntőek (a tanfolyam árában benne van). A tesztet bárki teheti, aki a nyelvtanulás mind a 3 szintjét 100%-ban elvégezte. Felhívjuk figyelmét, hogy ha valamelyik szintet nem teljesíti teljesen, akkor nem tudja teljesíteni a tesztet.",
  },
  certificatePageButtonText: {
    pl: "Zrób test",
    cs: "Proveďte test",
    sk: "Urobte si test",
    en: "Take a test",
    hu: "Vegyél egy tesztet",
  },
  certificatePageButtonActiveDesc: {
    pl: "Możesz przystąpić do testu – przycisk jest aktywny.",
    cs: "Test si můžete vyzkoušet - tlačítko je aktivní.",
    sk: "Môžete spustiť test - tlačidlo je aktívne",
    en: "You can take the test - the button is active.",
    hu: "Elvégezheti a tesztet - a gomb aktív.",
  },
  certificatePageButtonNotActiveDesc: {
    pl: "Nie możesz przystąpić do testu – przycisk  pozostaje nieaktywny.",
    cs: "Test nelze provést - tlačítko zůstane neaktivní.",
    sk: "V teste nemôžete pokračovať - ​​tlačidlo zostáva neaktívne.",
    en: "You cannot take the test - the button remains inactive.",
    hu: "A tesztet nem tudja elvégezni – a gomb inaktív marad.",
  },
  certificatePageProgressTitle: {
    pl: "Twoje aktualne postępy na wszystkich poziomach",
    cs: "Váš aktuální postup na všech úrovních",
    sk: "Váš aktuálny pokrok na všetkých úrovniach",
    en: "Your current progress at all levels",
    hu: "Jelenlegi fejlődésed minden szinten",
  },
  certificatePageSecondPoint: {
    pl: "Jak otrzymać certyfikat?",
    cs: "Jak získat certifikát?",
    sk: "Ako získať certifikát?",
    en: "How to get the certificate?",
    hu: "Hogyan lehet megszerezni a tanúsítványt?",
  },
  certificatePageSecondPointParagraph: {
    pl: "Po ukończeniu 3 poziomów kursu na 100 % otrzymasz dostęp do testu online, który należy poprawnie wypełnić w celu uzyskania certyfikatu. Jest on imienny i potwierdza ukończenie kursu na odpowiednim poziomie. Po zdaniu testu i uzyskaniu pozytywnego wyniku, certyfikat będzie możliwy do pobrania.",
    cs: "Po absolvování všech lekcí ve třech úrovních kurzu na 100 % získáte přístup k online testu, který musíte správně vyplnit, abyste získali certifikát. Je osobní a potvrzuje, že jste absolvovali kurz na odpovídající úrovni. Po úspěšném absolvování testu a získání pozitivního výsledku bude k dispozici certifikát ke stažení.",
    sk: "Po absolvovaní 3 úrovní 100% kurzu získate prístup k online testu, ktorý je potrebné správne vyplniť pre získanie certifikátu. Je osobný a potvrdzuje absolvovanie kurzu na príslušnej úrovni. Po absolvovaní testu a získaní pozitívneho výsledku bude certifikát k dispozícii na stiahnutie.",
    en: "Once you have completed the 3 levels of the 100% course, you will be given access to an online test that must be completed correctly in order to receive your certificate. It is personal and confirms that you have completed the course at the appropriate level. Once you have passed the test and received a passing score, the certificate will be downloadable.",
    hu: "Miután elvégezte a 100%-os tanfolyam 3 szintjét, hozzáférést kap egy online teszthez, amelyet helyesen kell kitöltenie, hogy megkapja a bizonyítványt. Személyes, és megerősíti, hogy a tanfolyamot a megfelelő szinten végezte. Miután sikeresen teljesítette a vizsgát és sikeres pontszámot kapott, a bizonyítvány letölthető lesz.",
  },
  certificatePageGoodLuck: {
    pl: "Powodzenia ;)",
    cs: "Hodně štěstí ;)",
    sk: "Veľa štastia ;)",
    en: "Good luck ;)",
    hu: "Sok szerencsét ;)",
  },
  tutorialTitle: {
    pl: "Jak się uczyć?",
    cs: "Jak se to naučit?",
    sk: "Ako sa učiť",
    en: "How to learn.",
    hu: "Hogyan tanuljunk.",
  },
  tutorialFirstParagraph: {
    pl: "Aby ułatwić Ci poruszanie się po platformie językowej przygotowaliśmy dla Ciebie instrukcję, dzięki której bez problemu przyswoisz podstawy poruszania się po platformie.",
    cs: "Abychom vám usnadnili orientaci v jazykové platformě, připravili jsme pro vás průvodce, který vám pomůže seznámit se se základy této platformy.",
    sk: "Aby sme vám uľahčili orientáciu v jazykovej platforme, pripravili sme pre vás manuál, vďaka ktorému si jednoducho osvojíte základy pohybu na platforme.",
    en: "To make it easier for you to move around the language platform, we have prepared a manual which will help you learn the basics of the platform.",
    hu: "Annak érdekében, hogy könnyebben mozoghasson a nyelvi platformon, elkészítettünk egy kézikönyvet, amely segít elsajátítani a platform alapjait.",
  },
  tutorialFirstPointTitle: {
    pl: "Wybierz język",
    cs: "Výběr jazyka",
    sk: "Vyberte jazyk",
    en: "Select language",
    hu: "Válasszon nyelvet",
  },
  tutorialFirstPointParagraph: {
    pl: "Tutaj otrzymujesz dostęp do wszystkich zakupionych kursów. Mają one kolorowe flagi. Kursy do których nie masz dostępu są opatrzone w czarno-białe flagi. Możesz wykupić do nich dostęp w każdej chwili, kontaktując się ponownie z naszym konsultantem.",
    cs: "Zde získáte přístup ke všem zakoupeným kurzům. Ty mají barevné vlajky. Kurzy, ke kterým nemáte přístup, jsou označeny černobíle. Přístup k nim si můžete kdykoli zakoupit tak, že opět kontaktujete našeho konzultanta.",
    sk: "Tu získate prístup ku všetkým zakúpeným kurzom. Majú farebné vlajky. Kurzy, ku ktorým nemáte prístup, sú označené čiernobielymi vlajkami. Prístup k nim si môžete kedykoľvek zakúpiť opätovným kontaktovaním nášho konzultanta.",
    en: "Here you get access to all the courses you have purchased. They have colored flags. Courses that you do not have access to are given black and white flags. You can purchase access to them at any time by contacting our consultant again.",
    hu: "Itt hozzáférhet az összes megvásárolt tanfolyamhoz. Színes zászlóik vannak. Azok a kurzusok, amelyekhez nem fér hozzá, fekete-fehér zászlókat kapnak. A hozzájuk való hozzáférést bármikor megvásárolhatja, ha ismét kapcsolatba lép tanácsadónkkal.",
  },
  tutorialSecondPointTitle: {
    pl: "Wybierz poziom trudności",
    cs: "Vyberte si úroveň obtížnosti",
    sk: "Vyberte úroveň obtiažnosti",
    en: "Select difficulty level",
    hu: "Válassza ki a nehézségi szintet",
  },
  tutorialSecondPointParagraph: {
    pl: "W tym miejscu masz do wyboru trzy poziomy trudności nauki języka.",
    cs: "Zde máte na výběr ze tří úrovní obtížnosti učení jazyka.",
    sk: "V tomto bode si môžete vybrať z troch úrovní obtiažnosti jazykového vzdelávania.",
    en: "Here you can choose from three levels of language learning difficulty.",
    hu: "Itt három nyelvtanulási nehézségi szint közül választhat.",
  },
  tutorialSecondPointParagraph2: {
    pl: "Wybierz ten, którego chcesz się aktualnie uczyć.",
    cs: "Vyberte si ten, který se chcete právě učit.",
    sk: "Vyberte si ten, ktorý chcete momentálne študovať.",
    en: "Choose the one you want to study at the moment.",
    hu: "Válassza ki azt, amelyet jelenleg tanulni szeretne.",
  },
  tutorialThirdPointTitle: {
    pl: "Wybór sposobu nauki",
    cs: "Zvolte si způsob učení",
    sk: "Výber spôsobu učenia",
    en: "Choosing a way to learn",
    hu: "A tanulás módjának megválasztása",
  },
  tutorialThirdPointParagraph: {
    pl: "Wybierz jak chcesz się uczyć.",
    cs: "Vyberte si, jak se chcete učit.",
    sk: "Vyberte si, ako sa chcete učiť.",
    en: "Choose the way you want to learn.",
    hu: "Válassza ki a tanulási módot.",
  },
  tutorialLearningMethodsFirstPointTitle: {
    pl: "Lekcje",
    cs: "Lekce",
    sk: "Lekcie",
    en: "Lessons",
    hu: "Leckék",
  },
  tutorialLearningMethodsFirstPointParagraph: {
    pl: "Jest to standardowy sposób nauki, lekcje są podzielone na rozdziały. Dzięki temu nauka przebiega sprawnie i szybko. Rekomendujemy naukę zgodnie z kolejnością lekcji.",
    cs: "Jedná se o standardní způsob výuky, lekce jsou rozděleny do kapitol. Díky tomu je učení rychlé a efektivní. Doporučujeme, abyste se učili v pořadí lekcí.",
    sk: "Toto je štandardný spôsob učenia, hodiny sú rozdelené do kapitol. Vďaka tomu učenie prebieha hladko a rýchlo. Odporúčame učiť sa podľa poradia hodín.",
    en: "This is a standard way of learning, the lessons are divided into chapters. This makes learning fast and efficient. We recommend that you learn in the order of the lessons.",
    hu: "Ez egy szabványos tanulási mód, a leckék fejezetekre vannak osztva. Ez gyorssá és hatékonysá teszi a tanulást. Javasoljuk, hogy a leckék sorrendjében tanuljon.",
  },
  tutorialLearningMethodsDescriptionTitle: {
    pl: "Każda lekcja jest oznaczona kolorem:",
    cs: "Každá lekce je barevně odlišena:",
    sk: "Každá lekcia je farebne odlíšená:",
    en: "Każda lekcja jest oznaczona kolorem:",
    hu: "Każda lekcja jest oznaczona kolorem:",
  },
  tutorialLearningMethodsDescriptionItem: {
    pl: "szary – lekcja nie została jeszcze rozpoczęta",
    cs: "šedá – lekce ještě nebyla zahájena",
    sk: "šedá – hodina ešte nezačala",
    en: "gray – the lesson has not started yet",
    hu: "szürke – még nem kezdődött el a lecke",
  },
  tutorialLearningMethodsDescriptionItem2: {
    pl: "żółty – lekcja w trakcie",
    cs: "žlutá – probíhající lekce",
    sk: "žltá – prebieha lekcia",
    en: "yellow - lesson in progress",
    hu: "sárga - lecke folyamatban",
  },
  tutorialLearningMethodsDescriptionItem3: {
    pl: "zielony – przerobiłeś już cały materiał z danej lekcji",
    cs: "zelená – již jste probrali celou látku lekce.",
    sk: "zelená – už ste dokončili všetku látku z hodiny",
    en: "green - you have already finished all the material in the lesson",
    hu: "zöld - már befejezte az összes anyagot a leckében",
  },
  tutorialLearningMethodsSecondPointTitle: {
    pl: "Ćwiczenia",
    cs: "Cvičení",
    sk: "Cvičenia",
    en: "Exercises",
    hu: "Feladatok",
  },
  tutorialLearningMethodsSecondPointParagraph: {
    pl: "W tym trybie nauki, możesz trenować swoją pamięć za pomocą różnorodnych ćwiczeń.",
    cs: "V tomto režimu učení můžete trénovat paměť pomocí různých cvičení.",
    sk: "V tomto režime učenia si môžete trénovať pamäť pomocou rôznych cvičení.",
    en: "In this learning mode, you can train your memory with a variety of exercises.",
    hu: "Ebben a tanulási módban különféle gyakorlatokkal edzheti a memóriáját.",
  },
  tutorialLearningMethodsThirdPointTitle: {
    pl: "Powtórz materiał",
    cs: "Opakování materiálu",
    sk: "Opakujte látku",
    en: "Repetition",
    hu: "Ismétlés",
  },
  tutorialLearningMethodsThirdPointParagraph: {
    pl: "W tym miejscu algorytm testuje poziom Twojej wiedzy. Możesz powtarzać tu materiał opanowany podczas lekcji, lub po prostu uczyć się w swoim tempie – bez podziału na lekcje",
    cs: "Zde algoritmus prověří vaši úroveň znalostí. Zde si můžete zopakovat látku, kterou jste se naučili v lekci, nebo se jednoduše učit svým vlastním tempem - bez rozdělení do lekcí.",
    sk: "Toto je miesto, kde algoritmus testuje úroveň vašich vedomostí. Tu si môžete zopakovať látku, ktorú ste sa naučili počas lekcií, alebo sa len učiť vlastným tempom – bez rozdelenia na lekcie",
    en: "This is where the algorithm tests your level of knowledge. Here you can repeat the material you have learnt in class or simply learn at your own pace - without dividing the lessons",
    hu: "Az algoritmus itt teszteli tudását. Itt megismételheti az órán tanult tananyagot, vagy egyszerűen saját tempójában tanulhatja meg – a leckék felosztása nélkül",
  },
  tutorialLearningMethodsThirdPointImgTitle: {
    pl: "Każda karta edukacyjna składa się z kilku elementów:",
    cs: "Každá výuková karta se skládá z několika prvků:",
    sk: "Každá vzdelávacia karta pozostáva z niekoľkých prvkov:",
    en: "Each educational card consists of several elements:",
    hu: "Minden oktatási kártya több elemből áll:",
  },
  tutorialLearningMethodsThirdPointImgDescriptionTitle: {
    pl: "Karta edukacyjna:",
    cs: "Učební karta:",
    sk: "Vzdelávací preukaz:",
    en: "Educational Card:",
    hu: "Oktatási kártya:",
  },
  tutorialLearningMethodsThirdPointImgDescriptionItem: {
    pl: "grafika przedstawiająca słówko, którego aktualnie się uczysz",
    cs: "grafické znázornění slova, které se právě učíte.",
    sk: "grafické znázornenie slova, ktoré sa práve učíte",
    en: "A picture of the word you are currently learning",
    hu: "Egy kép a szóról, amelyet éppen tanulsz",
  },
  tutorialLearningMethodsThirdPointImgDescriptionItem2: {
    pl: "słówko do nauki",
    cs: "slovo k naučení",
    sk: "slovo učiť sa",
    en: "word to learn",
    hu: "tanulnivaló szó",
  },
  tutorialLearningMethodsThirdPointImgDescriptionItem3: {
    pl: "głośnik – po naciśnięciu usłyszysz prawidłową wymowę",
    cs: "hlasitý reproduktor – po stisknutí uslyšíte správnou výslovnost.",
    sk: "reproduktor – stlačením si vypočujete správnu výslovnosť",
    en: "loudspeaker – press to hear the correct pronunciation",
    hu: "hangszóró – nyomja meg a megfelelő kiejtés meghallgatásához",
  },
  tutorialLearningMethodsThirdPointImgDescriptionItem4: {
    pl: "przykładowe zdanie z użytym słówkiem",
    cs: "příklad věty s použitým slovem",
    sk: "príkladová veta s použitým slovom",
    en: "example sentence with the word used",
    hu: "példamondat a használt szóval",
  },
  tutorialLearningMethodsThirdPointImgDescriptionItem5: {
    pl: "głośnik – po naciśnięciu usłyszysz prawidłową wymowę",
    cs: "reproduktor – po stisknutí uslyšíte správnou výslovnost.",
    sk: "reproduktor – stlačením si vypočujete správnu výslovnosť",
    en: "loudspeaker – press to hear the correct pronunciation",
    hu: "hangszóró – nyomja meg a megfelelő kiejtés meghallgatásához",
  },
  tutorialLearningMethodsThirdPointImgDescriptionItem6: {
    pl: "przycisk odwrócenia fiszki – po naciśnięciu ujrzysz tę samą fiszkę w swoim ojczystym języku",
    cs: "tlačítko Obrátit kartu – po stisknutí se zobrazí stejná karta ve vašem rodném jazyce.",
    sk: "tlačidlo prevrátenia karty – po stlačení sa vám zobrazí rovnaká karta vo vašom rodnom jazyku",
    en: "Reverse card button – Press to see the same card in your native language",
    hu: "Kártya megfordítása gomb – Nyomja meg, hogy ugyanazt a kártyát anyanyelvén tekintse meg",
  },
  tutorialLearningMethodsThirdPointSecondImgTitle: {
    pl: "Pole wyboru",
    cs: "Pole volby",
    sk: "Zaškrtávacie políčko",
    en: "Checkbox",
    hu: "Jelölőnégyzet",
  },
  tutorialLearningMethodsThirdPointSecondImgDescription: {
    pl: "Tu możesz wybrać, jak dobrze znasz i rozumiesz aktualnie wyświetlane słówko.",
    cs: "Zde si můžete vybrat, jak dobře znáte a rozumíte aktuálně zobrazenému slovu.",
    sk: "Tu si môžete vybrať, ako dobre poznáte a rozumiete aktuálne zobrazenému slovu.",
    en: "Here you can choose how well you know and understand the currently displayed word.",
    hu: "Itt kiválaszthatja, hogy mennyire ismeri és érti az éppen megjelenített szót.",
  },
  tutorialFourthPointTitle: {
    pl: "Twoje konto",
    cs: "Váš účet",
    sk: "Váš účet",
    en: "Your account:",
    hu: "Fiókja:",
  },
  tutorialFourthPointParagraph: {
    pl: "Aby wylogować się z platformy, kliknij ikonę profilu, w prawym górnym rogu. Po kliknięciu, następnym razem trzeba będzie się zalogować.",
    cs: "Kliknutím na toto tlačítko v pravém horním rohu obrazovky získáte přístup k některým důležitým možnostem.",
    sk: "Kliknutím na toto tlačidlo v pravom hornom rohu obrazovky získate prístup k niektorým dôležitým možnostiam.",
    en: "To log out of the platform, click the profile icon in the upper right corner. Once clicked, you will need to log in next time.",
    hu: "A platformról való kijelentkezéshez kattintson a jobb felső sarokban található profil ikonra. Miután rákattintott, legközelebb be kell jelentkeznie.",
  },
  tutorialFourthPointParagraph2: {
    pl: "Pod przyciskiem pojawi się nazwa Twojego konta (jest to Twój adres e-mail używany do logowania).",
    cs: "Pod tlačítkem se zobrazí název vašeho účtu (je to vaše e-mailová adresa, která se používá k přihlášení).",
    sk: "Pod tlačidlom sa zobrazí názov vášho účtu (je to vaša e-mailová adresa, ktorá sa používa na prihlásenie).",
    en: "Your account name will appear under the button (this is your email address used to log in).",
    hu: "A fiók neve megjelenik a gomb alatt (ez a bejelentkezéshez használt e-mail címe).",
  },
  tutorialFourthPointList: {
    pl: "Zmień hasło – Wybierz tę opcję, aby zmienić hasło.",
    cs: "Změna hesla – tuto možnost vyberte, chcete-li změnit heslo.",
    sk: "Zmena hesla – výberom tejto možnosti môžete zmeniť heslo",
    en: "Change Password – Select this option to change the password.",
    hu: "Jelszó módosítása – Válassza ezt a lehetőséget a jelszó megváltoztatásához.",
  },
  tutorialFourthPointList2: {
    pl: "Pomoc – tutaj dowiesz się, jak uzyskać pomoc",
    cs: "Pomoc – zde se dozvíte, jak získat pomoc",
    sk: "Pomoc – tu sa dozviete, ako získať pomoc",
    en: "Help – Find out how to get help here",
    hu: "Segítség – Itt megtudhatja, hogyan kaphat segítséget",
  },
  tutorialFourthPointList3: {
    pl: "Wyloguj się – po ukończeniu kursu możesz się wylogować.",
    cs: "Odhlášení – po dokončení kurzu se můžete odhlásit.",
    sk: "Odhlásenie – po dokončení kurzu sa môžete odhlásiť",
    en: "Log out – you can log out after completing the course.",
    hu: "Kijelentkezés – a tanfolyam elvégzése után jelentkezhet ki.",
  },
  tutorialFifthPointTitle: {
    pl: "Dodawanie języka",
    cs: "Přidání jazyka",
    sk: "Pridanie jazyka",
    en: "Adding a language",
    hu: "Nyelv hozzáadása",
  },
  tutorialFifthPointParagraph: {
    pl: "Po najechaniu kursorem myszy na ikonę języka, który nie jest dostępny, zostanie wyświetlony mały ekran zawierający informacje o tym, jak aktywować inny język.",
    cs: "Když najedete myší na ikonu jazyka, který není k dispozici, zobrazí se malý průvodce s informacemi, jak aktivovat jiný jazyk.",
    sk: "Keď myšou prejdete na ikonu jazyka, ktorý nie je k dispozícii, zobrazí sa malý sprievodca s informáciami o tom, ako aktivovať iný jazyk.",
    en: "If you hover your mouse over the icon for a language that is not available, a small screen will appear with information on how to activate another language.",
    hu: "Ha az egeret egy nem elérhető nyelv ikonja fölé viszi, egy kis képernyő jelenik meg egy másik nyelv aktiválásával kapcsolatos információkkal.",
  },
  contactText: {
    pl: "W razie jakichkolwiek problemów prosimy o kontakt: bok@eeagency.eu",
    cs: "V případě jakýchkoli problémů se prosím obraťte na: kzs@eeagency.eu",
    sk: "V prípade akýchkoľvek problémov nás prosím kontaktujte: ksz@eeagency.eu",
    en: "If you have any problems please contact us: @mail",
    hu: "Ha bármilyen problémája van, forduljon hozzánk: @mail",
  },
  addKey: {
    pl: "Dodaj kod",
    cs: "Přidat kód",
    sk: "Pridať kód",
    en: "Add the code",
    hu: "Kód hozzáadása",
  },
  startLearning: {
    pl: "Rozpocznij naukę",
    cs: "Začněte se učit",
    sk: "Začnite sa učiť",
    en: "Start learning",
    hu: "Kezdje el a tanulást",
  },
  typeGameMessage3: {
    pl: "Gratulacje! To poprawna odpowiedź.",
    cs: "Gratulujeme! Toto je správná odpověď.",
    sk: "Gratulujeme! Toto je správna odpoveď.",
    en: "Congratulations! That's the correct answer.",
    hu: "Gratulálunk! Ez a helyes válasz.",
  },
  typeGameMessage2: {
    pl: "Dobrze! Poprawna pisownia:",
    cs: "Dobře! Správný pravopis:",
    sk: "Dobre! Správny pravopis:",
    en: "Good! Correct spelling:",
    hu: "Szuper! Helyes írásmód:",
  },
  typeGameMessage1: {
    pl: "Niestety. Chodziło o:",
    cs: "Bohužel. Jednalo se o:",
    sk: "Bohužiaľ. Išlo o:",
    en: "Unfortunately. Correct answer:",
    hu: "Sajnálatos módon. Helyes válasz:",
  },
  next: {
    pl: "Następne",
    cs: "Další",
    sk: "Ďalšie",
    en: "Next",
    hu: "Következő",
  },
  check: {
    pl: "Sprawdź",
    cs: "Šek",
    sk: "Skontrolujte",
    en: "Check",
    hu: "Jelölje be",
  },
  typeExpression: {
    pl: "wpisz słowo",
    cs: "napište slovo",
    sk: "zadajte slovo",
    en: "type expression",
    hu: "típusú kifejezés",
  },
  changePassword: {
    pl: "Zmień hasło",
    cs: "Změnit heslo",
    sk: "Zmeniť heslo",
    en: "Change password",
    hu: "Jelszó módosítása",
  },
  addLanguage: {
    pl: "Dodaj język",
    cs: "Přidat jazyk",
    sk: "Pridanie jazyka",
    en: "Add language",
    hu: "Nyelv hozzáadása",
  },
  addLanguageModalUpperTextFirst: {
    pl: "Chcesz uczyć się kolejnego języka?",
    cs: "Chcete se naučit další jazyk?",
    sk: "Chcete sa naučiť ďalší jazyk?",
    en: "Want to learn another language?",
    hu: "Szeretne egy másik nyelvet tanulni?",
  },
  addLanguageModalUpperTextSecond: {
    pl: "Wpisz kod aby aktywować nowy język:",
    cs: "Zadejte kód pro aktivaci nového jazyka:",
    sk: "Zadaním kódu aktivujete nový jazyk:",
    en: "Enter the code to activate the new language:",
    hu: "Írja be a kódot az új nyelv aktiválásához:",
  },
  addLanguageModalLowerTextFirst: {
    pl: "Nie masz kodu?",
    cs: "Nemáte kód?",
    sk: "Nemáte kód?",
    en: "You don't have a code?",
    hu: "Nincs kódja?",
  },
  addLanguageModalLowerTextSecond: {
    pl: "Napisz do nas na adres: bok@eeagency.eu",
    cs: "Napište nám na adresu: kzs@eeagency.eu",
    sk: "Napíšte nám na: ksz@eeagency.eu",
    en: "Write to us at: contact@eeagency.eu",
    hu: "Írjon nekünk a következő címre: contact@eeagency.eu",
  },
  addLanguageModalLowerTextThird: {
    pl: "Odezwiemy się do Ciebie z atrakcyjną ofertą nauki kolejnego języka w promocyjnej cenie.",
    cs: "Ozveme se vám s atraktivní nabídkou na výuku dalšího jazyka za zvýhodněnou cenu.",
    sk: "Ozveme sa vám s atraktívnou ponukou na výučbu ďalšieho jazyka za zvýhodnenú cenu.",
    en: "We will get back to you with an attractive offer to learn another language at a discounted price.",
    hu: "Vonzó ajánlattal jelentkezünk, hogy kedvezményes áron tanulhasson másik nyelvet.",
  },
  addLanguageModalButtonText: {
    pl: "Aktywuj",
    cs: "Aktivace",
    sk: "Aktivácia",
    en: "Activate",
    hu: "Aktiválni",
  },
  makeRateText: {
    pl: "Oceń kartę:",
    cs: "Hodnotící karta:",
    sk: "Hodnotiaca karta:",
    en: "Rate Card:",
    hu: "Díjtáblázat:",
  },
  translateBoxHeading: {
    pl: "Chcesz sobie przypomnieć znaczenie słowa?",
    cs: "Chcete si zapamatovat význam slova?",
    sk: "Chcete si zapamätať význam slova?",
    en: "Want to remember the meaning of the word?",
    hu: "Szeretne megjegyezni a szó jelentését?",
  },
  translateBoxButtonText: {
    pl: "Tłumacz",
    cs: "Přeložit",
    sk: "Preložiť",
    en: "Translate",
    hu: "Lefordítani",
  },
  translateBoxExpressionText: {
    pl: "Tłumaczenie",
    cs: "Překlad",
    sk: "Preklad",
    en: "Translation",
    hu: "Fordítás",
  },
  translateBoxSentenceText: {
    pl: "Przykładowe zdanie",
    cs: "Ukázka věty",
    sk: "Ukážková veta",
    en: "Example sentence",
    hu: "Példamondat",
  },
  progressModalBookmarkText: {
    pl: "Twoja aktywność",
    cs: "Vaše činnost",
    sk: "Vaša aktivita",
    en: "Your activity",
    hu: "Az Ön tevékenysége",
  },
  progressModalTitle: {
    pl: "Podsumowanie Twojej aktywności w trybie lekcji.",
    cs: "Shrnutí vaší činnosti v režimu lekce.",
    sk: "Shrnutí vaší činnosti v režimu lekce.",
    en: "A summary of your lesson mode activity.",
    hu: "A lecke módban végzett tevékenységének összefoglalása.",
  },
  progressModalNumberHeader: {
    pl: "poznanych dziś wyrażeń",
    cs: "výrazy, které jste se dnes naučili",
    sk: "výrazy, ktoré ste sa dnes naučili",
    en: "expressions learned today",
    hu: "ma tanult kifejezések",
  },
  progressModalNumberHeader2: {
    pl: "dni nauki z rzędu",
    cs: "dny učení v řadě",
    sk: "dni učenia v rade",
    en: "study days in a row",
    hu: "tanulási napok egymás után",
  },
  progressModalNumberText: {
    pl: "Osiągnij biegłość językową. Im więcej słów opanujesz, tym łatwiej będzie Ci się porozumieć.",
    cs: "Dosáhnout jazykových znalostí. Čím více slov ovládáte, tím snadněji se vám bude komunikovat.",
    sk: "Dosiahnutie jazykových znalostí. Čím viac slov poznáte, tým ľahšie sa vám bude komunikovať.",
    en: "Achieve language proficiency. The more words you master, the easier it will be to communicate.",
    hu: "Nyelvtudást érhet el. Minél több szót sajátít el, annál könnyebb lesz a kommunikáció.",
  },
  progressModalNumberText2: {
    pl: "Staraj się uczyć codziennie chociaż przez chwilę. To usprawnia umysł i ułatwia zapamiętywanie.",
    cs: "Snažte se studovat každý den alespoň chvíli. Zlepšuje vaši mysl a usnadňuje zapamatování.",
    sk: "Snažte sa študovať každý deň aspoň minútu. Zlepšuje vašu myseľ a uľahčuje zapamätanie.",
    en: "Try to study every day for at least a little while. It streamlines your mind and makes it easier to remember.",
    hu: "Próbáljon meg minden nap legalább egy ideig tanulni. Leegyszerűsíti az elmét, és könnyebben emlékszik majd rá.",
  },
  progressModalChartTitle: {
    pl: "Wykres Twoich postępów",
    cs: "Graf vašeho pokroku",
    sk: "Graf vášho pokroku",
    en: "Charting your progress",
    hu: "A fejlődés feltérképezése",
  },
  progressModalChartText: {
    pl: "Spójrz na wykres, aby sprawdzić swoje postępy z ostatnich 7 dni. Utrzymując wysoki wynik, uczysz się wydajniej.",
    cs: "Podívejte se na graf a zkontrolujte svůj pokrok za posledních 7 dní. Udržováním vysokého skóre se učíte efektivněji.",
    sk: "Pozrite sa na graf a skontrolujte svoj pokrok za posledných 7 dní. Udržiavaním vysokého skóre sa učíte efektívnejšie.",
    en: "Look at the chart to check your progress over the last 7 days. By keeping your score high, you learn more efficiently.",
    hu: "Tekintse meg a diagramot, hogy ellenőrizze az elmúlt 7 napban elért fejlődését. Ha magas pontszámot tart, hatékonyabban tanul.",
  },
  progressModalFooter: {
    pl: "(Tryb powtórki nie wlicza się do Twojej aktywności.)",
    cs: "(Nezapomeňte, že režim nepřetržitého opakování se do těchto statistik nezapočítává).",
    sk: "(Režim opakování se do aktivity nezapočítává).",
    en: "(Repeat mode does not count as your activity.)",
    hu: "(Az ismétlési mód nem számít az Ön tevékenységének.)",
  },
  progressModalMondayShort: {
    pl: "pn.",
    cs: "Po.",
    sk: "Po,",
    en: "Mon.",
    hu: "Hé",
  },
  progressModalTuesdayShort: {
    pl: "wt.",
    cs: "Út.",
    sk: "Ut.",
    en: "Tue.",
    hu: "Ke",
  },
  progressModalWednesdayShort: {
    pl: "śr.",
    cs: "St.",
    sk: "St.",
    en: "Wed.",
    hu: "Sze",
  },
  progressModalThursdayShort: {
    pl: "czw.",
    cs: "Čt.",
    sk: "Stv.",
    en: "Thu.",
    hu: "Cs",
  },
  progressModalFridayShort: {
    pl: "pt.",
    cs: "Pá.",
    sk: "Pi.",
    en: "Fri.",
    hu: "Pé",
  },
  progressModalSaturdayShort: {
    pl: "sob.",
    cs: "So.",
    sk: "So.",
    en: "Sat.",
    hu: "Szo",
  },
  progressModalSundayShort: {
    pl: "ndz.",
    cs: "Ne.",
    sk: "Ne.",
    en: "Sun",
    hu: "Vas",
  },
  testPageTitle: {
    pl: "Test znajomości języka angielskiego",
    cs: "Test z anglického jazyka",
    sk: "Test z anglického jazyka",
    en: "English language proficiency test",
    hu: "Angol nyelvvizsga",
    ro: "",
  },
  testPageDesc: {
    pl: "Test językowy sprawdzi twoją znajomość języka, w tym rozumienie tekstu pisanego, oraz inteligencję językową.",
    cs: "Jazykový test prověří vaše jazykové dovednosti, včetně porozumění textu a jazykové inteligence.",
    sk: "Jazykový test preverí vaše jazykové schopnosti vrátane čítania s porozumením a jazykovej inteligencie.",
    en: "The language test will test your language skills, including reading comprehension, and language intelligence.",
    hu: "A nyelvvizsga próbára teszi nyelvtudását, beleértve a szövegértést és a nyelvi intelligenciát.",
    ro: "",
  },
  testPageRulesTitle: {
    pl: "Zasady uzyskania certyfikatu",
    cs: "Pravidla pro získání certifikátu",
    sk: "Pravidlá na získanie osvedčenia",
    en: "Rules of obtaining the certificate",
    hu: "A tanúsítvány megszerzésének szabályai",
    ro: "",
  },
  testPageRules1: {
    pl: "Test składa się z 20 pytań.",
    cs: "Test se skládá z 20 otázek.",
    sk: "Test pozostáva z 20 otázok.",
    en: "The test consists of 20 questions.",
    hu: "A teszt 20 kérdésből áll.",
    ro: "",
  },
  testPageRules2: {
    pl: "Pytania stworzono na podstawie materiałów z wszystkich 3 poziomów nauki, dostępnych na platformie Eureka.",
    cs: "Otázky byly vytvořeny na základě materiálů ze všech tří úrovní vzdělávání dostupných na platformě Eureka.",
    sk: "Otázky sú založené na materiáloch zo všetkých troch úrovní vzdelávania dostupných na platforme Eureka.",
    en: "The questions were created based on material from all 3 learning levels available on the Eureka platform.",
    hu: "A kérdések az Eureka platformon elérhető mind a 3 tanulási szint anyaga alapján készültek.",
    ro: "",
  },
  testPageRules3: {
    pl: "Każda poprawna odpowiedź to 1 punkt.",
    cs: "Každá správná odpověď znamená 1 bod.",
    sk: "Každá správna odpoveď znamená 1 bod.",
    en: "Each correct answer is 1 point.",
    hu: "Minden helyes válasz 1 pont.",
    ro: "",
  },
  testPageRules4: {
    pl: "Każda błędna odpowiedź to 0 punktów.",
    cs: "Za každou nesprávnou odpověď je 0 bodů.",
    sk: "Za každú nesprávnu odpoveď získate 0 bodov.",
    en: "Each wrong answer is 0 points.",
    hu: "Minden rossz válasz 0 pont.",
    ro: "",
  },
  testPageRules5: {
    pl: "Uzyskanie certyfikatu następuje po zdobyciu 100%.",
    cs: "Certifikát získáte po dosažení 100 % bodů.",
    sk: "Certifikát sa udeľuje po dosiahnutí 100 % úspešnosti.",
    en: "Certification is earned by earning 100%.",
    hu: "A bizonyítványt 100% megszerzésével lehet megszerezni.",
    ro: "",
  },
  testPageRules6: {
    pl: "Do testu należy przystąpić bez dodatkowych pomocy językowych i bez udziału osób trzecich.",
    cs: "Test by měl být prováděn bez dalších jazykových pomůcek a bez účasti třetích stran.",
    sk: "Test by sa mal vykonať bez akýchkoľvek ďalších jazykových pomôcok a bez účasti tretích strán.",
    en: "The test must be taken without additional language aids and without any third party.",
    hu: "A tesztet további nyelvi segédanyagok és harmadik fél nélkül kell letenni.",
    ro: "",
  },
  testPageRules7: {
    pl: "W przypadku nieuzyskania wystarczającej liczby punktów, możliwość każdego kolejnego wypełnienia testu, jest możliwa po 24h.",
    cs: "Pokud není získán dostatečný počet bodů, je možnost dalšího dokončení testu možná až po 24 hodinách.",
    sk: "Ak sa nezíska dostatočný počet bodov, ďalší test sa môže dokončiť po 24 hodinách.",
    en: "In case of not getting enough points, each next test can be completed after 24h.",
    hu: "Ha nincs elég pont, minden következő teszt 24 óra elteltével teljesíthető.",
    ro: "",
  },
  testPageRules8: {
    pl: "W przypadku uzyskania certyfikatu, zostanie on przesłany uczestnikowi na adres mailowy, podany w procesie rejestracji.",
    cs: "Pokud bude certifikát získán, bude účastníkovi zaslán na e-mailovou adresu uvedenou při registraci.",
    sk: "V prípade získania certifikátu bude účastníkovi zaslaný na e-mailovú adresu uvedenú počas registrácie.",
    en: "If a certificate is obtained, it will be sent to the participant at the email address provided during the registration process.",
    hu: "A tanúsítvány megszerzése esetén azt elküldjük a résztvevőnek a regisztráció során megadott e-mail címre.",
    ro: "",
  },
  testPageRules9: {
    pl: "Wyniki testu zostaną ujawnione uczestnikowi testu, niezwłocznie po jego zakończeniu.",
    cs: "Výsledky testu budou účastníkovi testu sděleny, jakmile bude test dokončen.",
    sk: "Výsledky testu sa účastníkovi oznámia bezprostredne po jeho ukončení.",
    en: "The test results will be disclosed to the test participant as soon as the test is completed.",
    hu: "A teszt eredményeit a teszt befejezése után azonnal közöljük a résztvevővel.",
    ro: "",
  },
  testPageRules10: {
    pl: "Certyfikat, oraz każda kolejna próba uzyskania certyfikat, są bezpłatne dla wszystkich użytkowników platformy językowej Eureka.",
    cs: "Certifikát a případný následný pokus o certifikaci je pro všechny uživatele jazykové platformy Eureka zdarma.",
    sk: "Certifikát a každý ďalší pokus o jeho získanie sú pre všetkých používateľov jazykovej platformy Eureka bezplatné.",
    en: "The certificate, and each subsequent attempt to obtain the certificate, are free of charge for all users of the Eureka language platform.",
    hu: "A tanúsítvány és minden további kísérlet a tanúsítvány megszerzésére az Eureka nyelvi platform minden felhasználója számára ingyenes.",
    ro: "",
  },
  testPageRules11: {
    pl: "O ewentualnych komplikacjach związanych z testem, uczestnik może poinformować biuro obsługi drogą mailową na adres email@email.com",
    cs: "O případných komplikacích spojených s testem může účastník informovat servisní kancelář e-mailem na adresu: kzs@eeagency.eu.",
    sk: "Ak sa vyskytnú akékoľvek komplikácie súvisiace s testom, účastník môže informovať podpornú kanceláriu e-mailom na adrese email@email.com.",
    en: "In case of any complications related to the test, the participant may inform the service office by e-mail to email@email.com.",
    hu: "A teszttel kapcsolatos bármilyen komplikáció esetén a résztvevő az email@email.com e-mail címen értesítheti a szervizt.",
    ro: "",
  },
  testPageStatement: {
    pl: "Oświadczam, że zasady uzyskania certyfikatu są dla mnie jasne i zrozumiałe.",
    cs: "Prohlašuji, že pravidla pro získání osvědčení jsou mi jasná a srozumitelná.",
    sk: "Vyhlasujem, že pravidlá na získanie osvedčenia sú pre mňa jasné a zrozumiteľné.",
    en: "I certify that the rules for obtaining certification are clear and understandable to me.",
    hu: "Tanúsítom, hogy a minősítés megszerzésének szabályai világosak és számomra érthetőek.",
    ro: "",
  },
  testPageName: {
    pl: "imię",
    cs: "Jméno",
    sk: "meno",
    en: "name",
    hu: "név",
    ro: "",
  },
  testPageSurname: {
    pl: "nazwisko",
    cs: "Příjmení",
    sk: "priezvisko",
    en: "surname",
    hu: "vezetéknév",
    ro: "",
  },
  testPagePersonalDataDesc: {
    pl: "(Dane osobowe zostaną wykorzystane jedynie w procesie przygotowania certyfikatu ukończenia kursu)",
    cs: "(Osobní údaje budou použity pouze při přípravě osvědčení o absolvování kurzu)",
    sk: "(Osobné údaje sa použijú len na prípravu osvedčenia o absolvovaní kurzu)",
    en: "(Personal data will be used only in the process of preparing the certificate of completion of the course)",
    hu: "(A személyes adatokat csak a tanfolyam elvégzését igazoló okirat elkészítése során használjuk fel)",
    ro: "",
  },
  testPageTestStart: {
    pl: "Rozpocznij test",
    cs: "Spuštění testu",
    sk: "Spustenie testu",
    en: "Begin the test",
    hu: "Kezdje el a tesztet",
    ro: "",
  },
  testPageTestEnd: {
    pl: "Zakończ test",
    cs: "Dokončení testu",
    sk: "Ukončite test",
    en: "Complete the test",
    hu: "Töltse ki a tesztet",
    ro: "",
  },
  testPageScoreTitle: {
    pl: "Wynik testu:",
    cs: "Výsledek testu:",
    sk: "Výsledok testu:",
    en: "Test result:",
    hu: "Teszteredmény:",
    ro: "",
  },
  testPageScore: {
    pl: "Udało Ci się uzyskać:",
    cs: "Podařilo se vám to:",
    sk: "Úspešne ste získali:",
    en: "You were able to get:",
    hu: "Sikerült megszereznie:",
    ro: "",
  },
  testPageCongratulations: {
    pl: "Gratulacje!",
    cs: "Gratulujeme!",
    sk: "Gratulujeme!",
    en: "Congratulations!",
    hu: "Gratulálunk!",
    ro: "",
  },
  testPageCongratulations2: {
    pl: "Udało Ci się uzyskać certyfikat ukończenia kursu języka angielskiego na Platformie Eureka.",
    cs: "Úspěšně jste získali certifikát o absolvování kurzu angličtiny na platformě Eureka.",
    sk: "Úspešne ste ukončili kurz angličtiny na platforme Eureka.",
    en: "You have successfully achieved a certificate of completion for your English course on the Eureka Platform.",
    hu: "Sikeresen megszerezte az Eureka Platform angol nyelvtanfolyamának elvégzéséről szóló bizonyítványt.",
    ro: "",
  },
  testPageCongratulations3: {
    pl: "W celu otrzymania certyfikatu zgloś się na: bok@eeagency.eu",
    cs: "Chcete-li certifikát získat, kontaktujte prosím: kzs@eeagency.eu",
    sk: "Pre získanie certifikátu kontaktujte: kzs@eeagency.eu",
    en: "In order to receive the certificate, please contact: kzs@eeagency.eu",
    hu: "A tanúsítvány átvételéhez kérjük, vegye fel a kapcsolatot: kzs@eeagency.eu",
    ro: "",
  },
  testPageFail: {
    pl: "Spróbuj ponownie jutro",
    cs: "Zkuste to zítra znovu",
    sk: "Skúste to zajtra znova",
    en: "Try again tomorrow",
    hu: "Holnap próbálja újra",
    ro: "",
  },
  testPageFail2: {
    pl: "Niestety, nie udało Ci się uzyskać certyfikatu",
    cs: "Certifikát se vám bohužel nepodařilo získat.",
    sk: "Bohužiaľ sa vám nepodarilo získať certifikát",
    en: "Unfortunately, you were unable to obtain certification",
    hu: "Sajnos nem sikerült megszereznie a tanúsítványt",
    ro: "",
  },
  testPageToPlatformButtonText: {
    pl: "Powrót do Platformy",
    cs: "Zpět na platformu",
    sk: "Späť na Platformu",
    en: "Back to the Platform",
    hu: "Vissza a platformra",
    ro: "",
  },
  notificationHeader: {
    pl: "Co nowego",
    cs: "Co je nového",
    sk: "Čo nové",
    en: "",
    hu: "",
  },
  notificationDescription: {
    pl: "Wiosna to czas zmian na Platformie Eureka. Aby nauka przebiegała Ci jeszcze przyjemniej i efektywniej, wprowadziliśmy nowe funkcje.",
    cs: "Jaro je na platformě Eureka obdobím změn. Přidali jsme nové funkce, které vám zpříjemní a zefektivní učení.",
    sk: "Jar je na platforme Eureka obdobím zmien. Pridali sme nové funkcie, aby bolo vaše učenie ešte príjemnejšie a efektívnejšie.",
    en: "",
    hu: "",
  },
  notificationList1Header: {
    pl: "Twoja aktywność",
    cs: "Vaše činnost",
    sk: "Vaša činnosť",
    en: "",
    hu: "",
  },
  notificationList1Text1: {
    pl: "Chcesz wiedzieć, jak przebiega proces Twojej nauki? Zebraliśmy najważniejsze informacje o Twoich ostatnich postępach w jednym miejscu.",
    cs: "Chcete vědět, jak probíhá váš proces učení? Na jednom místě jsme shromáždili nejdůležitější informace o vašem nedávném pokroku.",
    sk: "Chcete vedieť, ako prebieha váš proces učenia? Na jednom mieste sme zhromaždili najdôležitejšie informácie o vašom nedávnom pokroku.",
    en: "",
    hu: "",
  },
  notificationList1Text2: {
    pl: "Aby sprawdzić swoją aktywność, wciśnij przycisk. Tę opcję znajdziesz w prawym brzegu ekranu. (na telefonie znajdziesz tę opcję w ikonie użytkownika)",
    cs: "Chcete-li zkontrolovat svou aktivitu, stiskněte tlačítko . Tuto možnost najdete na pravém okraji obrazovky. (V telefonu najdete tuto možnost v ikoně uživatele).",
    sk: "Ak chcete skontrolovať svoju aktivitu, stlačte tlačidlo . Túto možnosť nájdete na pravom okraji obrazovky. (V smartfóne nájdete túto možnosť v ikone používateľa)",
    en: "",
    hu: "",
  },
  notificationList2Header: {
    pl: "Wyszukaj słowo",
    cs: "Hledání slov",
    sk: "Vyhľadávanie slov",
    en: "",
    hu: "",
  },
  notificationList2Text1: {
    pl: "Chcesz szybko przypomnieć sobie znaczenie jakiegoś słowa? Teraz jest to możliwe! Wpisz nurtujące Cię słowo, a otrzymasz odpowiedź w Twoim języku, lub tym którego się uczysz.",
    cs: "Chcete si rychle vybavit význam slova? Nyní je to možné! Zadejte slovo a dostanete odpověď ve svém jazyce nebo v jazyce, který se učíte.",
    sk: "Chcete si rýchlo vybaviť význam slova? Teraz je to možné! Zadajte slovo a dostanete odpoveď vo svojom jazyku alebo v jazyku, ktorý sa práve učíte.",
    en: "",
    hu: "",
  },
  notificationList2Text2: {
    pl: "Aby wyszukać słowo, wciśnij przycisk lupy i wpisz słowo. Tę opcję znajdziesz w prawym górnym rogu strony.",
    cs: "Chcete-li vyhledat slovo, stiskněte tlačítko lupy a zadejte slovo. Tuto možnost najdete v pravém horním rohu stránky.",
    sk: "Ak chcete vyhľadať slovo, stlačte tlačidlo lupy a zadajte slovo. Túto možnosť nájdete v pravom hornom rohu stránky.",
    en: "",
    hu: "",
  },
  notificationList3Header: {
    pl: "Oceń kartę nauki",
    cs: "Hodnocení studijní karty",
    sk: "Hodnotenie študijnej karty",
    en: "",
    hu: "",
  },
  notificationList3Text1: {
    pl: "Obrazek albo zdanie na karcie nauki wyjątkowo Ci się podoba? A może nie do końca do Ciebie przemawia? Daj nam o tym znać za pomocą kciuka.",
    cs: "Líbí se vám obrázek nebo věta na studijní kartě obzvlášť dobře? Nebo se vám možná příliš nezamlouvá? Dejte nám vědět.",
    sk: "Páči sa vám obrázok alebo veta na študijnej karte? Alebo sa vám možno celkom nepozdáva? Dajte nám vedieť.",
    en: "",
    hu: "",
  },
  notificationList3Text2: {
    pl: "Aby ocenić kartę, wciśnij przycisk kciuka w górę lub w dół. Tę opcję znajdziesz pod kartą nauki.",
    cs: "Chcete-li kartu ohodnotit, stiskněte tlačítko s palcem nahoru nebo dolů. Tuto možnost najdete pod kartou učení.",
    sk: "Ak chcete kartu ohodnotiť, stlačte tlačidlo s palcom nahor alebo nadol. Túto možnosť nájdete pod učebnou kartou.",
    en: "",
    hu: "",
  },
};
