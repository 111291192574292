import { useEffect, useState } from "react";
import "./EditFlashcard.css";
import api from '../../api/api'
import { Spinner } from "react-bootstrap";

function EditFlashcard({ flashcardData, afterUpdate, closeModal, lessons }) {
  const [data, setData] = useState({});
  const [flashcard, setFlashcard] = useState();
  const [allLessons, setAllLessons] = useState(lessons.sort((a,b)=>a.number-b.number));
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setFlashcard(flashcardData)
    console.log(flashcardData)

    setData({
      contentToExpression: flashcardData.contentTo.expression,
      contentFromExpression: flashcardData.contentFrom.expression,
      contentToSentence: flashcardData.contentTo.sentence,
      contentFromSentence: flashcardData.contentFrom.sentence,
      comment: flashcardData.comment,
      lessonId: flashcardData.link.linkLesson.id,
      imageBase64: flashcardData.link.image.base64,
      toProcess: flashcardData.toProcess,
      version: flashcardData.link.version,
      hidden: flashcardData.link.hidden,
    });
  }, []);

  const onInputChange = (value, key) => {
      setData({
          ...data,
          [key]: value
      })

  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const onFileChange = async (event) => {
    const file = event.target.files[0];
    const supportedTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (supportedTypes.indexOf(file.type) === -1) {
      alert("Bad image type! Try with: jpg, jpeg or png");
    }

    const base64 = await toBase64(file);

    setData({
      ...data,
      imageBase64: base64,
    });
  };


  const onSave = async () => {
      setIsLoading(true)
      await api.post(`/flashcard/${flashcardData.id}`, data)
      setIsLoading(false)
      await afterUpdate()
  }

  const onChangeImage = async () => {
    const imageId = flashcard.link.image.id
    const lessonId = flashcard.link.linkLesson.id
    await api.post(`/lesson/${lessonId}/change-image/${imageId}`)
    await afterUpdate()
}

  return (
      <>
      <div className="flashcard-edit-modal-hero" onClick={closeModal}>
    </div>

    <div className="flashcard-edit-modal">
      <img className="flashcard-edit-img" src={data.imageBase64} />
      <button onClick={onChangeImage} className="flashcard-edit-button">Ustaw zdjęcie lekcji</button>

      <input type="file" onChange={onFileChange} />
      <label className="flashcard-edit-label">Expression native</label>
      <input
        className="flashcard-edit-input"
        value={data.contentFromExpression}
        onChange={(ev) => onInputChange(ev.target.value, 'contentFromExpression')}
      />
      <label className="flashcard-edit-label">Sentence native</label>

      <textarea
        className="flashcard-edit-input"
        value={data.contentFromSentence}
        onChange={(ev) => onInputChange(ev.target.value, 'contentFromSentence')}

      />
      <label className="flashcard-edit-label">Expression foreign</label>

      <input
        className="flashcard-edit-input"
        value={data.contentToExpression}
        onChange={(ev) => onInputChange(ev.target.value, 'contentToExpression')}

      />
      <label className="flashcard-edit-label">Sentence foreign</label>

      <textarea
        className="flashcard-edit-input"
        value={data.contentToSentence}
        onChange={(ev) => onInputChange(ev.target.value, 'contentToSentence')}

      />

<label className="flashcard-edit-label">Lesson</label>

      <select className="flashcard-edit-input" value={data.lessonId}
        onChange={(ev) => onInputChange(parseInt(ev.target.value), 'lessonId')}
        >
        {allLessons.map(lesson => (<option value={lesson.id}>
          {lesson.number}
        </option>))}
      </select>

      <label className="flashcard-edit-label">Version</label>
      <input
        className="flashcard-edit-input"
        value={data.version}
        type="number"
        onChange={(ev) => onInputChange(parseInt(ev.target.value), 'version')}

      />
      <label className="flashcard-edit-label">Hidden</label>

            <input
        className="flashcard-edit-input"
        checked={data.hidden}
        onChange={(ev) => onInputChange(ev.target.checked, 'hidden')}

        type="checkbox"
      />

<label className="flashcard-edit-label">Comment</label>

<textarea
  className="flashcard-edit-input"
  value={data.comment}
  onChange={(ev) => onInputChange(ev.target.value, 'comment')}

/>
        <button onClick={onSave} className="flashcard-edit-button">Zapisz</button>
        {isLoading && <Spinner animation="border"/>}
    </div>
    </>
  );
}

export default EditFlashcard;
