import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { useHistory } from "react-router";
import ColouredReturnButton from "../../components/Buttons/ColouredReturnButton/ColouredReturnButton";
import PageStructure from "../../components/PageStructure/PageStructure";
import Store from "../../Store";
import "./GrammarPage.css";

const responsive = {
  0: { items: 1 },
};

const items = [
  <div className="grammar-page__content">
    <p className="grammar-page__content-header">Nouns - Podstatná jména</p>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">Jednotné a množné číslo:</p>
      <p className="grammar-page__content-row">
        cat - cat<span className="grammar-page__span-bold">s</span>
      </p>
      <p className="grammar-page__content-row">
        table - table<span className="grammar-page__span-bold">s</span>
      </p>
      <p className="grammar-page__content-row">
        photo - photo<span className="grammar-page__span-bold">s</span>
      </p>
      <p className="grammar-page__content-row">
        hero - hero<span className="grammar-page__span-bold">es</span>
      </p>
      <p className="grammar-page__content-row">
        bus - bus<span className="grammar-page__span-bold">es</span>
      </p>
      <p className="grammar-page__content-row">
        famil<span className="grammar-page__span-bold">y</span> - famil
        <span className="grammar-page__span-bold">ies</span>
      </p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">Nepravidelná:</p>
      <p className="grammar-page__content-row">
        child - child
        <span className="grammar-page__span-bold">ren</span>
      </p>
      <p className="grammar-page__content-row">
        f<span className="grammar-page__span-bold">oot</span> - f
        <span className="grammar-page__span-bold">eet</span>
      </p>
      <p className="grammar-page__content-row">
        m<span className="grammar-page__span-bold">a</span>n - m
        <span className="grammar-page__span-bold">e</span>n
      </p>
      <p className="grammar-page__content-row">
        m<span className="grammar-page__span-bold">ous</span>e - m
        <span className="grammar-page__span-bold">ic</span>e
      </p>
      <p className="grammar-page__content-row">
        t<span className="grammar-page__span-bold">oo</span>th - t
        <span className="grammar-page__span-bold">ee</span>th
      </p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">
        Nepočitatelná (látky a abstraktní pojmy):
      </p>
      <p className="grammar-page__content-row">water, food, music, success</p>
    </div>
  </div>,
  <div className="grammar-page__content">
    <p className="grammar-page__content-header">Adjectives - Přídavná jména</p>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">
        nice - nice<span className="grammar-page__span-bold">r</span> - the nice
        <span className="grammar-page__span-bold">st</span>
      </p>
      <p className="grammar-page__content-row">
        important - <span className="grammar-page__span-bold">more</span>{" "}
        important - <span className="grammar-page__span-bold">the most</span>{" "}
        important
      </p>
      <p className="grammar-page__content-row">
        beautiful - <span className="grammar-page__span-bold">more</span>{" "}
        beautiful - <span className="grammar-page__span-bold">the most</span>{" "}
        beautiful
      </p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">
        <span className="grammar-page__span-bold">Irregular adjectives:</span>
      </p>
      <p className="grammar-page__content-row">bad/evil - worse - worst</p>
      <p className="grammar-page__content-row">far - further - furthest</p>
      <p className="grammar-page__content-row">good - better - best</p>
      <p className="grammar-page__content-row">many/much - more - most</p>
      <p className="grammar-page__content-row">near - nearer - nearest</p>
      <p className="grammar-page__content-row">old - elder - eldest</p>
    </div>
  </div>,
  <div className="grammar-page__content">
    <p className="grammar-page__content-header">Příslovce - Adverbs</p>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">
        near - near<span className="grammar-page__span-bold">er</span> -
        <span className="grammar-page__span-bold">the</span> near
        <span className="grammar-page__span-bold">est</span>
      </p>
      <p className="grammar-page__content-row">
        quickly - <span className="grammar-page__span-bold">more</span> quickly
        - <span className="grammar-page__span-bold">the most</span> quickly
      </p>
    </div>
  </div>,
  <div className="grammar-page__content">
    <p className="grammar-page__content-header">Pronouns - Zájmena</p>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">I - me - my - mine - myself</p>
      <p className="grammar-page__content-row">
        you - you - your - yours - yourself
      </p>
      <p className="grammar-page__content-row">he - him - his - his - himself</p>
      <p className="grammar-page__content-row">
        she - her - her - hers - herself
      </p>
      <p className="grammar-page__content-row">it - it - its - its - itself</p>
      <p className="grammar-page__content-row">
        we - us - our - ours - ourselves
      </p>
      <p className="grammar-page__content-row">
        you - you - your - yours - yourselves
      </p>
      <p className="grammar-page__content-row">
        they - them - their - theirs - themselves
      </p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">
        <span className="grammar-page__span-bold">Neurčitá zájmena:</span>
      </p>
      <p className="grammar-page__content-row">a couple - pár</p>
      <p className="grammar-page__content-row">
        a little, a few - trochu, pár, několik
      </p>
      <p className="grammar-page__content-row">
        a lot, much, many - hodně, mnoho
      </p>
      <p className="grammar-page__content-row">some, any - nějaký, jakýsi</p>
      <p className="grammar-page__content-row">no, none - žádný</p>
      <p className="grammar-page__content-row">one - jeden</p>
      <p className="grammar-page__content-row">all - všechny</p>
      <p className="grammar-page__content-row">every, each - každý</p>
      <p className="grammar-page__content-row">both - oba</p>
      <p className="grammar-page__content-row">plenty of - hodně</p>
      <p className="grammar-page__content-row">enough - dost</p>
      <p className="grammar-page__content-row">other, another - jiný, další</p>
    </div>
  </div>,
  <div className="grammar-page__content">
    <p className="grammar-page__content-header">Příslovce - Adverbs</p>
    <div className="grammar-page__content-inner-section-flex">
      <div className="grammar-page__content-inner-section">
        <p className="grammar-page__content-row">above - nad</p>
        <p className="grammar-page__content-row">after - po</p>
        <p className="grammar-page__content-row">along - podél</p>
        <p className="grammar-page__content-row">among - mezi (několika)</p>
        <p className="grammar-page__content-row">around - kolem</p>
        <p className="grammar-page__content-row">at - u</p>
        <p className="grammar-page__content-row">before - před (čas)</p>
        <p className="grammar-page__content-row">below - pod</p>
        <p className="grammar-page__content-row">between - mezi (dvěma)</p>
        <p className="grammar-page__content-row">beyond - za</p>
        <p className="grammar-page__content-row">by - poblíž</p>
        <p className="grammar-page__content-row">down - dolů</p>
      </div>
      <div className="grammar-page__content-inner-section">
        <p className="grammar-page__content-row">from - z</p>
        <p className="grammar-page__content-row">in front of - před</p>
        <p className="grammar-page__content-row">in - v</p>
        <p className="grammar-page__content-row">into - do, dovnitř</p>
        <p className="grammar-page__content-row">left - doleva</p>
        <p className="grammar-page__content-row">on - na</p>
        <p className="grammar-page__content-row">right - doprava</p>
        <p className="grammar-page__content-row">through - skrz</p>
        <p className="grammar-page__content-row">to - do</p>
        <p className="grammar-page__content-row">under - pod</p>
        <p className="grammar-page__content-row">up - nahoru</p>
      </div>
    </div>
  </div>,
  <div className="grammar-page__content">
    <p className="grammar-page__content-header">Konjukce - Conjunctions</p>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">and - a</p>
      <p className="grammar-page__content-row">as well as - i, stejně jako</p>
      <p className="grammar-page__content-row">although - ačkoliv</p>
      <p className="grammar-page__content-row">still - avšak, přesto</p>
      <p className="grammar-page__content-row">however - avšak</p>
      <p className="grammar-page__content-row">or - nebo</p>
      <p className="grammar-page__content-row">either .. or - buď .. nebo</p>
      <p className="grammar-page__content-row">so, thus - proto, tak</p>
    </div>
  </div>,
  <div className="grammar-page__content">
    <p className="grammar-page__content-header">Slovesa - Verbs</p>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">Základní slovesa:</p>
      <p className="grammar-page__content-row">
        be, have, do, go, can help, speak, read
      </p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">sloveso BE:</p>
      <p className="grammar-page__content-row">I am, you are, he/she/it is</p>
      <p className="grammar-page__content-row">we are, you are, they are</p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">sloveso HAVE:</p>
      <p className="grammar-page__content-row">I have, you have, he/she/it has</p>
      <p className="grammar-page__content-row">we have, you have, they have</p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">Tvoření otázek:</p>
      <p className="grammar-page__content-row">I have - Do I have?</p>
      <p className="grammar-page__content-row">He has - Does he have?</p>
      <p className="grammar-page__content-row">I am - Am I?</p>
      <p className="grammar-page__content-row">He is - Is he?</p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">Zápor:</p>
      <p className="grammar-page__content-row">
        I have - I do not have - I don't have
      </p>
      <p className="grammar-page__content-row">
        He has - he doesn't have - he hasn't
      </p>
      <p className="grammar-page__content-row">I am - I am not</p>
      <p className="grammar-page__content-row">He is - He is not - He isn't</p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">Přítomný čas</p>
      <p className="grammar-page__content-row">
        I read - Do I read? - I do not read
      </p>
      <p className="grammar-page__content-row">
        I am reading - Am I reading? - I am not reading
      </p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">Minulý čas</p>
      <p className="grammar-page__content-row">
        I answered - Did I answer? - I did not answer
      </p>
      <p className="grammar-page__content-row">
        I was answering - Was I answering? - I was not answering
      </p>
    </div>
    <div className="grammar-page__content-inner-section">
      <p className="grammar-page__content-row">Budoucí čas</p>
      <p className="grammar-page__content-row">
        I will go - Will I go? - I will not go
      </p>
    </div>
  </div>,
];

function GrammarPageCsEn(props) {
  const history = useHistory();

  return (
    <PageStructure
        setError={props.setError}
      altBg
      tutorialLink
    >
      <ColouredReturnButton
        className="coloured-return-button--top-left"
        onClick={history.goBack}
      >
        {Store.getText("back")}
      </ColouredReturnButton>
      <div className="grammar-page">
        <h1 className="grammar-page__page-title">Anglická gramatika</h1>
        <p className="grammar-page__desc">
          Na této stránce naleznete přehled základních slovíček a gramatiky.
          Snažili jsme se pro Vás shrnout základní znalosti do jednoho krátkého
          přehledu, který každý uživatel dokáže snadno zvládnout. Po zvládnutí
          tohoto přehledu již budete schopni jazyku částečně porozumět a budete
          též schopni formulovat jednoduché věty.
        </p>
        <section className="grammar-page__content-section">
          <AliceCarousel
            autoHeight
            // autoWidth
            mouseTracking
            items={items}
            responsive={responsive}

          
          />
        </section>
      </div>
    </PageStructure>
  );
}

export default GrammarPageCsEn;
