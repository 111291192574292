import Table from "rc-table";
import { useEffect, useState } from "react";
import api from "../../api/api";
import EditFlashcard from "../../components/EditFlashcard/EditFlashcard";
import "./FlashcardTable.css";
import PageStructure from "../../components/PageStructure/PageStructure";
import { Link } from "react-router-dom";

const PER_PAGE = 20;


const Pagination = ({offset, limit, allCount, changePage}) => {

  return (
    <div className="pagination-wrapper">
      {" "}
      <button disabled={offset <= 0} onClick={() => changePage(-1)} className="pagination-button">
        Poprzednia strona
      </button>
      <div>
        <p>
          Strona: {Math.floor(offset / limit) + 1} z{" "}
          {Math.ceil(allCount / limit)}
        </p>
        <p>
          Wyświetlane fiszki: {offset + 1} -{" "}
          {offset + limit < allCount ? offset + limit : allCount} z{" "}
          {allCount}
        </p>
      </div>
      <button disabled={offset + limit >= allCount} onClick={() => changePage(1)} className="pagination-button">
        Następna Strona
      </button>
    </div>
  );
};

function FlashcardTable(props) {
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
    },
    {
      title: "Image",
      dataIndex: "link",
      key: "link",
      width: 100,
      render: (row) => {
        return row && <img className="table-img" src={row.image.base64} />;
      },
    },
    {
      title: "Expression native",
      dataIndex: "contentFrom",
      key: "contentFrom",
      width: 100,
  
      render: (row) => {
        return row.expression;
      },
    },
    {
      title: "Sentence native",
      dataIndex: "contentFrom",
      key: "contentFrom",
      render: (row) => {
        return row.sentence;
      },
    },
    {
      title: "Expression foreign",
      dataIndex: "contentTo",
      key: "contentTo",
      width: 100,
  
      render: (row) => {
        return row.expression;
      },
    },
    {
      title: "Sentence foreign",
      dataIndex: "contentTo",
      key: "contentTo",
      render: (row) => {
        return row.sentence;
      },
    },
    {
      title: "Lesson",
      dataIndex: "link",
      key: "link",
      width: 100,
      render: (row) => {
        return row && row.linkLesson.number;
      },
    },
    {
      title: "Version",
      dataIndex: "link",
      key: "link",
      width: 100,
      render: (row) => {
        return row && row.version
      }
    },
    {
      title: "Hidden",
      dataIndex: "link",
      key: "link",
      width: 100,
      render: (row) => {
        return row && row.hidden ? "x" : "";
      },
    },
    {
      title: "Is lesson image",
      dataIndex: "link",
      key: "link",
      width: 100,
      render: (row) => {
        return (row && isImageUsedAsLessonImage(row.image.id)) ? "x" : "";
      },
    },
  ];

  
  const [flashcards, setFlashcards] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLesson, setCurrentLesson] = useState(null);
  const [count, setCount] = useState(0);
  const [possibleLessons, setPossibleLessons] = useState([])
  const [lessons, setLessons] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentFlashcard, setCurrentFlashcard] = useState({});
  const [languagePairs, setLanguagePairs] = useState([]);
  const [currentPairId, setCurrentPairId] = useState();

  const afterUpdate = async () => {
    setCurrentFlashcard({});
    setIsModalOpen(false);
    await load(currentPage, currentPairId, currentLesson);
  };

  const closeModal = () => {
    setCurrentFlashcard({});
    setIsModalOpen(false);
  };

  const changePage = async (diff) => {
    setCurrentPage(currentPage + diff)
    await load(currentPage + diff, currentPairId, currentLesson)
  }

  const load = async (page, pairId, lesson) => {
    const res = await api.get(
      `/flashcard?offset=${(page - 1) * PER_PAGE}&limit=${PER_PAGE}&pairId=${pairId}&lesson=${lesson}`
    );
    setCount(res.data.count);
    setFlashcards(res.data.flashcards);

    const resLessons = await api.get(
      `/lesson/language-pair/${pairId}`
    );

    setLessons(resLessons.data)
    setPossibleLessons(resLessons.data.map(lesson => lesson.number).sort((a,b) => a-b))

  };

  const changePair = async () => {
    setCurrentPage(1)
    await load(1, currentPairId, currentLesson)
  }

  const isImageUsedAsLessonImage = (imageId) => {
    return !!lessons.find(lesson => lesson.image.id === imageId)
  }



  useEffect(async () => {
    const res = await api.get(`/language-pair`);
    setLanguagePairs(res.data)
    setCurrentPairId(res.data[0].id)
    res.data.map(pair => {
      pair.desc = `${pair.languageFrom.language}-${pair.languageTo.language}(poziom: ${pair.level})`
    })
    res.data = res.data.sort((a,b) => a.desc.localeCompare(b.desc))
    await load(currentPage, res.data[0].id, currentLesson);
  }, []);
  return (
    <PageStructure setError={props.setError}
altBg>
<div>
  <Link to="/admin/users"><button>Użytkownicy</button></Link>
  <Link to="/admin"><button>Fiszki</button></Link>
  <Link to="/admin/by-rate"><button>Fiszki z ocenami</button></Link>

</div>
<div className="flashcard-table">
 <div>
          <select onChange={(ev) => setCurrentPairId(ev.target.value)}> 
              {languagePairs.map((pair, i) => <option value={pair.id} key={pair.id}>{pair.desc}</option>)}
          </select>

          <select onChange={(ev) => setCurrentLesson(ev.target.value)}> 
              {possibleLessons.map((lesson, i) => <option value={lesson} key={lesson}>{lesson}</option>)}
              <option value={-1} >Without lesson</option>
              <option value={null} selected>All</option>
          </select>
          <button onClick={changePair} className="button-std">Znajdź</button>
      </div>
      <Pagination offset={(currentPage - 1) * PER_PAGE} limit={PER_PAGE} allCount={count} changePage={changePage}/>

     
        <Table
          data={flashcards}
          columns={columns}
          onRow={(row) => {
            return {
              onClick: () => {
                setCurrentFlashcard(row);
                setIsModalOpen(true);
              },
            };
          }}
        ></Table>
        {isModalOpen && (
          <EditFlashcard
            flashcardData={currentFlashcard}
            lessons={lessons}
            afterUpdate={afterUpdate}
            closeModal={closeModal}
          ></EditFlashcard>
        )}
    <Pagination offset={(currentPage - 1) * PER_PAGE} limit={PER_PAGE} allCount={count} changePage={changePage}/>
    </div>
    </PageStructure>

  );
}

export default FlashcardTable;
